<div class="table-block">
    <div class="main-table-filters">
        <!-- refresh -->
        <button class="refresh-page" (click)="reloadPage()">{{"general.reload" | translate}}</button>

        <!-- SELECT SEARCH FILTER -->
        <div class="main-dropdown filters">
            <span>{{"general.filter" | translate}}</span>
            <ul>
                <li>
                    <h3>{{"general.show-filter" | translate}}</h3>
                </li>
                <li *ngFor="let filter of filterList; let i = index;">
                    <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                        [(ngModel)]="filter.checked" />
                    <label for="filter-{{i}}">{{"table-cols."+filter.field | translate}}</label>
                </li>
            </ul>
        </div>
        <div class="selected-filters">
            <ng-container *ngFor="let filter of filterList; let i = index;">
                <div class="filter-item" *ngIf="filter.checked">
                    <div class="filter-label">
                        <button class="filter-close" (click)="filterClose(filter)"><img alt="close filter"
                                src="assets/images/filter-close.svg" /></button> {{"table-cols."+filter.field | translate}}
                    </div>
                    <div class="filter-value">
                        <input *ngIf="filter.type==='input'" type="{{filter.type}}" (keyup.enter)="changeFilter()"
                            [style.width.px]="filter.value.length * 13" name="{{filter.field}}" value="{{filter.value}}"
                            [(ngModel)]="filter.value">
                        <input *ngIf="filter.type==='date'" type="{{filter.type}}" (change)="changeFilter()"
                            [style.width.px]="150" name="{{filter.field}}" value="{{filter.value}}"
                            [(ngModel)]="filter.value">
                        <select *ngIf="filter.type==='select'" (change)="changeFilter()" name="{{filter.field}}"
                            [(ngModel)]="filter.value">
                            <option *ngFor="let option of filter.options; let i = index;" value="{{option.value}}">
                                {{"report."+option.label | translate}}</option>
                        </select>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="clear"></div>

    <div class="table-outside-div">
        <p-table [columns]="selectedColumns" [value]="reportList" [reorderableColumns]="false"
            [resizableColumns]="true" [paginator]="showPaginator" (onLazyLoad)="loadDataLazy($event)" [lazy]="true"
            [loading]="loading" [rows]="maxNumberOfResults" [totalRecords]="countItems" *ngIf="tableIsVisible" [(first)]="first">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" class="table-data-col-label {{col.field}}" pReorderableColumn
                        pResizableColumn [pSortableColumn]="(col.field !== 'type' && col.field !== 'status')?col.field:''">
                        {{"table-cols."+col.field | translate}}
                        <p-sortIcon *ngIf="col.field !== 'type' && col.field !== 'status'"
                            [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </th>
                    <th class="text-align-right">Download</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of selectedColumns">
                        <ng-container *ngIf="col.field === 'type'">
                            {{"report."+rowData.parameters.type | translate}}
                        </ng-container>
                        <ng-container *ngIf="col.field === 'status'">
                            {{ rowData.result.state }}
                        </ng-container>
                        <ng-container *ngIf="col.field === 'createdAt'">
                            {{ rowData[col.field] | date : "dd.MM.YYYY HH:mm:ss" }}
                        </ng-container>
                        <ng-container *ngIf="col.field !== 'type' && col.field !== 'status' && col.field !== 'createdAt'">
                            {{ rowData[col.field] }}
                        </ng-container>
                    </td>
                    <td class="text-align-right">
                        <a target="_blank" *ngIf="rowData.result.state === 'complete' && rowData.result.downloadUrl" href="{{rowData.result.downloadUrl}}" class="download-icon-in-table"><img src="assets/images/download.svg" alt="download"></a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="item-per-page">
        <label for="item-per-page">{{"general.objects-per-site"|translate}}</label>
        <select id="item-per-page" name="objectsNum" [(ngModel)]="maxNumberOfResults"
            #maxNumberOfResultsRef="ngModel" (change)="changeNumberOfRecords();">
            <option *ngFor="let item of ['10','20','50','100','200','500','1000']" value="{{item}}">{{item}}
            </option>
        </select>
    </div>

    <div class="buttons">
        <a class="button button-report" routerLink="/report">{{'header.menu-item-report-create' | translate}}</a>
    </div>
</div>
