<div class="row">
    <div class="col-md-6">
        <div class="main-block-white">
            <div class="statistik-title"><img alt="icon" src="assets/images/sim-swap.svg"> {{"general.connectstatus" | translate}}</div>
        
            <div class="chart-block">

                <!-- Example
                <div class="chart-item">
                    <div class="chart-item-icon National"></div>
                    <div class="chart-item-labels-graph">
                        <div class="chart-item-labels">
                            <div class="chart-item-label">National</div>
                            <div class="chart-item-usage-label">10 MB {{"general.of" | translate}} 200MB {{"general.used" | translate}}</div>
                        </div>
                        <div class="chart-item-graph">
                            <div class="chart-item-usage" title="Verbraucht: 10 MB" style="width: {{(100 / 200) * 10}}%"></div>
                        </div>
                        <div class="chart-item-legend"><b>{{"table-cols.plan" | translate}}:</b> Unlimited</div>
                    </div>
                </div>
                -->

                <div class="chart-item">
                    <div class="chart-item-icon"></div>
                    <div class="chart-item-labels-graph">
                        <div class="chart-item-labels">
                            <div class="chart-item-label">{{"general.connectstatus" | translate}}</div>
                            <div class="chart-item-usage-label">{{onlineCards}} {{"general.of" | translate}} {{allCards}} {{"sim-cards.sim-cards" | translate}} online</div>
                        </div>
                        <div class="chart-item-graph">
                            <div class="chart-item-usage" title="Online: {{onlineCards}} SIM-Karten" style="width: {{(100 / allCards) * onlineCards}}%"></div>
                        </div>
                        <div class="chart-item-legend"><b>Online:</b> {{onlineCards}}</div>
                        <div class="chart-item-legend-other"><b>Offline:</b> {{allCards - onlineCards}}</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


