import { Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
//import { LazyLoadEvent } from 'primeng/api';
import { CostCenterService } from 'src/app/services/cost-center.service';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { SimcardData } from 'src/app/interfaces/simcard-data';
import { CardService } from 'src/app/services/card.service';
import { SimCardsList } from 'src/app/interfaces/simcardslist';
//import { TrafficLog } from 'src/app/interfaces/traffic-log';
import { ConnectionLog } from 'src/app/interfaces/connection-log';
import { SimcardUpdateRequest } from 'src/app/interfaces/simcard-update-request';
import { SimCardsGroups } from 'src/app/interfaces/simcardsgroups';
import { SimcardGroupData } from 'src/app/interfaces/simcard-group-data';
import { FilterElement } from 'src/app/interfaces/filter-element';
import { FilterSubElement } from "src/app/interfaces/filter-sub-element";
import { CostCenter } from 'src/app/interfaces/cost-center';
import { CostCenterList } from 'src/app/interfaces/cost-center-list';
import { TrafficProfileService } from 'src/app/services/traffic-profile.service';
import { TrafficProfile } from 'src/app/interfaces/traffic-profile';
import { DataSession } from 'src/app/interfaces/data-session';
import { DataSessionsList } from 'src/app/interfaces/data-sessions-list';
import { DataTrafficList } from 'src/app/interfaces/data-traffic-list';
import { DataTraffic } from 'src/app/interfaces/data-traffic';
import { CustomTableColumn } from 'src/app/interfaces/custom-table-column';
import { UserSettings } from 'src/app/interfaces/user-settings';
import { DropdownFilterEvent } from 'primeng/dropdown';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HistoryList } from 'src/app/interfaces/historylist';
import { HistoryData } from 'src/app/interfaces/history-data';
import { CustomFieldsConfig, CustomFieldsList } from 'src/app/interfaces/custom-fields-config';
import { HttpErrorResponse } from '@angular/common/http';
import { Ban, BanList } from 'src/app/interfaces/ban';
import { BanService } from 'src/app/services/ban.service';
import { TariffData, TariffDataList } from 'src/app/interfaces/tariff-data';
import { SimcardsFilterData } from 'src/app/interfaces/simcards-filter-data';
import { Simcard } from "../../../../interfaces/simcard";
import { SimcardViews } from 'src/app/interfaces/simcard-views';

declare const successMessage: FunctionStringCallback;
declare const errorMessage: FunctionStringCallback;

interface SimCardsUpdateError {
  simcard: SimcardData;
  error: string;
}

interface MapKeys {
  name?: string,
  uuid?: string,
  msisdn?: string,
  iccid?: string,
  tariffname?: string,
  lifecycleState?: string,
  contractStartDate?: string,
  contractEndDate?: string,
  roamingPolicy?: string,
  costCenter?: string,
  ban?: string,
  traffic_profile?: string,
  customAttribute1?: string,
  customAttribute2?: string,
  customAttribute3?: string,
  customAttribute4?: string,
  customAttribute5?: string,
}

interface MapDataSessionsKeys {
  imei?: string,
  status?: string,
  startTime?: string,
  endTime?: string,
  providerName?: string,
  bytesIn?: string,
  bytesDown?: string,
  apn?: string
}

interface ActiveBatchActionsSessionData {
  uuid?: string
}

@Component({
  selector: 'app-sim-cards-list',
  templateUrl: './sim-cards-list.component.html',
  styleUrls: ['./sim-cards-list.component.css']
})
export class SimCardsListComponent implements OnInit, OnDestroy, AfterContentChecked {
  private languageSubscription: Subscription;

  public simCards: SimcardData[] = [];
  public simCardsUpdateErrors: SimCardsUpdateError[] = [];
  public checkedSimCards: SimcardData[] = [];
  public batchSimCards: SimcardData[] = [];
  public simCardsDataSessions!: DataSession[];
  public totalDataSessions = 0;
  public maxDataSessionsPerPage = 10;
  public dataSessionsPageNo = 1;
  public showDSPaginator = false;
  public loadingDS = true;
  public tableDSIsVisible = true;
  public simCardsDataTraffic!: DataTraffic[];
  public totalDataTraffic = 0;
  public maxDataTrafficPerPage = 10;
  public dataTrafficPageNo = 1;
  public showDTPaginator = false;
  public loadingDT = true;
  public tableDTIsVisible = true;
  public connectionData!: ConnectionLog[];
  public banList: Ban[] = [];
  public banPageNumber = 1;
  public banItemsPerPage = 100;
  public banSortBy = '';
  public simCardsList: unknown;
  public simCardsBatchList: unknown;
  public showPaginator = false;
  public showPaginatorHistory = false;
  public loading = true;
  public loadingHistory = true;
  public maxNumberOfResults = 10;
  public maxHistoryNumberOfResults = 10;
  public maxNumberOfBatchResults = 10;
  public countItems = 0;
  public countHistoryItems = 0;
  public maxNumberOfConnectionsResults = 10;
  public countConnectionsItems = 100;
  public showConnectionPaginator = false;
  public loadingConnections = false;
  public pageNumber = 1;
  public pageHistoryNumber = 1;
  public pageConnectionsNumber = 1;
  public searchKey = '';
  public searchHistoryKey = '';
  public tableIsVisible = true;
  public tableHistoryIsVisible = true;
  public tableConnectionsIsVisible = true;
  public numberOfCheckedSimCards = 0;
  public selectedSimCards = 0;
  public simCardsExecuted = 0;
  public batchSimCardsExecuted = 0;
  public isSimCardChecked = false;
  public listDataSessionsCols!: CustomTableColumn[];
  public simcardSelected = false;
  public isModalChecked = false;
  public mainCheckBoxState = false;
  public selectAllCheckBoxState = false;
  public selectedSimcardData!: SimcardData | null;
  public detailsBlockVisible = '';
  public simcardGroups!: SimcardGroupData[];
  public selectedSimcardGroups: SimcardGroupData[] = [];
  public filteredSimcardGroups!: SimcardGroupData[];
  public batchFilteredSimcardGroups!: SimcardGroupData[];
  public costCenters: CostCenter[] = [];
  public selectedCostCenter!: CostCenter | undefined;
  public filteredCostCenters!: CostCenter[];
  public trafficProfiles!: TrafficProfile[];
  public selectedTrafficProfile!: TrafficProfile | undefined;
  public filteredTrafficProfiles!: TrafficProfile[];
  public cols!: CustomTableColumn[];
  public colsHistory!: CustomTableColumn[];
  public reorderedCols!: CustomTableColumn[];
  public selectedColumns!: CustomTableColumn[];
  public filterList: FilterElement[] = [];
  public filterHistoryList: FilterElement[] = [];
  public isModalStatusChecked = false;
  public multiSimAction = '';
  public simCardsProcessedInPrecent = 0;
  public simCardsUpdateInProcess = false;
  public isMassModalChecked = false;
  public isBatchModalConfirmationChecked = false;
  public selectedRoamingPolicy = '';
  public activeBatchActionsSessionData: ActiveBatchActionsSessionData = {};
  public batchNextPage: string | undefined | null = undefined;
  public chkBatchUpdateName = false;
  public txtBatchUpdateName = "";
  public chkBatchUpdateCostCenter = false;
  public selBatchUpdateCostCenter!: CostCenter | undefined;
  public chkBatchUpdateTrafficProfile = false;
  public selBatchUpdateTrafficProfile!: TrafficProfile | undefined;
  public chkBatchUpdateSimGroups = false;
  public batchSimcardGroups: SimcardGroupData[] = [];
  public batchGroupOpt = '';
  public chkBatchUpdateNote1 = false;
  public batchGroupNote1 = '';
  public chkBatchUpdateNote2 = false;
  public batchGroupNote2 = '';
  public viewName = '';
  public userSettings: UserSettings = {};
  public enableColsDefaultChecked = true;
  public currentViewDefaultChecked = true;
  public enableColsDefault = ['status', 'msisdn', 'name', 'iccid']; // Enabled Cols for SIM Cards Table without saved views as initial
  public enableCols: string[] = [];
  public saveUserSettingsSuccessMessage = "";
  public sortDirection: number | null | undefined;
  public sortHistoryDirection: number | null | undefined;
  public mapKeys: MapKeys = {
    name: 'name',
    uuid: 'uuid',
    msisdn: 'msisdn',
    iccid: 'iccid',
    tariffname: 'tariff.name',
    lifecycleState: 'lifecycleState',
    contractStartDate: 'contractStartDate',
    contractEndDate: 'contractEndDate',
    roamingPolicy: 'roamingPolicy',
    costCenter: 'costCenter.name',
    ban: 'costCenter.ban.ban',
    traffic_profile: 'trafficProfile.name',
    customAttribute1: 'customAttributes.customAttribute1',
    customAttribute2: 'customAttributes.customAttribute2',
    customAttribute3: 'customAttributes.customAttribute3',
    customAttribute4: 'customAttributes.customAttribute4',
    customAttribute5: 'customAttributes.customAttribute5',
  };
  public searchDataSessionsKey = '';
  public sortDataSessionsDirection: number | null | undefined;
  public mapDataSessionsKeys: MapDataSessionsKeys = {
    imei: 'imei',
    status: 'status',
    startTime: 'startTime',
    endTime: 'endTime',
    providerName: 'providerName',
    bytesIn: 'bytesIn',
    bytesDown: 'bytesDown',
    apn: 'apn'
  };
  public costCentersFilter: FilterSubElement[] = [];
  public groups: FilterSubElement[] = [];
  public banFilter: FilterSubElement[] = [];
  public historyList: unknown;
  public historyData: HistoryData[] = [];
  public listOfTariffs: TariffData[] = [];
  public tariffs: FilterSubElement[] = [];

  public customFieldsList?: CustomFieldsList | undefined;

  public dataField1? = '';
  public dataField2? = '';
  public dataField3? = '';
  public dataField4? = '';
  public dataField5? = '';

  public chkBatchUpdateDataField1 = false;
  public chkBatchUpdateDataField2 = false;
  public chkBatchUpdateDataField3 = false;
  public chkBatchUpdateDataField4 = false;
  public chkBatchUpdateDataField5 = false;

  public apply_filter_select = '';
  public apply_filter_file!: File | null;

  public selectedCSVFile!: File | null;

  public actionActive = false;

  public first = 0;

  public simCardsCache: Simcard[] = [];

  constructor(private router: Router, private userService: UserService,
              private translate: TranslateService, private sessionService: SessionService, private cardService: CardService, private banService: BanService,
              private costCenterService: CostCenterService, private trafficProfileService: TrafficProfileService, private errorService: ErrorHandlerService,
              private changeDetector: ChangeDetectorRef) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }

    this.showPaginator = true;

    this.sortDirection = 0;
    this.listAllCostCenters();
    this.listAllTrafficProfiles();
    this.findSimcardGroups();
    this.getBanList();
    this.listAllTariffs();

    this.filterList = [
      {field: 'msisdn', type: 'input', checked: false, value: ""},
      {field: 'iccid', type: 'input', checked: false, value: ""},
      {field: 'name', type: 'input', checked: false, value: ""},
      {field: 'customAttributes.customAttribute1', type: 'input', checked: false, value: ""},
      {field: 'customAttributes.customAttribute2', type: 'input', checked: false, value: ""},
      {field: 'customAttributes.customAttribute3', type: 'input', checked: false, value: ""},
      {field: 'customAttributes.customAttribute4', type: 'input', checked: false, value: ""},
      {field: 'customAttributes.customAttribute5', type: 'input', checked: false, value: ""},
      {field: 'costCenter.ban', type: 'select', checked: false, value: "", options: this.banFilter},
      {field: 'costCenter', type: 'select', checked: false, value: "", options: this.costCentersFilter},
      {field: 'groups', type: 'select', checked: false, value: "", options: this.groups},
      {field: 'tariffname', type: 'select', checked: false, value: "", options: this.tariffs},
      {
        field: 'roamingPolicy',
        type: 'select',
        checked: false,
        value: "",
        options: [{value: 'none', label: 'NONE'}, {value: 'eu', label: 'EU'}, {
          value: 'eu_ch',
          label: 'EU_CH'
        }, {value: 'world', label: 'WORLD'}]
      },
      {
        field: 'online',
        type: 'select',
        checked: false,
        value: "",
        options: [{value: 'true', label: 'Online'}, {value: 'false', label: 'Offline'}]
      },
      {
        field: 'lifecycleState',
        type: 'select',
        checked: false,
        value: "",
        options: [{value: 'active', label: 'active'}, {value: 'disabled', label: 'disabled'}, {
          value: 'canceled',
          label: 'canceled'
        }]
      },
      {field: 'apply_filter', type: 'hidden', checked: false, value: ""}
    ];

    this.cols = [
      {field: 'status', checked: false},
      {field: 'msisdn', checked: false},
      {field: 'iccid', checked: false},
      {field: 'name', checked: false},
      {field: 'customAttributes.customAttribute1', checked: false},
      {field: 'customAttributes.customAttribute2', checked: false},
      {field: 'customAttributes.customAttribute3', checked: false},
      {field: 'customAttributes.customAttribute4', checked: false},
      {field: 'customAttributes.customAttribute5', checked: false},
      {field: 'costCenter', checked: false},
      {field: 'groups', checked: false},
      {field: 'traffic_profile', checked: false},
      {field: 'ban', checked: false},
      {field: 'tariffname', checked: false},
      {field: 'lifecycleState', checked: false},
      {field: 'contractStartDate', checked: false},
      {field: 'contractEndDate', checked: false},
      {field: 'mcc', checked: false},
      {field: 'mnc', checked: false},
      {field: 'roamingPolicy', checked: false}
    ];

    this.listDataSessionsCols = [
      {field: 'startTime', checked: true},
      {field: 'endTime', checked: true},
      {field: 'numDays', checked: true},
      {field: 'mcc', checked: true},
      {field: 'mnc', checked: true},
      {field: 'traffic', checked: true},
      {field: 'bytesIn', checked: true},
      {field: 'bytesOut', checked: true},
      {field: 'apn', checked: true},
      {field: 'providerName', checked: true},
      {field: 'imei', checked: true},
      {field: 'ipv4Address', checked: true}
    ];

    this.showPaginatorHistory = true;
    this.colsHistory = [
      {field: 'userName', checked: true},
      {field: 'createdAt', checked: true},
      {field: 'type', checked: true}
    ];

    this.filterHistoryList = [
      {field: 'createdAt', type: 'date', checked: false, value: ""}
    ];

    this.enableCols = this.enableColsDefault;
    this.initialCols(this.enableCols);

    this.getUserSettings();
    this.getCustomFieldsData();
  }

  ngOnInit() {
    console.log('initialized');
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log(event);
  }

  // refresh page event
  reloadPage() {
    this.listAllCostCenters();
    this.listAllTrafficProfiles();
    this.findSimcardGroups();
    this.getSimcardList();

    this.mainCheckBoxState = false;
    this.selectAllCheckBoxState = false;
    this.selectedSimCards = 0;

    // if show details get fresh data from selected card
    if (this.selectedSimcardData && this.selectedSimcardData['@id'] !== undefined) {
      this.getSimcardDetails(this.selectedSimcardData['@id']);
    }

    this.errorService.deleteFieldError();
  }

  getSimcardDetails(uuid: string) {
    this.cardService.getSimcardDetails(this.sessionService.getJWTToken(), uuid).subscribe((data: SimcardData) => {
      this.selectedSimcardData = data;
      this.getDataForCard(this.selectedSimcardData);
      //console.log('Selected Card: ', this.selectedSimcardData);
    });
  }

  executeMainCheckBoxEvent(event: Event) {
    //console.log("check all");
    for (const simCard of this.simCards) {
      simCard.selected = (<HTMLInputElement>event.target).checked;
    }

    this.selectAllCheckBoxState = false;
    this.countSelectedSIMCards();
  }

  countSelectedSIMCards() {
    this.selectedSimCards = 0;
    this.checkedSimCards = [];

    for (const simCard of this.simCards) {
      if (simCard.selected == true) {
        this.selectedSimCards++;
        this.checkedSimCards.push(simCard);
      }
    }
  }

  executeEditMultiSimAction() {
    this.isMassModalChecked = true;
  }

  showBatchActionsModal() {
    this.isBatchModalConfirmationChecked = true;

    this.simCardsUpdateInProcess = false;
    this.simCardsProcessedInPrecent = 0;
  }

  executeMultiSimAction() {
    this.simCardsExecuted = 0;
    this.simCardsProcessedInPrecent = 0;

    if (this.multiSimAction != '') {
      this.showBatchActionsModal();
    }
  }

  cancelBatchActions() {
    this.isBatchModalConfirmationChecked = false;
  }

  executeSimcardsBatchAction() {
    this.errorService.deleteFieldError();
    this.actionActive = true;
    this.cardService.createBatchSimcardsActionSession(this.sessionService.getJWTToken(), this.multiSimAction, this.selectedRoamingPolicy).subscribe({
      next: (response) => {
        this.activeBatchActionsSessionData = response;

        if (this.selectAllCheckBoxState == false) {
          this.executeUpdateLogic();
        } else {
          this.batchSimCards = [];
          this.listAllSimCardsInBatch();
        }
      },
      error: (err) => {
        this.errorService.processError(err);
        this.actionActive = false;
      }
    });
  }

  executeUpdateLogic() {
    const simCards: string[] = [];

    this.checkedSimCards.forEach((simcardData: SimcardData) => {
      if (simcardData?.['@id']) {
        simCards.push(simcardData?.['@id']);
      }
    });

    this.cardService.addSimcardsToBatchSession(this.sessionService.getJWTToken(), this.activeBatchActionsSessionData.uuid, simCards).subscribe({
      next: (response) => {
        //console.log(response);
        this.executeBatchActionsSubmit();
        return response;
      },
      error: (err) => {
        this.errorService.processError(err);
        this.actionActive = false;
      },
    });
  }

  executeBatchActionsSubmit() {
    this.cardService.submitSimcardsBatchSession(this.sessionService.getJWTToken(), this.activeBatchActionsSessionData.uuid).subscribe({
      next: (response) => {
        //console.log(response);
        this.isBatchModalConfirmationChecked = false;
        this.selectedRoamingPolicy = '';

        successMessage(this.translate.instant('general.update-started-message'));
        this.actionActive = false;
        return response;
      },
      error: (err) => {
        this.errorService.processError(err);
        this.actionActive = false;
      },
    });
  }

  executeBatchUpdateLogic() {
    this.errorService.deleteFieldError();
    setTimeout(() => {
      const updateObj: SimcardUpdateRequest = {
        note1: (Math.random() * 10) + ' ',
      };

      const simCard = this.batchSimCards[this.batchSimCardsExecuted];

      this.cardService.updateExistingSimCard(this.sessionService.getJWTToken(), simCard?.['@id'], updateObj).subscribe({
        next: (response: SimcardData) => {
          //console.log(response);
          this.executionBatchPercentCalculation();
          this.testBatchExecuteMore();
          return response;
        },
        error: (error: ErrorEvent) => {
          this.simCardsUpdateErrors.push({
            simcard: this.batchSimCards[this.batchSimCardsExecuted],
            error: error.error["hydra:description"]
          });
          this.executionBatchPercentCalculation();
          this.testBatchExecuteMore();
        }
      });
      //console.log(this.multiSimAction);
    }, 50);
  }

  executionBatchPercentCalculation() {
    this.simCardsExecuted++;
    this.batchSimCardsExecuted++;
    this.simCardsProcessedInPrecent = Math.round((100 / this.countItems) * this.simCardsExecuted);
    if (this.simCardsProcessedInPrecent === 100) {
      successMessage(this.translate.instant('general.update-success-message'));
      this.reloadPage();
    }
  }

  testBatchExecuteMore() {
    if (this.batchSimCardsExecuted < this.batchSimCards.length) {
      this.executeBatchUpdateLogic();
    } else {
      if (this.batchNextPage === undefined) {
        return;
      }

      this.listAllSimCardsInBatch();
    }
  }

  getSimcardList() {
    this.loading = true;

    let searchKey = this.searchKey;

    if (searchKey.indexOf('.') > -1) {
      searchKey = searchKey.split('.')[1];
    }

    this.cardService.getSimcardList(this.sessionService.getJWTToken(), this.pageNumber, this.maxNumberOfResults, this.mapKeys[searchKey as keyof MapKeys], this.sortDirection, this.filterList, true, true).subscribe((data: SimCardsList) => {
      this.simCardsList = data;
      //console.log('SIM Cards: ', this.simCardsList);

      this.simCards = [];

      const simCardsLocal = data['hydra:member'];

      for (const simCardLocal of simCardsLocal) {
        this.getDataForCard(simCardLocal);
      }

      this.simCards = simCardsLocal;

      this.countItems = (data["hydra:totalItems"]) ? data["hydra:totalItems"] : 0;
      this.showPaginator = true;
      this.loading = false;

      //console.log('simCards', this.simCards);
    });
  }

  getDataForCard(simCardLocal: SimcardData) {
    if (this.simcardGroups.length > 0) {
      const groupObjects: SimcardGroupData[] = [];
      for (const group of this.simcardGroups) {
        if (group['@id'] && simCardLocal.groups.includes(group['@id'])) {
          groupObjects.push(group);
        }
      }

      if (groupObjects.length > 0) {
        simCardLocal.groups = groupObjects;
      }
    }

    if (this.costCenters.length > 0) {
      let simCostCenter!: CostCenter;

      for (const costCenter of this.costCenters) {
        if (costCenter['@id'] === simCardLocal.costCenter) {
          simCostCenter = costCenter;
          break;
        }
      }

      if (simCostCenter != undefined) {
        simCardLocal.costCenter = simCostCenter;
      }
    }

    if (this.trafficProfiles.length > 0) {
      let simTrafficProfile!: TrafficProfile;

      for (const trafficProfile of this.trafficProfiles) {
        if (trafficProfile['@id'] === simCardLocal.trafficProfile) {
          simTrafficProfile = trafficProfile;
          break;
        }
      }

      if (simTrafficProfile != undefined) {
        simCardLocal.trafficProfile = simTrafficProfile;
      }
    }
    this.dataField1 = simCardLocal.customAttributes?.customAttribute1;
    this.dataField2 = simCardLocal.customAttributes?.customAttribute2;
    this.dataField3 = simCardLocal.customAttributes?.customAttribute3;
    this.dataField4 = simCardLocal.customAttributes?.customAttribute4;
    this.dataField5 = simCardLocal.customAttributes?.customAttribute5;

    const ban = this.banList.find(ban => ban['@id'] === simCardLocal.costCenter?.ban);
    if (ban) {
      simCardLocal.ban = ban.ban;
    }

    const tariff = this.listOfTariffs.find(tariff => tariff['@id'] === simCardLocal.tariff);
    if (tariff) {
      simCardLocal.tariffname = tariff.name;
    }
  }

  listAllTrafficProfiles() {
    this.trafficProfileService.getTrafficProfileList(this.sessionService.getJWTToken(), null, 0).subscribe((data) => {
      this.trafficProfiles = [];
      this.trafficProfiles = data['hydra:member'];
      //console.log('trafficProfiles', this.trafficProfiles);
    });
  }


  listAllSimCardsInBatch() {
    this.cardService.getBatchSimcardList(this.sessionService.getJWTToken(), 1, this.maxNumberOfBatchResults, this.batchNextPage, this.filterList).subscribe((data: SimCardsList) => {
      this.simCardsBatchList = data;
      //console.log(this.simCardsBatchList);

      this.batchSimCards = [...this.batchSimCards, ...data['hydra:member']];

      this.batchNextPage = undefined;

      if (data["hydra:view"] && "hydra:next" in data["hydra:view"]) {
        this.batchNextPage = data["hydra:view"]["hydra:next"];
      }

      if (this.batchNextPage === undefined) {

        this.checkedSimCards = this.batchSimCards;

        this.executeUpdateLogic();

        return;
      }

      this.listAllSimCardsInBatch();
    });

    //console.log(this.batchSimCards);
  }

  listAllCostCenters() {
    this.costCenterService.getCostCenterList(this.sessionService.getJWTToken(), null, 0, null).subscribe({
      next: (data: CostCenterList) => {
        this.costCenters = [];
        this.costCenters = data['hydra:member'];

        for (const costCenter of this.costCenters) {
          this.costCentersFilter.push({value: costCenter['@id'], label: costCenter.name});
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      },
    });

    //console.log(this.costCenters);
  }

  checkBoxEventOnRow(event: Event, rowData: SimcardData) {
    //console.log("select one row");
    rowData.selected = (<HTMLInputElement>event.target).checked;

    if (rowData.selected == false) {
      this.mainCheckBoxState = false;
    }

    this.countSelectedSIMCards();
  }

  loadDataLazy(event: TableLazyLoadEvent): void {
    this.mainCheckBoxState = false;

    if (event && event.sortField && event.sortField && typeof event.sortField === 'string') {
      this.searchKey = event.sortField;
    }

    this.sortDirection = event.sortOrder;

    this.pageNumber = (event.first) ? (event?.first / +this.maxNumberOfResults) + 1 : 1;

    //console.log("Page number: ", this.pageNumber);

    this.getSimcardList();
  }

  changeFilter(filter: FilterElement | undefined = undefined) {
    if (filter) {
      console.log(filter.field);
    }
    //console.log(this.filterList);
    this.first = 0;
    this.pageNumber = 1;
    this.getSimcardList();
  }

  filterClose(filter: FilterElement) {
    filter.checked = false;
    this.changeFilter(filter);
  }

  handleCheck(event = false) {
    // disable alle checked views
    if(event) {
      if (this.userSettings && this.userSettings.simcardViews) {
        for (const view of this.userSettings.simcardViews) {
          view.currentView = false;
        }
      }
      this.currentViewDefaultChecked = false;
    }
    // end disable alle checked views

    this.selectedColumns = [];
    this.enableCols = [];

    this.cols.forEach(col => {
      if (col.checked) {
        this.selectedColumns.push(col);
        this.enableCols.push(col.field);
      }
    });
  }

  exportAllSimCardsAction() {
    this.cardService.getSimcardsCSV(this.sessionService.getJWTToken(), 'iccid', this.sortDirection, this.filterList, true, true).subscribe({
      next: (response: string) => {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'simcards_export.csv';
        hiddenElement.click();
      },
      error: (err) => {
        this.errorService.processError(err);
      },
    });
  }

  loadDataSessionsLazy(event: TableLazyLoadEvent): void {
    if (event && event.sortField && event.sortField && typeof event.sortField === 'string') {
      this.searchDataSessionsKey = event.sortField;
    }

    this.sortDataSessionsDirection = event.sortOrder;

    this.dataSessionsPageNo = (event.first) ? (event.first / +this.maxDataSessionsPerPage) + 1 : 1;

    //console.log("Page number: ", this.dataSessionsPageNo);

    this.cardService.getDataSessionsForASimCard(this.sessionService.getJWTToken(), this?.selectedSimcardData?.uuid, this.dataSessionsPageNo, this.maxDataSessionsPerPage, this.mapDataSessionsKeys[this.searchDataSessionsKey as keyof MapDataSessionsKeys], this.sortDataSessionsDirection).subscribe({
      next: (data: DataSessionsList) => {
        const dataSessions: DataSessionsList = data;
        this.simCardsDataSessions = dataSessions['hydra:member'];

        this.totalDataSessions = (data["hydra:totalItems"]) ? data["hydra:totalItems"] : 0;
        this.showDSPaginator = true;
        this.loadingDS = false;

        //console.log(this.simCardsDataSessions);
      },
      error: (err) => {
        this.errorService.processError(err);
      }
    });
  }

  changeNumberOfRecords() {
    this.tableIsVisible = false;
    this.mainCheckBoxState = false;

    setTimeout(() => {
      this.tableIsVisible = true;
    }, 50);
  }

  initialCols(enableCols: string[]) {
    this.cols.map(col => col.checked = false); // Disable all Ckeckbox

    for (const col of this.cols) {
      if (enableCols.includes(col.field)) {
        col.checked = true;
      }
    }

    this.handleCheck();
  }

  changeNumberOfConnectionRecords() {
    this.tableConnectionsIsVisible = false;

    setTimeout(() => {
      this.tableConnectionsIsVisible = true;
    }, 50);
  }

  changeNumberOfDataSessionRecords() {
    this.tableDSIsVisible = false;

    setTimeout(() => {
      this.tableDSIsVisible = true;
    }, 50);
  }

  editSimcardDataRow(simData: SimcardData) {
    this.selectSimcardDataRow(simData);
    this.detailsBlockVisible = "";
    this.simcardSelected = false;
    this.isModalChecked = true;
    this.generateFilteredSimcardGroups();
  }

  editSimcardData(simData: SimcardData) {
    this.selectSimcardDataRow(simData);
    this.generateFilteredSimcardGroups();
  }

  selectSimcardDataRow(simData: SimcardData) {
    //console.log('selected simdata', simData);

    const simDataCpy = Object.assign({}, simData);

    this.selectedSimcardData = simDataCpy;

    this.getDataForCard(this.selectedSimcardData);

    this.getSimCardsDataSessionsList(simDataCpy);
    this.getSimCardsDataTrafficList(simDataCpy);

    this.selectedSimcardGroups = (this.selectedSimcardData.groups as SimcardGroupData[]) ?? [];

    this.selectedCostCenter = this.selectedSimcardData.costCenter;
    this.selectedTrafficProfile = this.selectedSimcardData.trafficProfile;

    this.simcardSelected = true;
    this.detailsBlockVisible = 'details-block-visible';

    this.getHistoryList();

    console.log('Selected SIM Card: ', this.selectedSimcardData);
  }

  hideDetailsBlock() {
    this.selectedSimcardData = null;
    this.simcardSelected = false;
    this.detailsBlockVisible = '';
  }

  showEditModal() {
    this.isModalChecked = true;
  }

  executeUpdateAction() {
    this.errorService.deleteFieldError();
    let selectedSimcardGroupsIDS: string[] = [];

    if ((this.selectedSimcardGroups ?? []).length > 0) {
      selectedSimcardGroupsIDS = (this.selectedSimcardGroups ?? []).map(object => object["@id"] ?? "");
    }

    const simDataID = this.selectedSimcardData?.['@id'];

    const updateObj: SimcardUpdateRequest = {
      name: this.selectedSimcardData?.name,
      note1: this.selectedSimcardData?.note1,
      note2: this.selectedSimcardData?.note2,
      groups: selectedSimcardGroupsIDS,
    };

    updateObj.costCenter = null;
    if (this.selectedCostCenter) {
      updateObj.costCenter = this.selectedCostCenter["@id"];
    }

    updateObj.trafficProfile = null;
    if (this.selectedTrafficProfile) {
      updateObj.trafficProfile = this.selectedTrafficProfile["@id"];
    }

    updateObj.customAttributes = {
      customAttribute1: this.dataField1,
      customAttribute2: this.dataField2,
      customAttribute3: this.dataField3,
      customAttribute4: this.dataField4,
      customAttribute5: this.dataField5
    };

    this.cardService.updateExistingSimCard(this.sessionService.getJWTToken(), simDataID, updateObj).subscribe({
      next: (data: SimcardData) => {
        //console.log(data);
        this.isModalChecked = false;

        if (this.simcardSelected) {
          this.selectSimcardDataRow(data);
        }

        this.getSimcardList();
        successMessage(this.translate.instant('sim-cards.update-success-message'));
        this.reloadPage();
        return data;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  findSimcardGroups() {
    this.cardService.getSimcardGroupsList(this.sessionService.getJWTToken()).subscribe({
      next: (data: SimCardsGroups) => {
        this.simcardGroups = data["hydra:member"];

        for (const simcardGroup of this.simcardGroups) {
          this.groups.push({value: simcardGroup['@id'], label: simcardGroup.name});
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  generateFilteredSimcardGroups() {
    this.filteredSimcardGroups = [];

    for (let i = 0; i < this.simcardGroups.length; i++) {
      const simcardGroup = this.simcardGroups[i];
      if (simcardGroup.costCenter === this.selectedCostCenter?.['@id'] || this.selectedSimcardGroups.includes(simcardGroup)) {
        this.filteredSimcardGroups.push(simcardGroup);
      }
    }
  }

  resetFilteredSimcardGroups() {
    this.selectedSimcardGroups = [];
    this.generateFilteredSimcardGroups();
  }

  generateBatchFilteredSimcardGroups() {
    this.batchFilteredSimcardGroups = [];

    for (let i = 0; i < this.simcardGroups.length; i++) {
      const simcardGroup = this.simcardGroups[i];
      if (simcardGroup.costCenter === this.selBatchUpdateCostCenter?.['@id'] || this.selBatchUpdateCostCenter === undefined) {
        this.batchFilteredSimcardGroups.push(simcardGroup);
      }
    }
  }

  resetBatchFilteredSimcardGroups() {
    this.batchSimcardGroups = [];
    this.generateBatchFilteredSimcardGroups();
  }

  filterCostCenters(event: DropdownFilterEvent) {
    console.log(event.filter);
  }

  filterTrafficProfiles(event: DropdownFilterEvent) {
    console.log(event.filter);
  }

  restartCostCenter(event: Event) {
    if (!(<HTMLInputElement>event.target).checked) {
      this.selBatchUpdateCostCenter = undefined;
    }

    this.resetBatchFilteredSimcardGroups();
  }

  restartTrafficProfile(event: Event) {
    if (!(<HTMLInputElement>event.target).checked) {
      this.selBatchUpdateTrafficProfile = undefined;
    }
  }

  restartSimGroups(event: Event) {
    if (!(<HTMLInputElement>event.target).checked) {
      this.batchGroupOpt = '';
      this.batchSimcardGroups = [];
    }

    this.resetBatchFilteredSimcardGroups();
  }

  resetMassUpdateForm() {
    this.chkBatchUpdateName = false;
    this.txtBatchUpdateName = '';

    this.chkBatchUpdateTrafficProfile = false;
    this.selBatchUpdateTrafficProfile = undefined;

    this.chkBatchUpdateCostCenter = false;
    this.selBatchUpdateCostCenter = undefined;

    this.chkBatchUpdateSimGroups = false;
    this.batchGroupOpt = '';
    this.batchSimcardGroups = [];

    this.chkBatchUpdateNote1 = false;
    this.batchGroupNote1 = '';

    this.chkBatchUpdateNote2 = false;
    this.batchGroupNote2 = '';

    this.chkBatchUpdateDataField1 = false;
    this.dataField1 = '';
    this.chkBatchUpdateDataField2 = false;
    this.dataField2 = '';
    this.chkBatchUpdateDataField3 = false;
    this.dataField3 = '';
    this.chkBatchUpdateDataField4 = false;
    this.dataField4 = '';
    this.chkBatchUpdateDataField4 = false;
    this.dataField4 = '';
  }

  executeUpdateCurrentAction() {
    //console.log("executeUpdateCurrentAction");
    this.simCardsUpdateErrors = [];
    this.simCardsExecuted = 0;
    this.isMassModalChecked = false;
    this.simCardsUpdateInProcess = true;
    this.isModalStatusChecked = true;
    this.executeUpdateCurrentSimcardsLogic();
  }

  executeUpdateCurrentSimcardsLogic() {
    setTimeout(() => {
      const simCard = this.checkedSimCards[this.simCardsExecuted];

      const updateObj = this.generateSimCardUpdateRequestObject(simCard);

      this.cardService.updateExistingSimCard(this.sessionService.getJWTToken(), simCard?.['@id'], updateObj).subscribe({
        next: (response: SimcardData) => {
          //console.log(response);
          this.executionPercentCurrentSimcardsCalculation();
          this.testExecuteMoreCurrentSimcards();
          return response;
        },
        error: (error: ErrorEvent) => {
          this.simCardsUpdateErrors.push({
            simcard: this.checkedSimCards[this.simCardsExecuted],
            error: error.error["hydra:description"]
          });
          this.executionPercentCurrentSimcardsCalculation();
          this.testExecuteMoreCurrentSimcards();
        }
      });
      //console.log(this.multiSimAction);
    }, 50);
  }

  executionPercentCurrentSimcardsCalculation() {
    this.simCardsExecuted++;
    this.simCardsProcessedInPrecent = Math.round((100 / this.selectedSimCards) * this.simCardsExecuted);
    if (this.simCardsProcessedInPrecent === 100) {
      this.reloadPage();
      successMessage(this.translate.instant('general.update-success-message'));
    }
  }

  testExecuteMoreCurrentSimcards() {
    if (this.simCardsExecuted < this.selectedSimCards) {
      this.executeUpdateCurrentSimcardsLogic();
    }
  }

  executeUpdateMultiAction() {
    //console.log("executeUpdateMultiAction");
    this.simCardsExecuted = 0;
    this.simCardsProcessedInPrecent = 0;
    this.isMassModalChecked = false;

    if (this.multiSimAction != '') {
      this.simCardsUpdateErrors = [];
      this.simCardsCache = [];
      this.simCardsUpdateInProcess = true;
      this.isModalStatusChecked = true;

      this.listAllMultiSimCardsInBatch(true);
    }

  }

  executeBatchUpdateMultiSimcardLogic() {
    this.testBatchExecuteMultiSimcardMore();
  }


  executeBatchUpdateMultiSimcardLogicFromArray() {
    setTimeout(() => {
      const simCard = this.simCardsCache[this.batchSimCardsExecuted];

      const updateObj = this.generateSimCardUpdateRequestObject(simCard);

      this.cardService.updateExistingSimCard(this.sessionService.getJWTToken(), simCard?.['@id'], updateObj).subscribe({
        next: (response: SimcardData) => {
          //console.log(response);
          this.executionBatchMultiSimcardPercentCalculation();
          this.testOnlyTheBatchExecuteMultiSimcard();
        },
        error: (error: ErrorEvent) => {
          this.simCardsUpdateErrors.push({
            simcard: this.simCardsCache[this.batchSimCardsExecuted],
            error: error.error["hydra:description"]
          });
          this.executionBatchMultiSimcardPercentCalculation();
          this.testOnlyTheBatchExecuteMultiSimcard();
        }
      });
      //console.log(this.multiSimAction);
    }, 50);
  }

  executionBatchMultiSimcardPercentCalculation() {
    this.simCardsExecuted++;
    this.batchSimCardsExecuted++;
    this.simCardsProcessedInPrecent = Math.round((100 / this.countItems) * this.simCardsExecuted);
    if (this.simCardsProcessedInPrecent === 100) {
      this.reloadPage();
      successMessage(this.translate.instant('general.update-success-message'));
    }
  }

  testBatchExecuteMultiSimcardMore() {
    this.listAllMultiSimCardsInBatch(true);
  }

  testOnlyTheBatchExecuteMultiSimcard() {
    if (this.batchSimCardsExecuted < this.simCardsCache.length) {
      this.executeBatchUpdateMultiSimcardLogicFromArray();
    } else {
      if (this.batchNextPage === undefined) {
        return;
      }
    }
  }

  listAllMultiSimCardsInBatch(withUpdate: boolean) {
    this.batchSimCardsExecuted = 0;

    this.cardService.getBatchSimcardList(this.sessionService.getJWTToken(), 1, this.maxNumberOfBatchResults, this.batchNextPage, this.filterList).subscribe((data: SimCardsList) => {
      this.simCardsBatchList = data;
      //console.log(this.simCardsBatchList);

      this.batchSimCards = [];
      this.batchSimCards = data['hydra:member'];

      this.batchSimCards.forEach((value, index) => {
        this.simCardsCache.push(value);
      });

      this.batchNextPage = undefined;

      const totalItems = (data["hydra:totalItems"]) ? data["hydra:totalItems"] : 0;

      if (data["hydra:view"] && "hydra:next" in data["hydra:view"]) {
        this.batchNextPage = data["hydra:view"]["hydra:next"];

        if (totalItems > 0 && this.simCardsCache.length === totalItems) {
          //console.log("EXECUTE NOW UPDATES");
          this.executeBatchUpdateMultiSimcardLogicFromArray();
          return;
        }
      } else {
        if (totalItems > 0 && this.simCardsCache.length === totalItems) {
          //console.log("EXECUTE NOW UPDATES");
          this.executeBatchUpdateMultiSimcardLogicFromArray();
          return;
        }
      }

      if (withUpdate) {
        this.executeBatchUpdateMultiSimcardLogic();
      }
      this.showPaginator = true;
    });

    //console.log(this.batchSimCards);
  }

  generateSimCardUpdateRequestObject(simCard: SimcardData): SimcardUpdateRequest {
    const updateObj: SimcardUpdateRequest = {};

    if (this.chkBatchUpdateName) {
      updateObj.name = (this.txtBatchUpdateName !== '') ? this.txtBatchUpdateName : null;
    }

    if (this.chkBatchUpdateCostCenter) {
      updateObj.costCenter = (this.selBatchUpdateCostCenter !== null && this.selBatchUpdateCostCenter !== undefined) ? this.selBatchUpdateCostCenter?.['@id'] : null;
    }

    if (this.chkBatchUpdateTrafficProfile) {
      updateObj.trafficProfile = (this.selBatchUpdateTrafficProfile !== null && this.selBatchUpdateTrafficProfile !== undefined) ? this.selBatchUpdateTrafficProfile?.['@id'] : null;
    }

    if (this.chkBatchUpdateNote1) {
      updateObj.note1 = (this.batchGroupNote1 !== '') ? this.batchGroupNote1 : null;
    }

    if (this.chkBatchUpdateNote2) {
      updateObj.note2 = (this.batchGroupNote2 !== '') ? this.batchGroupNote2 : null;
    }

    if (this.chkBatchUpdateSimGroups) {
      updateObj.groups = [];

      const currentSimGroups: string[] = [];
      if(this.selectAllCheckBoxState){
        simCard.groups.forEach((group: string) => {
          currentSimGroups?.push(group);
        });
      } else {
        simCard.groups.forEach((groupData: SimcardGroupData) => {
          if (groupData?.['@id']) {
            currentSimGroups?.push(groupData?.['@id']);
          }
        });
      }

      if (this.batchGroupOpt === 'add') {
        if (simCard.groups) {
          currentSimGroups.forEach((group: string) => {
              updateObj.groups?.push(group);
          });
        }
        if (this.batchSimcardGroups && this.batchSimcardGroups.length > 0) {
          this.batchSimcardGroups?.forEach((groupData: SimcardGroupData) => {
            if (groupData?.['@id'] && updateObj.groups && !(groupData?.['@id'] in updateObj.groups)) {
              updateObj.groups?.push(groupData?.['@id']);
            }
          });
        }
      } else if (this.batchGroupOpt === 'change') {
        if (this.batchSimcardGroups && this.batchSimcardGroups.length > 0) {
          this.batchSimcardGroups?.forEach((groupData: SimcardGroupData) => {
            if (groupData?.['@id']) {
              updateObj.groups?.push(groupData?.['@id']);
            }
          });
        }
      } else if (this.batchGroupOpt === 'delete') {
        if (simCard.groups) {
          for (const simGroup of currentSimGroups) {
            let exist = false;
            if (this.batchSimcardGroups) {
              for (const simGroupTest of this.batchSimcardGroups) {
                if (simGroup === simGroupTest?.['@id']) {
                  exist = true;
                  break;
                }
              }
            }
            if (!exist) {
              updateObj.groups?.push(simGroup);
            }
          }
        }
      }
    }

    updateObj.customAttributes = {};

    if (this.chkBatchUpdateDataField1) {
      updateObj.customAttributes.customAttribute1 = this.dataField1;
    }

    if (this.chkBatchUpdateDataField2) {
      updateObj.customAttributes.customAttribute2 = this.dataField2;
    }

    if (this.chkBatchUpdateDataField3) {
      updateObj.customAttributes.customAttribute3 = this.dataField3;
    }

    if (this.chkBatchUpdateDataField4) {
      updateObj.customAttributes.customAttribute4 = this.dataField4;
    }

    if (this.chkBatchUpdateDataField5) {
      updateObj.customAttributes.customAttribute5 = this.dataField5;
    }

    return updateObj;
  }

  getSimCardsDataSessionsList(simcardData: SimcardData) {
    this.cardService.getDataSessionsForASimCard(this.sessionService.getJWTToken(), simcardData.uuid, this.dataSessionsPageNo, this.maxDataSessionsPerPage, this.mapDataSessionsKeys[this.searchDataSessionsKey as keyof MapDataSessionsKeys], this.sortDataSessionsDirection).subscribe({
      next: (data: DataSessionsList) => {
        const dataSessions: DataSessionsList = data;
        this.simCardsDataSessions = dataSessions['hydra:member'];

        this.totalDataSessions = (data["hydra:totalItems"]) ? data["hydra:totalItems"] : 0;
        this.showDSPaginator = true;
        this.loadingDS = false;

        //console.log(this.simCardsDataSessions);
      },
      error: (err) => {
        this.errorService.processError(err);
      }
    });
  }

  getSimCardsDataTrafficList(simcardData: SimcardData) {
    this.cardService.getDataTrafficForASimCard(this.sessionService.getJWTToken(), simcardData.uuid, this.dataSessionsPageNo, this.maxDataSessionsPerPage, this.mapDataSessionsKeys[this.searchDataSessionsKey as keyof MapDataSessionsKeys], this.sortDataSessionsDirection).subscribe({
      next: (data: DataTrafficList) => {
        const dataTraffic: DataTrafficList = data;
        this.simCardsDataTraffic = dataTraffic['hydra:member'];

        this.totalDataTraffic = (data["hydra:totalItems"]) ? data["hydra:totalItems"] : 0;
        this.showDTPaginator = true;
        this.loadingDT = false;

        //console.log('simCardsDataTraffic', this.simCardsDataTraffic);
      },
      error: (err) => {
        this.errorService.processError(err);
      }
    });
  }

  showView(enableCols: string[]) {
    this.enableCols = JSON.parse(JSON.stringify(enableCols));
    this.initialCols(this.enableCols);
    //console.log(enableCols);
  }

  deleteView(index: number) {
    if (this.userSettings.simcardViews) {
      this.userSettings.simcardViews.splice(index, 1);
      this.saveUserSettingsSuccessMessage = this.translate.instant('sim-cards.delete-view');
      this.saveUserSettings();
    }
  }

  setDefaultView(index: number) {
    let defaultSelected = false;
    if (this.userSettings.simcardViews) {
      
      this.userSettings.simcardViews.map((view, i) => {

        if(this.userSettings.simcardViews && index != -1)
          this.userSettings.simcardViews[index].default = true;

        if (index !== i)
          view.default = false

        if (index != -1)
          defaultSelected = true;
      });
    }

    if (defaultSelected) {
      this.enableColsDefaultChecked = false;
    } else {
      this.enableColsDefaultChecked = true;
    }

    this.saveUserSettingsSuccessMessage = this.translate.instant('sim-cards.set-default-view');
    this.saveUserSettings();
  }

  saveView() {
    if (this.userSettings.simcardViews) {
      this.userSettings.simcardViews.push({name: this.viewName, enableCols: this.enableCols, default: false, currentView: true});
    } else {
      this.userSettings.simcardViews = [{name: this.viewName, enableCols: this.enableCols, default: false, currentView: true}];
    }

    this.saveUserSettingsSuccessMessage = this.translate.instant('sim-cards.saved-view');
    this.saveUserSettings();
    this.viewName = "";
  }

  saveUserSettings() {
    this.userService.saveUserSettings(this.sessionService.getJWTToken(), this.userSettings).subscribe({
      next: () => {
        successMessage(this.saveUserSettingsSuccessMessage);
      },
      error: (err) => {
        this.errorService.processError(err);
      }
    });
  }

  getUserSettings() {
    this.userService.getUserSettings(this.sessionService.getJWTToken()).subscribe({
      next: (data) => {
        if (Object.keys(data).length) {
          this.userSettings = data;
        } else {
          this.userSettings = {};
        }

        this.checkDefaultView();
      },
      error: (err) => {
        this.errorService.processError(err);
      }
    });
  }

  checkDefaultView() {

    if (this.userSettings && this.userSettings.simcardViews) {
      for (const view of this.userSettings.simcardViews) {
        view.currentView = false;
        if (view.default) {
          view.currentView = true;
          this.currentViewDefaultChecked = false;
          this.enableCols = view.enableCols;
          this.enableColsDefaultChecked = false;
        }
      }

      this.initialCols(this.enableCols);
    }
  }

  setCurrentView(view:SimcardViews | null) {
    if (this.userSettings && this.userSettings.simcardViews) {
      for (const view of this.userSettings.simcardViews) {
        view.currentView = false;
      }
    }

    if(view) {
      view.currentView = true;
      this.currentViewDefaultChecked = false;
    } else {
      this.currentViewDefaultChecked = true;
    }
    
  }

  // history

  loadHistoryDataLazy(event: TableLazyLoadEvent): void {
    if (event && event.sortField && event.sortField && typeof event.sortField === 'string') {
      this.searchHistoryKey = event.sortField;
    }

    this.sortHistoryDirection = event.sortOrder;

    this.pageHistoryNumber = (event.first) ? (event.first / +this.maxHistoryNumberOfResults) + 1 : 1;

    this.getHistoryList();
  }

  changeNumberOfHistoryRecords() {
    this.tableHistoryIsVisible = false;

    setTimeout(() => {
      this.tableHistoryIsVisible = true;
    }, 50);
  }

  changeHistoryFilter() {
    this.getHistoryList();
  }

  filterHistoryClose(filter: FilterElement) {
    filter.checked = false;
    this.changeHistoryFilter();
  }

  getHistoryList() {
    this.loadingHistory = true;

    if (this.selectedSimcardData?.uuid) {
      this.cardService.getHistoryList(this.sessionService.getJWTToken(), this.selectedSimcardData.uuid, this.pageHistoryNumber, this.maxHistoryNumberOfResults, this.searchHistoryKey, this.sortHistoryDirection, this.filterHistoryList).subscribe({
        next: (data: HistoryList) => {
          this.historyList = data;
          this.historyData = data['hydra:member'];

          this.countHistoryItems = (data["hydra:totalItems"]) ? data["hydra:totalItems"] : 0;
          this.showPaginatorHistory = true;
          this.loadingHistory = false;

          //console.log('historyList', this.historyData);
        },
        error: (err) => {
          this.errorService.processError(err);
        }
      });
    }
  }

  // history end

  humanFileSize(bytes: number, dp = 2) {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    //const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const units = ['kB', 'MB', 'GB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }

  calculateNumDaysBetween(date1: string, date2: string): string {
    const Date_Start = new Date(date1);
    const Date_End = date2 ? new Date(date2) : new Date();

    let delta: number = Math.abs(Date_End.getTime() - Date_Start.getTime()) / 1000;

    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    const seconds = Math.floor(delta % 60);

    const hoursStr = ("0" + hours).slice(-2);
    const minutesStr = ("0" + minutes).slice(-2);
    const secondsStr = ("0" + seconds).slice(-2);

    return '' + days + ':' + hoursStr + ':' + minutesStr + ':' + secondsStr;
  }

  getCustomFieldsData() {
    this.cardService.getCustomSimCardFields(this.sessionService.getJWTToken()).subscribe({
      next: (response: CustomFieldsConfig) => {
        this.customFieldsList = response.simcardCustomAttributes;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  getBanList() {
    this.banList = [];
    this.banFilter = [];

    this.banPageNumber = 1;
    this.getBanAllPages();
  }

  getBanAllPages() {
    this.banService.getBanList(this.sessionService.getJWTToken(), this.banPageNumber, this.banItemsPerPage, this.banSortBy, 1, null).subscribe({
      next: (response: BanList) => {

        this.banList.push(...response['hydra:member']);

        if (response['hydra:totalItems'] && this.banList.length < response['hydra:totalItems']) {
          this.banPageNumber++;
          this.getBanAllPages();
        } else {

          for (const banElement of this.banList) {
            this.banFilter.push({value: banElement['@id'], label: banElement.ban});
          }

        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  listAllTariffs() {
    this.banService.getTariffList(this.sessionService.getJWTToken()).subscribe({
      next: (data: TariffDataList) => {
        this.listOfTariffs = data['hydra:member'];

        for (const tariff of this.listOfTariffs) {
          this.tariffs.push({value: tariff['@id'], label: tariff.name});
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  selectFileForDownload(event: any) {
    const csvFileForImport = event.target.files[0];
    const fileExtension = csvFileForImport.name.replace(/^.*\./, '');

    const accept = ["csv"];

    if (accept.indexOf(fileExtension.toLowerCase()) != 0 || (accept.indexOf(fileExtension.toLowerCase()) == 0 && csvFileForImport.size == 0)) {
      errorMessage(this.translate.instant('sim-cards.wrong-csv-file'));
      return;
    }

    this.selectedCSVFile = event.target.files[0];
    //console.log(this.selectedCSVFile);

    if (this.apply_filter_select === "ICCID") {
      this.cardService.createSimcardsICCIDFilter(this.sessionService.getJWTToken(), this.selectedCSVFile).subscribe({
        next: (filterData: SimcardsFilterData) => {
          this.filterList[this.filterList.length - 1].value = (undefined === filterData['@id']) ? "" : filterData['@id'];
          this.changeFilter();
        },
        error: (error: HttpErrorResponse) => {
          this.errorService.processError(error);
        }
      });
    } else {
      this.cardService.createSimcardsMSISDNFilter(this.sessionService.getJWTToken(), this.selectedCSVFile).subscribe({
        next: (filterData: SimcardsFilterData) => {
          this.filterList[this.filterList.length - 1].value = (undefined === filterData['@id']) ? "" : filterData['@id'];
          this.changeFilter();
        },
        error: (error: HttpErrorResponse) => {
          this.errorService.processError(error);
        }
      });
    }
  }

  resetCsvFile() {
    this.selectedCSVFile = null;
    this.apply_filter_file = null;
    this.filterList[this.filterList.length - 1].value = '';
    this.changeFilter();
  }

  protected readonly Math = Math;
}
