import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
    //TODO: For preventing Error 404 Page Not Found the line bellow shoud be commented
    { path: '', component: HomeComponent },
    { path: 'login', component: LoginComponent }

    //TODO: Required for preventing Error 404 Page Not Found
    //{ path: '', pathMatch: 'full', component: ConfigurationComponent },
    //{ path: '**', component: ConfigurationComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FrontwebRoutingModule { }