<!--
<div class="main-table-filters">
    <button class="refresh-page" (click)="reloadPage()">{{"general.reload" | translate}}</button>

    <div class="main-dropdown filters">
        <span>{{"general.filter" | translate}}</span>
        <ul>
            <li>
                <h3>{{"general.show-filter" | translate}}</h3>
            </li>
            <li *ngFor="let filter of filterList; let i = index;">
                <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                    [(ngModel)]="filter.checked" />
                <label for="filter-{{i}}">{{"table-cols."+filter.field | translate}}</label>
            </li>
        </ul>
    </div>
    <div class="selected-filters">
        <ng-container *ngFor="let filter of filterList; let i = index;">
            <div class="filter-item" *ngIf="filter.checked">
                <div class="filter-label">
                    <button class="filter-close" (click)="filterClose(filter)"><img alt="close filter"
                            src="assets/images/filter-close.svg" /></button> {{"table-cols."+filter.field | translate}}
                </div>
                <div class="filter-value">
                    <input *ngIf="filter.type==='input'" type="{{filter.type}}" (keyup.enter)="changeFilter()"
                        [style.width.px]="filter.value.length * 13" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <input *ngIf="filter.type==='date'" type="{{filter.type}}" (change)="changeFilter()"
                        [style.width.px]="150" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <select *ngIf="filter.type==='select'" (change)="changeFilter()" name="{{filter.field}}"
                        [(ngModel)]="filter.value">
                        <option *ngFor="let option of filter.options; let i = index;" value="{{option.value}}">
                            {{"job-history."+option.label | translate}}</option>
                    </select>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="clear"></div>
-->

<div class="table-block">
    <div class="table-outside-div">
        <p-table [columns]="cols" [value]="banList" [paginator]="showPaginator" (onLazyLoad)="loadDataLazy($event)" [lazy]="true"
            [loading]="loading" [rows]="itemsPerPage" [totalRecords]="countItems" *ngIf="tableIsVisible">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" class="table-data-col-label {{col.field}}" pReorderableColumn
                        pResizableColumn [pSortableColumn]="">
                        {{"table-cols."+col.field | translate}}
                        <!--
                        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        -->
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of cols">{{ rowData[col.field] }}</td>
                    <td class="buttons">
                        <button class="table-row-button"
                            (click)="editBan(rowData);"><img src="assets/images/row-edit.svg"
                                alt="edit" /></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div class="item-per-page">
            <label for="item-per-page">{{'general.objects-per-site'|translate}}</label>
            <select id="item-per-page" name="objectsNum" [(ngModel)]="itemsPerPage"
                #itemsPerPageRef="ngModel" (change)="changeNumberOfRecords();">
                <option *ngFor="let item of ['10','20','50','100','200','500','1000']" value="{{item}}">{{item}}
                </option>
            </select>
        </div>
    </div>
    <div>
        <input type="checkbox" class="modal-chkbox" id="modal" [(ngModel)]="isModalChecked">
        <label for="modal" class="disable-modal-label">{{"cost-center.new-cost-center"|translate}}</label>
        <label for="modal" class="modal-background"></label>
        <div class="modal">
            <label for="modal" class="close-modal"><img src="assets/images/close.svg" alt="close-icon"></label>
            <h3 class="modal-title">{{"ban.h-edit-ban"|translate}}</h3>

            <div class="tabbed-modal">
                <div>
                    <form name="banForm" ngForm #banForm="ngForm">
                        <div class="form-item">
                            <label for="ban">{{'table-cols.ban'|translate}}</label>
                            <input type="text" class="ban" id="ban" 
                                    [(ngModel)]="ban" name="ban" #banRef="ngModel"
                                    [value]="ban" disabled>
                        </div>
                        <div class="form-item">
                            <label for="description">{{'table-cols.description'|translate}}</label>
                            <input type="text" class="description" id="description" 
                                    [(ngModel)]="description" name="description" #descriptionRef="ngModel"
                                    [value]="description">
                        </div>
                        <div class="form-item">
                            <label for="defaultCostCenter">{{'table-cols.defaultCostCenter'|translate}}</label>                            
                            <p-dropdown scrollHeight="130px" placeholder="{{'general.choice'|translate}}"
                                    name="defaultCostCenter"
                                    [(ngModel)]="defaultCostCenter" [options]="costCenters" optionLabel="name" optionValue="@id"
                                    [filter]="true" filterBy="name"></p-dropdown>
                        </div>
                        <div class="form-item traffic-profile-ban">
                            <label for="defaultTrafficProfile">{{'table-cols.defaultTrafficProfile'|translate}}</label>                            
                            <p-dropdown scrollHeight="130px" placeholder="{{'general.choice'|translate}}"
                                    name="defaultTrafficProfile"
                                    [(ngModel)]="defaultTrafficProfile" [options]="trafficProfiles" optionLabel="name" optionValue="@id"
                                    [filter]="true" filterBy="name"></p-dropdown>
                        </div>
                    </form>
                </div>
            </div>
            <div class="button-center">
                <button type="submit" [disabled]="!banForm.valid" class="button save" id="submit" role="button" (click)="executeUpdateAction();">
                    {{"general.save"|translate}}
                </button>
            </div>
        </div>
    </div>
</div>
