import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, catchError, map, throwError } from 'rxjs';
import { SessionService } from './session.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BASE_URL } from '../../environments/environment';
import { ReportData } from '../interfaces/report-data';
import { ReportDataList } from '../interfaces/report-data-list';
import { FilterElement } from '../interfaces/filter-element';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private languageSubscription: Subscription;

  constructor(private http: HttpClient, private sessionService: SessionService, private translate: TranslateService) {
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    

  }

  createNewReport(token: string | null, reportFormData: ReportData, defaultLanguage = 'de'): Observable<ReportData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<ReportData>(BASE_URL + '/api/v2/reports', reportFormData, httpOptions).pipe(map((reportData: ReportData) => {
      return reportData;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
    }));
  }

  updateReport(token: string | null, reportFormData: ReportData, uuid: string | undefined, defaultLanguage = 'de'): Observable<ReportData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.patch<ReportData>(BASE_URL + '/api/v2/reports/' + uuid, reportFormData, httpOptions).pipe(map((reportData: ReportData) => {
      return reportData;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
    }));
  }

  getReport(token: string | null, uuid: string | undefined,  defaultLanguage = 'de') : Observable<ReportData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token);

    const httpOptions = {
      headers: httpHeaders,
    };

    return this.http.get<ReportData>(BASE_URL + '/api/v2/reports/' + uuid, httpOptions).pipe(map((reportData: ReportData) => {
      return reportData;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
    }));
  }

  downloadReport(token: string | null, uuid: string | undefined,  defaultLanguage = 'de') : Observable<string> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'text/csv')
      .set('Accept', 'text/csv')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token);

    const httpOptions: NonNullable<unknown> = {
      headers: httpHeaders,
      responseType: 'text' as const
    };

    return this.http.get<string>(BASE_URL + '/api/v2/reports/' + uuid + '/download', httpOptions).pipe(map((reportData: string) => {
      return reportData;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
    }));
  }

  getReports(token: string | null, page = 1, numberOfItems = 20, sortKey: string | null = null, sortDirection: number | undefined | null = 0, filterElements: FilterElement[] | null, isTemplate = false, isScheduler = false, defaultLanguage = 'de'): Observable<ReportDataList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('page', '' + page)
      .set('itemsPerPage', '' + numberOfItems);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }

    params = params.set('isTemplate', isTemplate);
    params = params.set('exists[schedule]', isScheduler);

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token);

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<ReportDataList>(BASE_URL + '/api/v2/reports', httpOptions).pipe(map((reportData: ReportDataList) => {
      return reportData;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
    }));
  }

  deleteReport(token: string | null, id: string | undefined, defaultLanguage = 'de'): Observable<ReportData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.delete<ReportData>(BASE_URL + id, httpOptions).pipe(map((userData: ReportData) => {
      return userData;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
    }));
  }
}
