<!--
<input type="checkbox" class="modal-chkbox" id="modal" [(ngModel)]="isModalChecked">
<label for="modal"></label>
<label for="modal" class="modal-background"></label>
-->
<div class="modal-alternativ">
    <!--
    <label for="modal" class="close-modal"><img src="assets/images/close.svg" alt="close-icon"></label>
    -->
    <h3 class="modal-title">{{'sim-card-activate.activate-title' | translate}}</h3>
    <div class="tabbed-modal">
        <form name="simCardActivateForm" ngForm #simCardActivateForm="ngForm">
            <div class="form-item">
                <label for="Bezeichnung" class="col-form-label">{{'sim-card-activate.cost-center' | translate}}</label>
                <select id="item-per-page" name="objectsNum" [(ngModel)]="selectedCostCenterID" #costCenterRef="ngModel"
                    (change)="findAllTariffs();">
                    <option value="">{{"general.choice" | translate}}</option>
                    <option *ngFor="let item of costCenters" value="{{item['@id']}}">
                        {{item.name}}
                    </option>
                </select>
            </div>
            <div class="form-item">
                <label for="BAN" class="col-form-label">{{'sim-card-activate.traffic-profile' | translate}}</label>
                <select id="item-per-page" name="objectsNum" [(ngModel)]="selectedTrafficProfileDataID"
                    #trfficProfileObjectRef="ngModel">
                    <option value="">{{"general.choice" | translate}}</option>
                    <option *ngFor="let item of trafficProfiles" value="{{item['@id']}}">
                        {{item.name}}
                    </option>
                </select>
            </div>
            <div class=" form-item">
                <label for="BAN" class="col-form-label">{{'sim-card-activate.tariff' | translate}}</label>
                <select id="item-per-page" name="objectsNum" [(ngModel)]="selectedTariffID" #tariffObjectRef="ngModel">
                    <option *ngFor="let item of banAvailableTariffs" value="{{item['@id']}}">
                        {{item.name}}
                    </option>
                </select>
            </div>
            <div class="form-item">
                <label for="simnumber">{{'sim-card-activate.sim-serial' | translate}}</label>
                <textarea id="simnumber" class="input-with-button" name="simCardEntry" #simCardEntryRef="ngModel" (keydown.enter)="addToSimList()" (keyup.enter)="simCardEntryField=''"
                    aria-describedby="KostenstelleHelpInline" [(ngModel)]="simCardEntryField"></textarea>
                <button (click)="addToSimList();" class="input-icon-button">+</button>
            </div>
            <div class="form-item">
                <div class="sim-card-line" *ngFor="let simLine of simCardEntryList; let i = index;">
                    <div class="input-with-delete-button">{{simLine}}</div>
                    <button (click)="removeSelectedSim(i);" class="delete-input-icon-button"><img
                            src="assets/images/row-delete.svg" alt="delete"></button>
                </div>
            </div>
        </form>
    </div>
    <div class="button-center">
        <button type="button" class="button save" id="submit" role="button"
            [disabled]="selectedCostCenterID===''||selectedTrafficProfileDataID===''||selectedTariffID===''||simCardEntryList.length===0"
            (click)="executeUpdateAction();">
            {{'sim-card-activate.activate-button' | translate}}
        </button>
    </div>
</div>