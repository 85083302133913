<div class="modal-alternativ account">
  <h3 class="modal-title">{{'profile.login-data' | translate}}</h3>

  <div class="block-with-background">
    <!-- show Login data -->
    <div *ngIf="!editLoginData">
      <div class="item">
        <label for="username">{{"profile.username"|translate}}</label><span id="username">{{activeUser.qualifiedUsername}}</span>
      </div>
      <div class="item">
        <label for="password">{{"profile.password"|translate}}</label><span id="password">******</span>
      </div>

      <div class="button-center">
        <button class="button edit" (click)="editLoginData = true"><img src="assets/images/edit.svg" alt="edit-icon" />
          Passwort bearbeiten</button>
      </div>
    </div>

    <!-- edit Login data -->
    <div *ngIf="editLoginData">
      <form name="userDataForm" ngForm #userDataForm="ngForm">
        <!-- form for change password-->
        <div class="change-password-block">
          <div class="item password form-item">
            <label for="uPassword" class="field-title">
              {{'profile.currentpassword' | translate}}
            </label>
            <input id="uPassword" maxlength="255" name="uPassword" placeholder="" type="password" value=""
              [(ngModel)]="uPassword" required #uPasswordRef="ngModel">
            <br>
            <span class="required_error_message"
              *ngIf="!uPasswordRef.valid && uPasswordRef.touched">{{'profile.setpassword' | translate}}</span>
          </div>
          <div class="item password form-item">
            <label for="info" class="field-title">
              {{'profile.newpassword' | translate}}
              <span class="info" id="info">
                <span class="info-text">
                  {{'profile.password-i1' | translate}} ~!@$%^*_-+=`|\()&#123;&#125;[]:;”’<>,.?/#&.
                    {{'profile.password-i2' | translate}}
                </span>
              </span>
            </label>
            <input id="uNewPassword" class="newPassword" name="uNewPassword" placeholder="" type="password" value=""
              [(ngModel)]="uNewPassword" required minlength="13" maxlength="255" #uNewPasswordRef="ngModel"
              pattern="((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[+\-<>~_\(\)\{\}\[\]:;&apos;&quot;”’`|.,?/=!@#$%^&*])).{13,}">
            <span class="required_error_message"
              *ngIf="!uNewPasswordRef.valid && uNewPasswordRef.touched">{{'profile.password-not-secure' |
              translate}}</span>
          </div>
          <div class="item password form-item">
            <label for="uPasswordConfirm" class="field-title">
              {{'profile.password-repeat' | translate}}
            </label>
            <input id="uPasswordConfirm" name="uPasswordConfirm" placeholder="" type="password" value=""
              [(ngModel)]="uPasswordConfirm" required minlength="13" maxlength="255" #uPasswordConfirmRef="ngModel"
              pattern="((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[+\-<>~_\(\)\{\}\[\]:;&apos;&quot;”’`|.,?/=!@#$%^&*])).{13,}">
            <span class="required_error_message"
              *ngIf="(!uPasswordConfirmRef.valid || uNewPasswordRef.value !== uPasswordConfirmRef.value) && uPasswordConfirmRef.touched">{{'profile.password-different'
              | translate}}</span>
          </div>

          <div class="item password form-item" *ngIf="isAlreadyActivated">
            <label for="barCodeNumber" class="field-title">
              {{'profile.generated-pin' | translate}}
            </label>
            <input id="barCodeNumber" maxlength="255" name="barCodeNumber" required type="text" value=""
              [(ngModel)]="barCodeNumber" #barCodeNumberRef="ngModel">
            <span class="required_error_message"
              *ngIf="!barCodeNumberRef.valid && barCodeNumberRef.touched">{{'profile.2fa-app-valid-pin' |
              translate}}</span>
          </div>
        </div>
        <!-- end form for change password-->

        <div class="button-center">
          <button class="gray-button button" (click)="editLoginData = false">{{"profile.cancel"|translate}}</button>
          <button class="save button" [disabled]="!userDataForm.valid || !userDataForm.dirty"
            (click)="changeCurrentPassword();">{{"profile.save"|translate}}</button>
        </div>
      </form>
    </div>

  </div>

  <h3 class="modal-title">{{'profile.totp' | translate}}</h3>

  <div class="block-with-background">
    <!-- Device List -->
    <div class="no-devices-text" *ngIf="totpDevices === undefined || totpDevices.length < 1">
      {{'profile.totp-deactiveted' | translate}}
    </div>

    <div class="devices-list" *ngIf="totpDevices !== undefined && totpDevices.length > 0">
      <div class="header">
        <span class="devicename">{{'profile.devicename' | translate}}</span>
        <span class="from">{{'profile.deviceFrom' | translate}}</span>
        <span class="action"></span>
      </div>
      <div *ngFor="let device of totpDevices; let i = index; trackBy:deviceIdentifier">
        <span class="devicename">{{device.name}}</span>
        <span class="from">01.01.2023</span>
        <span class="action">
          <button (click)="prepareDeleteDevice(device);" class="deletedevice"><img src="assets/images/row-delete.svg"
              alt="delete"></button>
        </span>
      </div>
    </div>

    <div class="button-center">
      <button class="button edit" (click)="executeDeviceModal();"><span class="add-plus">+</span>
        {{'profile.adddevice' | translate}}</button>
    </div>
  </div>
</div>

<!-- delete modal -->
<div *ngIf="showDeleteModal" class="custom-modal delete-modal">
  <div class="custom-modal-content">
    <p class="text-align-center">{{'profile.deleteQuestion' | translate}}</p>
    <div class="button-center">
      <button class="gray-button button" (click)="showDeleteModal = false">{{"profile.cancel"|translate}}</button>
      <button class="save button" type="button"
        (click)="deleteCurrentDevice(prepereDeleteDevice);">{{"profile.delete"|translate}}</button>
    </div>
  </div>
</div>
