import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable, BehaviorSubject, of, Subject, throwError } from 'rxjs';

import { catchError, finalize, map, tap } from 'rxjs/operators';

import { BASE_URL } from 'src/environments/environment';

import { CostCenterList } from '../interfaces/cost-center-list';
import { FilterElement } from '../interfaces/filter-element';
import { CostCenter } from '../interfaces/cost-center';

const SERVICE_URL = "/cost_centers";

@Injectable({
  providedIn: 'root'
})
export class CostCenterService {

  constructor(private http: HttpClient) { }

  public getCostCenterList(token: string | null, sortKey: string | null = null, sortDirection: number | undefined | null = 0, filterElements: FilterElement[] | null = null, defaultLanguage = 'de'): Observable<CostCenterList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('pagination', false);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<CostCenterList>(BASE_URL + '/api/v2' + SERVICE_URL, httpOptions).pipe(map((costCenterList: CostCenterList) => {
      const dataResult = costCenterList;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getCostCenterCSV(token: string | null, defaultLanguage = 'de'): Observable<string> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const params = new HttpParams()
      .set('pagination', false);

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'text/csv')
      .set('Accept', 'text/csv')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token);

    const httpOptions: NonNullable<unknown> = {
      headers: httpHeaders,
      params: params,
      responseType: 'text' as const
    };

    return this.http.get<string>(BASE_URL + '/api/v2' + SERVICE_URL, httpOptions).pipe(map((costCenterList: string) => {
      const dataResult = costCenterList;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  createNewCostCenter(token: string | null, costCenterData: CostCenter, defaultLanguage = 'de'): Observable<CostCenter> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<CostCenter>(BASE_URL + '/api/v2' + SERVICE_URL, costCenterData, httpOptions).pipe(map((costCenterRes: CostCenter) => {
      const dataResult = costCenterRes;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  updateCostCenter(token: string | null, uuid: string | undefined, costCenterData: CostCenter, defaultLanguage = 'de'): Observable<CostCenter> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.patch<CostCenter>(BASE_URL + uuid, costCenterData, httpOptions).pipe(map((costCenter: CostCenter) => {
      const dataResult = costCenter;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  deleteSimcardGroup(token: string | null, uuid: string | undefined, defaultLanguage = 'de'): Observable<any> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.delete<any>(BASE_URL + uuid, httpOptions).pipe(map((response: any) => {
      const dataResult = response;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  createNewOrder(token: string | null, costCenterUUID: string | undefined, orderData: any, defaultLanguage = 'de'): Observable<CostCenter> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<any>(BASE_URL + '/api/v2' + SERVICE_URL + '/' + costCenterUUID + '/order_simcards', orderData, httpOptions).pipe(map((costCenterRes: any) => {
      const dataResult = costCenterRes;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }
}
