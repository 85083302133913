export const environment = {
    production: false
};

export * from './settings.de';

//export const BASE_URL = 'https://localhost';
export const BASE_URL = '';
export const CLIENT_ID = '';
export const CLIENT_SECRET = '';

