import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
//import { LazyLoadEvent } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CostCenterService } from './../../services/cost-center.service';
import { TrafficProfileService } from './../../services/traffic-profile.service';
import { CostCenter } from 'src/app/interfaces/cost-center';
import { CostCenterList } from 'src/app/interfaces/cost-center-list';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { BanService } from 'src/app/services/ban.service';
import { CardService } from 'src/app/services/card.service';
import { Ban } from 'src/app/interfaces/ban';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { FilterElement } from 'src/app/interfaces/filter-element';
import { TrafficProfileList } from 'src/app/interfaces/traffic-profile-list';
import { TrafficProfile } from 'src/app/interfaces/traffic-profile';
import { TariffData, TariffDataList } from 'src/app/interfaces/tariff-data';
import { SimcardData } from 'src/app/interfaces/simcard-data';

declare const successMessage: FunctionStringCallback;

interface MapKeys {
  name: string,
}

interface ActiveBatchActionsSessionData {
  uuid?: string
}

declare let $: any;

@Component({
  selector: 'app-sim-card-order',
  templateUrl: './sim-card-order.component.html',
  styleUrls: ['./sim-card-order.component.css']
})
export class SimCardOrderComponent implements OnInit {
  private languageSubscription: Subscription;

  public isModalChecked = true;

  public costCentersList: unknown;
  public costCenters: CostCenter[] = [];
  public selectedCostCenterData: CostCenter | null | undefined = null;
  public selectedCostCenterID = '';

  public sortDirection: number | null | undefined;
  public searchKey = '';
  public filterList: FilterElement[] = [];
  public mapKeys: MapKeys = {
    name: 'name'
  };

  public companyName = '';
  public contactFirstName = '';
  public contactLastName = '';
  public contactTelephone = '';
  public contactEMail = '';

  public orderCompanyName = '';
  public orderFirstName = '';
  public orderLastName = '';
  public orderTelephone = '';
  public orderEMail = '';

  public deliveryCompanyName = '';
  public deliveryFirstName = '';
  public deliveryLastName = '';
  public deliveryAddress = '';
  public deliveryZipCode = '';
  public deliveryCity = '';
  public deliveryCountry = '';

  public simMiniWPin = 0;
  public simMiniNoPin = 0;
  public simMicroWPin = 0;
  public simMicroNoPin = 0;
  public simNanoWPin = 0;
  public simNanoNoPin = 0;

  public chkAcceptOrder = false;

  public sended = false;

  constructor(private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private sessionService: SessionService,
    private costCenterService: CostCenterService,
    private errorService: ErrorHandlerService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }

    this.listAllCostCenters();
  }

  ngOnInit() {
    console.log('initialized');
  }

  listAllCostCenters() {
    this.costCenterService.getCostCenterList(this.sessionService.getJWTToken(), this.mapKeys[this.searchKey as keyof MapKeys], this.sortDirection, this.filterList).subscribe({
      next: (data: CostCenterList) => {
        this.costCentersList = data;
        //console.log(this.costCentersList);

        this.costCenters = data['hydra:member'];
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  executeUpdateAction() {
    //console.log('SAVE');

    const costCenter = this.costCenters.find((item: CostCenter) => item['@id'] === this.selectedCostCenterID);

    if (costCenter) {
      const orderData: unknown = {
        "costCenter": this.selectedCostCenterID,
        "contact": {
          "company": this.companyName,
          "lastName": this.contactLastName,
          "firstName": this.contactFirstName,
          "phoneNr": this.contactTelephone,
          "email": this.contactEMail
        },
        "authorization": {
          "lastName": this.orderLastName,
          "firstName": this.orderFirstName,
          "phoneNr": this.orderTelephone,
          "email": this.orderEMail
        },
        "delivery": {
          "company": this.deliveryCompanyName,
          "lastName": this.deliveryLastName,
          "firstName": this.deliveryFirstName,
          "address": this.deliveryAddress,
          "zipCode": this.deliveryZipCode,
          "city": this.deliveryCity,
          "country": this.deliveryCountry
        },
        "amounts": {
          "miniWithPin": this.simMiniWPin,
          "miniWithoutPin": this.simMiniNoPin,
          "microWithPin": this.simMicroWPin,
          "microWithoutPin": this.simMicroNoPin,
          "nanoWithPin": this.simNanoWPin,
          "nanoWithoutPin": this.simNanoNoPin
        }
      };

      this.costCenterService.createNewOrder(this.sessionService.getJWTToken(), costCenter?.uuid, orderData).subscribe({
        next: (response: unknown) => {
          console.log(response);
          this.resetFormValues();
          this.sended = true;

          successMessage(this.translate.instant('sim-card-order.send-message'));
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.processError(err);
        },
      });
    }
  }

  resetFormValues() {
    this.selectedCostCenterData = null;
    this.selectedCostCenterID = '';
    this.companyName = '';
    this.contactFirstName = '';
    this.contactLastName = '';
    this.contactTelephone = '';
    this.contactEMail = '';

    this.orderCompanyName = '';
    this.orderFirstName = '';
    this.orderLastName = '';
    this.orderTelephone = '';
    this.orderEMail = '';

    this.deliveryCompanyName = '';
    this.deliveryFirstName = '';
    this.deliveryLastName = '';
    this.deliveryAddress = '';
    this.deliveryZipCode = '';
    this.deliveryCity = '';
    this.deliveryCountry = '';

    this.simMiniWPin = 0;
    this.simMiniNoPin = 0;
    this.simMicroWPin = 0;
    this.simMicroNoPin = 0;
    this.simNanoWPin = 0;
    this.simNanoNoPin = 0;

    this.chkAcceptOrder = false;
  }
}
