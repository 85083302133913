import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
//import { LazyLoadEvent } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CostCenterService } from './../../services/cost-center.service';
import { TrafficProfileService } from './../../services/traffic-profile.service';
import { CostCenter } from 'src/app/interfaces/cost-center';
import { CostCenterList } from 'src/app/interfaces/cost-center-list';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { BanService } from 'src/app/services/ban.service';
import { CardService } from 'src/app/services/card.service';
import { Ban } from 'src/app/interfaces/ban';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterElement } from 'src/app/interfaces/filter-element';
import { TrafficProfileList } from 'src/app/interfaces/traffic-profile-list';
import { TrafficProfile } from 'src/app/interfaces/traffic-profile';
import { TariffData, TariffDataList } from 'src/app/interfaces/tariff-data';

declare const successMessage: FunctionStringCallback;

interface MapKeys {
  name: string,
}

interface ActiveBatchActionsSessionData {
  uuid?: string
}

declare let $: any;

@Component({
  selector: 'app-sim-card-activate',
  templateUrl: './sim-card-activate.component.html',
  styleUrls: ['./sim-card-activate.component.css']
})
export class SimCardActivateComponent implements OnInit {
  private languageSubscription: Subscription;

  public costCentersList: unknown;
  public costCenters: CostCenter[] = [];
  public selectedCostCenterData: CostCenter | null | undefined = null;
  public selectedCostCenterID = '';

  public trafficProfiles: TrafficProfile[] = [];
  public trafficProfilesList: unknown;
  public selectedTrafficProfileData: TrafficProfile | null = null;
  public selectedTrafficProfileDataID = '';

  public tariffList: unknown;
  public listOfTariffs: TariffData[] = [];
  public selectedTariff: TariffData = {};
  public banAvailableTariffs: TariffData[] = [];
  public selectedTariffID = '';

  public isModalChecked = true;
  public simCardEntryField = '';
  public simCardEntryList!: string[];

  public selectedBan: Ban = {};

  public sortDirection: number | null | undefined;
  public searchKey = '';
  public filterList: FilterElement[] = [];
  public mapKeys: MapKeys = {
    name: 'name'
  };

  public activeBatchActionsSessionData: ActiveBatchActionsSessionData = {};

  constructor(private router: Router,
              private userService: UserService,
              private translate: TranslateService,
              private sessionService: SessionService,
              private costCenterService: CostCenterService,
              private banService: BanService,
              private trafficProfileService: TrafficProfileService,
              private errorService: ErrorHandlerService,
              private cardService: CardService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }

    this.simCardEntryList = [];

    this.listAllCostCenters();
    this.listAllTrafficProfiles();
    this.listAllTariffs();
  }

  listAllCostCenters() {
    this.costCenterService.getCostCenterList(this.sessionService.getJWTToken(), this.mapKeys[this.searchKey as keyof MapKeys], this.sortDirection, this.filterList).subscribe({
      next: (data: CostCenterList) => {
        this.costCentersList = data;
        //console.log(this.costCentersList);

        this.costCenters = data['hydra:member'];
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  listAllTrafficProfiles() {
    this.trafficProfileService.getTrafficProfileList(this.sessionService.getJWTToken(), this.mapKeys[this.searchKey as keyof MapKeys], this.sortDirection, this.filterList).subscribe({
      next: (data: TrafficProfileList) => {
        this.trafficProfilesList = data;
        //console.log(this.trafficProfilesList);
        this.trafficProfiles = data['hydra:member'];
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  listAllTariffs() {
    this.banService.getTariffList(this.sessionService.getJWTToken()).subscribe({
      next: (data: TariffDataList) => {
        this.listOfTariffs = data['hydra:member'];
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  findAllTariffs() {
    const selectedCostCenterData = this.costCenters.find(item => item['@id'] == this.selectedCostCenterID);
    this.selectedCostCenterData = selectedCostCenterData;

    this.banService.getBanDetails(this.sessionService.getJWTToken(), this.selectedCostCenterData?.ban).subscribe({
      next: (response: Ban) => {
        this.selectedBan = response;

        this.findAllTariffsForBan(response);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  findAllTariffsForBan(banData: Ban) {
    this.banAvailableTariffs = [];
    for (const banTariff of banData.availableTariffs!) {
      for (const itemTariff of this.listOfTariffs) {
        if (banTariff === itemTariff['@id']) {
          this.banAvailableTariffs.push(itemTariff);
          break;
        }
      }
    }
  }

  ngOnInit() {
    console.log('initialized')
  }

  executeUpdateAction() {
    this.errorService.deleteFieldError();
    this.executeSimcardsBatchAction();
  }

  addToSimList() {
    if (this.simCardEntryField !== '') {
      const cards = this.simCardEntryField.split(/\r?\n|\r|\n/g);
      for (const card of cards) {
        let trimmedCard = card.trim();
        if (trimmedCard !== '') {
          if (!trimmedCard.startsWith('894920')) {
            trimmedCard = '894920' + trimmedCard;
          }
          this.simCardEntryList.push(trimmedCard);
        }
      }
    }
    this.simCardEntryField = '';
  }

  removeSelectedSim(simIndex: number) {
    this.simCardEntryList.splice(simIndex, 1);
  }

  executeSimcardsBatchAction() {
    this.cardService.createBatchSimcardsActivationActionSession(this.sessionService.getJWTToken(), "activate_simcards", "", this.selectedCostCenterID, this.selectedTariffID, this.selectedTrafficProfileDataID).subscribe({
      next: (response) => {
        this.activeBatchActionsSessionData = response;

        this.executeUpdateLogic();
      },
      error: (err) => {
        this.errorService.processError(err);
      }
    });
  }

  executeUpdateLogic() {
    this.cardService.addSimcardsICCIDsToBatchSession(this.sessionService.getJWTToken(), this.activeBatchActionsSessionData.uuid, this.simCardEntryList).subscribe({
      next: (response) => {
        this.executeBatchActionsSubmit();
        return response;
      },
      error: (err) => {
        this.errorService.processError(err);
      },
    });
  }

  executeBatchActionsSubmit() {
    this.cardService.submitSimcardsBatchSession(this.sessionService.getJWTToken(), this.activeBatchActionsSessionData.uuid).subscribe({
      next: (response) => {
        successMessage(this.translate.instant('general.update-started-message'));

        this.simCardEntryList = [];

        return response;
      },
      error: (err) => {
        this.errorService.processError(err);
      },
    });
  }
}
