import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CardService } from 'src/app/services/card.service';
import { UserGroup, UserGroupList } from 'src/app/interfaces/user-group';
import { CostCenterRole } from 'src/app/interfaces/cost-center-role';
import { PermissionsData, PermissionsList } from 'src/app/interfaces/permissionslist';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FilterElement } from 'src/app/interfaces/filter-element';
import { CustomTableColumn } from 'src/app/interfaces/custom-table-column';
import { CostCenterService } from 'src/app/services/cost-center.service';
import { CostCenterList } from 'src/app/interfaces/cost-center-list';
import { CostCenter } from 'interfaces/costcenter';
import { MultiSelectModule } from 'primeng/multiselect';

declare const successMessage: FunctionStringCallback;

@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.css']
})
export class UserGroupsComponent implements OnInit, OnDestroy {
  private languageSubscription: Subscription;

  public userGroupList: UserGroup[] = [];
  public showPaginator = false;
  public loading = true;
  public maxNumberOfResults = 10;
  public countItems = 100;
  public pageNumber = 1;
  public tableIsVisible = true;
  public userGroupSelected = false;
  public isModalChecked = false;
  public selectedUserGroupData: UserGroup | null = null;
  public costCenterRoles: CostCenterRole[] = [];
  public globalPermissions: PermissionsData[] = [];
  public simcardPermissions: PermissionsData[] = [];
  public editUserGroupPermissions: string[] = [];
  public userGroupFormData: UserGroup = {};
  public name = "";
  public editExistingUserGroupUuid = "";
  public requireTwoFactor = false;
  public searchKey: string | null | undefined;
  public sortDirection: number | null | undefined;
  public filterList: FilterElement[] = [];
  public cols!: CustomTableColumn[];
  public prepereDeleteUserGroup: UserGroup = {};
  public showDeleteModal = false;
  public costCenters: CostCenter[] = [];
  public usedCostCenters: string[] = [];
  public first = 0;

  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService, private errorService: ErrorHandlerService, private costCenterService: CostCenterService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }

    this.cols = [
      { field: 'name', checked: true }
    ];

    this.filterList = [
      {field: 'name', type: 'input', checked: false, value: ""},
    ];

    this.getGlobalPermissions();
    this.getSimcardPermissions();
    this.listAllCostCenters();
  }

  ngOnInit() {
    console.log('initialized');
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  loadDataLazy(event: TableLazyLoadEvent): void {
    if (event && event.sortField && event.sortField && typeof event.sortField === 'string') {
      this.searchKey = event.sortField;
    }

    this.sortDirection = event.sortOrder;

    this.pageNumber = (event.first)?(event.first / +this.maxNumberOfResults) + 1:1;

    this.getUserGroupList();
  }

  changeNumberOfRecords() {
    this.tableIsVisible = false;

    setTimeout(() => {
      this.tableIsVisible = true;
    }, 50);
  }

  addNewCostCenterRole() {
    if (this.costCenterRoles == undefined) this.costCenterRoles = [];
    this.costCenterRoles.push({ costCenter: "", permissions: [] });
  }

  deleteFromRoleList(index: number): void {
    this.costCenterRoles.splice(index, 1);
  }

  reloadPage() {
    this.getUserGroupList();
  }

  regenerateUsedCostCenters() {
    this.usedCostCenters = [];

    for(const costCenterRole of this.costCenterRoles) {
      if(costCenterRole.costCenter) {
        this.usedCostCenters.push(costCenterRole.costCenter);
      }
    }

    //console.log(this.usedCostCenters);
  }

  getUserGroupList() {
    this.loading = true;

    this.userService.getUserGroupList(this.sessionService.getJWTToken(), this.pageNumber, this.maxNumberOfResults, this.searchKey, this.sortDirection, this.filterList).subscribe({
      next: (data: UserGroupList) => {
        this.userGroupList = data['hydra:member'];

        this.countItems = (data["hydra:totalItems"])?data["hydra:totalItems"]:0;
        this.showPaginator = true;
        this.loading = false;

        //console.log('batchList', this.batchData);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  saveUserGroup() {
    if(this.editExistingUserGroupUuid != '') {
      this.updateUserGroup();
    } else {
      this.createUserGroup();
    }
  }

  updateUserGroup() {
    this.errorService.deleteFieldError();
    this.generateUserGroupFormData();

    this.userService.updateUserGroup(this.sessionService.getJWTToken(), this.userGroupFormData, this.editExistingUserGroupUuid).subscribe({
      next: (response: UserGroup) => {
        successMessage(this.translate.instant("user-groups.updated-group"));
        this.isModalChecked = false;
        this.getUserGroupFormData({});
        this.reloadPage();
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  createUserGroup() {
    this.errorService.deleteFieldError();
    this.generateUserGroupFormData();

    this.userService.createUserGroup(this.sessionService.getJWTToken(), this.userGroupFormData).subscribe({
      next: (response: UserGroup) => {
        successMessage(this.translate.instant("user-groups.created-group"));
        this.isModalChecked = false;
        this.getUserGroupFormData({});
        this.reloadPage();
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  generateUserGroupFormData() {
    this.userGroupFormData = {
      name: this.name,
      permissions: this.editUserGroupPermissions,
      requireTwoFactor: this.requireTwoFactor,
      simcardAcl: this.costCenterRoles
    }
  }

  getUserGroupFormData(userGroup: UserGroup) {
    //console.log(userGroup);
    this.name = (userGroup.name)?userGroup.name:'';
    this.requireTwoFactor = (userGroup.requireTwoFactor)?userGroup.requireTwoFactor:false;
    this.editUserGroupPermissions = (userGroup.permissions)?userGroup.permissions:[];
    this.editExistingUserGroupUuid = (userGroup.uuid)?userGroup.uuid:'';

    this.costCenterRoles = (userGroup.simcardAcl)?userGroup.simcardAcl:[];
    /* alternative: 
    this.costCenterRoles = [];
    if(userGroup.simcardAcl) {
      for (const costCenterRole of userGroup.simcardAcl) {
        this.costCenterRoles.push({costCenter: costCenterRole.costCenter, permissions: costCenterRole.permissions});
      }
    }
    end for alternative */

    if(userGroup.permissions) {
      for (const permission of this.globalPermissions) {
        if(permission.name && this.editUserGroupPermissions.includes(permission.name)) {
          permission.checked = true;
        }
      }
    } else {
      this.globalPermissions.map(permission => permission.checked = false); // Disable all Ckeckbox
    }
    
  }

  getGlobalPermissions() { // persmions für User und User Gruppen mit scope = global
    this.userService.getPermissions(this.sessionService.getJWTToken(), 'global').subscribe({
      next: (response: PermissionsList) => {
        this.globalPermissions = response['hydra:member'];
        //console.log(this.globalPermissions);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  getSimcardPermissions() { // persmions für CostCenter mit scope = simcard
    this.userService.getPermissions(this.sessionService.getJWTToken(), 'simcard').subscribe({
      next: (response: PermissionsList) => {
        this.simcardPermissions = response['hydra:member'];
        //console.log(this.globalPermissions);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  changePermissions() {
    this.editUserGroupPermissions = [];

    for (const permission of this.globalPermissions) {
      if(permission.checked && permission.name) {
        this.editUserGroupPermissions.push(permission.name);
      }
    }
    //console.log(this.editGroupPermissions);
  }

  editUserGroup(userGroup: UserGroup) {
    this.errorService.deleteFieldError();
    this.getUserGroupFormData(userGroup);
    this.regenerateUsedCostCenters();
    this.isModalChecked = true;
  }

  prepareDeleteUserGroup(userGroup: UserGroup) {
    this.errorService.deleteFieldError();
    this.prepereDeleteUserGroup = userGroup;
    this.showDeleteModal = true;
  }

  deleteSelectedUserGroup(userGroup: UserGroup) {
    this.userService.deleteUserGroup(this.sessionService.getJWTToken(), userGroup?.['@id']).subscribe({
      next: (response: UserGroup) => {
        //console.log(response);
        this.reloadPage();

        successMessage(this.translate.instant("user.user-deleted"));
        this.showDeleteModal = false;
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  createNewUserGroupButton() {
    this.errorService.deleteFieldError();
    this.getUserGroupFormData({});
    this.regenerateUsedCostCenters();
  }

  changeFilter() {
    //console.log(filter.field);
    //console.log(this.filterList);
    this.first = 0;
    this.pageNumber = 1;
    this.getUserGroupList();
  }

  filterClose(filter: FilterElement) {
    filter.checked = false;
    this.changeFilter();
  }

  listAllCostCenters() {
    this.costCenterService.getCostCenterList(this.sessionService.getJWTToken(), 'name', 0, null).subscribe({
      next: (data: CostCenterList) => {
        this.costCenters = data['hydra:member'];
        //console.log(this.costCenters);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }
}
