<div class="main-table-filters">
    <!-- refresh -->
    <button class="refresh-page" (click)="reloadPage()">{{"general.reload" | translate}}</button>

    <!-- SELECT SEARCH FILTER -->
    <div class="main-dropdown filters">
        <span>{{"general.filter" | translate}}</span>
        <ul>
            <li>
                <h3>{{"general.show-filter" | translate}}</h3>
            </li>
            <li *ngFor="let filter of filterList; let i = index;">
                <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                    [(ngModel)]="filter.checked" />
                <label for="filter-{{i}}">{{"table-cols."+filter.field | translate}}</label>
            </li>
        </ul>
    </div>
    <div class="selected-filters">
        <ng-container *ngFor="let filter of filterList; let i = index;">
            <div class="filter-item" *ngIf="filter.checked">
                <div class="filter-label">
                    <button class="filter-close" (click)="filterClose(filter)"><img alt="close filter"
                            src="assets/images/filter-close.svg" /></button> {{"table-cols."+filter.field | translate}}
                </div>
                <div class="filter-value">
                    <input *ngIf="filter.type==='input'" type="{{filter.type}}" (keyup.enter)="changeFilter()"
                        [style.width.px]="filter.value.length * 13" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <input *ngIf="filter.type==='date'" type="{{filter.type}}" (change)="changeFilter()"
                        [style.width.px]="150" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <select *ngIf="filter.type==='select'" (change)="changeFilter()" name="{{filter.field}}"
                        [(ngModel)]="filter.value">
                        <option *ngFor="let option of filter.options; let i = index;" value="{{option.value}}">
                            {{"job-history."+option.label | translate}}</option>
                    </select>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="clear"></div>
<div class="table-block">
    <div class="table-outside-div">
        <p-table [columns]="cols" [value]="batchData" [reorderableColumns]="false"
            [resizableColumns]="true" [paginator]="showPaginator" (onLazyLoad)="loadDataLazy($event)" [lazy]="true"
            [loading]="loading" [rows]="maxNumberOfResults" [totalRecords]="countItems" *ngIf="tableIsVisible">

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" class="table-data-col-label {{col.field}}" pReorderableColumn
                        pResizableColumn [pSortableColumn]="(col.field !== 'pendingCount' && col.field !== 'successfulCount' && col.field !== 'failedCount')?col.field:''">
                        {{"table-cols."+col.field | translate}}
                        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" *ngIf="col.field !== 'pendingCount' && col.field !== 'successfulCount' && col.field !== 'failedCount'"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of cols" class="td-with-click-event" (click)="showDetails(rowData);">
                        <ng-container *ngIf="col.field === 'action'">
                            {{"job-history."+rowData[col.field] | translate}}
                        </ng-container>
                        <ng-container *ngIf="col.field === 'submittedAt'">
                            {{ rowData[col.field] | date : "dd.MM.YYYY HH:mm:ss" }}
                        </ng-container>
                        <ng-container *ngIf="col.field !== 'action' && col.field !== 'submittedAt'">
                            {{ rowData[col.field] }}
                        </ng-container>
                    </td>
                </tr>
            </ng-template>

        </p-table>

        <div class="item-per-page">
            <label for="item-per-page">{{"general.objects-per-site"|translate}}</label>
            <select id="item-per-page" name="objectsNum" [(ngModel)]="maxNumberOfResults"
                #maxNumberOfResultsRef="ngModel" (change)="changeNumberOfRecords();">
                <option *ngFor="let item of ['10','20','50','100','200','500','1000']" value="{{item}}">{{item}}
                </option>
            </select>
        </div>
    </div>
</div>

<!-- Details Modal -->
<div class="block-modal-details">
    <input type="checkbox" class="modal-chkbox" id="modal-details" value="{{isDetailsModalChecked}}"
        [(ngModel)]="isDetailsModalChecked">
    <label for="modal-details" class="disable-modal-label"></label>
    <label for="modal-details" class="modal-background"></label>

    <div class="modal">
        <label for="modal-details" class="close-modal"><img src="assets/images/close.svg" alt="close-icon" /></label>

        <h3 class="modal-title">{{"job-history.action-details" | translate}}</h3>

        <div class="item">
            <span class="label">{{"table-cols.action" | translate}}</span>
            <div class="value">{{"job-history."+batchDetails.action | translate}}</div>
        </div>
        <div class="item">
            <span class="label">{{"table-cols.submittedAt" | translate}}</span>
            <div class="value">{{ batchDetails.submittedAt | date : "dd.MM.YYYY HH:mm:ss" }}</div>
        </div>
        <div class="item">
            <span class="label">{{"table-cols.state" | translate}}</span>
            <div class="value">{{ "job-history."+batchDetails.state | translate }}</div>
        </div>
        <div class="item">
            <span class="label">{{"table-cols.pendingCount" | translate}}</span>
            <div class="value">{{ batchDetails.pendingCount }}</div>
        </div>
        <div class="item">
            <span class="label">{{"table-cols.successfulCount" | translate}}</span>
            <div class="value">{{ batchDetails.successfulCount }}</div>
        </div>
        <div class="item">
            <span class="label">{{"table-cols.failedCount" | translate}}</span>
            <div class="value">{{ batchDetails.failedCount }}</div>
        </div>

        <h3 class="modal-title">{{"job-history.simcards" | translate}}</h3>

        <div class="simcard-list-in-batch" *ngIf="batchDetails.targets && batchDetails.targets.length > 0">
            <table>
                <tr>
                    <th>{{"job-history.simcard" | translate}}</th>
                    <th>{{"table-cols.status" | translate}}</th>
                    <th>{{"table-cols.fkp-code" | translate}}</th>
                    <th>{{"table-cols.fkp-message" | translate}}</th>
                </tr>
                <tr *ngFor="let target of batchDetails.targets">
                    <td>
                        {{ target.targetInfo ? (target.targetInfo.msisdn ? target.targetInfo.msisdn : target.targetInfo.iccid) : '' }}
                    </td>
                    <td>
                        {{ "job-history."+target.state | translate }}
                    </td>
                    <td>
                        {{ target.fkpErrorCode }}
                    </td>
                    <td>
                        {{ target.fkpErrorMessage }}
                    </td>
                </tr>
            </table>
        </div>

    </div>
</div>
<!-- End Details Modal -->
