import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { SimcardUpdateRequest } from 'src/app/interfaces/simcard-update-request';

import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CardService } from 'src/app/services/card.service';

import { SimcardData } from 'src/app/interfaces/simcard-data';

import { Papa } from 'ngx-papaparse';

interface SimCardsUpdateError {
  simcard: SimcardData;
  error: string;
}

@Component({
  selector: 'app-multi-sim-card-csvimport',
  templateUrl: './multi-sim-card-csvimport.component.html',
  styleUrls: ['./multi-sim-card-csvimport.component.css']
})
export class MultiSimCardCSVImportComponent {
  public isModalStatusChecked = false;
  public simCardsUpdateInProcess = false;
  public simCardsProcessedInPrecent = 0;
  public simCardsUpdateErrors: SimCardsUpdateError[] = [];

  public importedSimCards: SimcardData[] = [];
  public simCardsExecuted = 0;

  public selectedCSVFile!: File;

  private languageSubscription: Subscription;

  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService, private cardService: CardService,
    private papaCSVParser: Papa) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }
  }

  uploadSelectedFile(event: any) {
    const csvFileForImport = event.target.files[0];
    const fileExtension = csvFileForImport.name.replace(/^.*\./, '');

    const accept = ["csv"];

    if (accept.indexOf(fileExtension.toLowerCase()) != 0 || (accept.indexOf(fileExtension.toLowerCase()) == 0 && csvFileForImport.size == 0)) {
      alert('The file is incorrect format or empty file');
      return;
    }

    this.selectedCSVFile = event.target.files[0];
    //console.log(this.selectedCSVFile);
  }

  executeUpdateAction() {
    const fileReader = new FileReader();
    const me = this;
    fileReader.onload = function () {
      me.papaCSVParser.parse(fileReader!.result!.toString(), {
        complete: (result) => {
          const parsedData: string[][] = result.data;
          //console.log('Parsed: ', parsedData);

          type SimCardCSVObject = { [key: string]: string }

          if ((parsedData.length - 1) > 1) {

            me.isModalStatusChecked = true;
            me.simCardsUpdateInProcess = true;
            me.simCardsUpdateErrors = [];

            const header: string[] = parsedData[0];
            for (let i = 1; i < parsedData.length - 1; i++) {
              const parsedRow: string[] = parsedData[i];
              const simObject: SimCardCSVObject = {};
              for (const jIndex in parsedRow) {
                simObject[header[jIndex]] = parsedRow[jIndex];
              }

              me.importedSimCards.push(simObject);
            }
          } else {
            alert('No enought data for import.');
            return;
          }

          if (me.importedSimCards.length > 0) {
            //console.log(me.importedSimCards);

            me.executeUpdateCurrentSimcardsLogic();
          }
        }
      });
    };
    if (this.selectedCSVFile) {
      // This does not return the text. It just starts reading.
      // The onload handler is triggered when it is done and the result is available.
      fileReader.readAsText(this.selectedCSVFile);
    }
  }

  executeUpdateCurrentSimcardsLogic() {
    setTimeout(() => {
      const simCard = this.importedSimCards[this.simCardsExecuted];

      const updateObj = this.generateSimCardUpdateRequestObject(simCard);

      this.cardService.updateExistingSimCard(this.sessionService.getJWTToken(), simCard?.['@id'], updateObj).subscribe({
        next: (response: SimcardData) => {
          console.log(response);
          this.executionPercentCurrentSimcardsCalculation();
          this.testExecuteMoreCurrentSimcards();
        },
        error: (error: any) => {
          //console.log(error);
          this.simCardsUpdateErrors.push({ simcard: this.importedSimCards[this.simCardsExecuted], error: error.error["hydra:description"] });
          this.executionPercentCurrentSimcardsCalculation();
          this.testExecuteMoreCurrentSimcards();
        }
      });
    }, 50);
  }

  executionPercentCurrentSimcardsCalculation() {
    this.simCardsExecuted++;
    this.simCardsProcessedInPrecent = Math.round((100 / this.importedSimCards.length) * this.simCardsExecuted);
  }

  testExecuteMoreCurrentSimcards() {
    if (this.simCardsExecuted < this.importedSimCards.length) {
      this.executeUpdateCurrentSimcardsLogic();
    }
  }

  generateSimCardUpdateRequestObject(csvObject: any): SimcardUpdateRequest {
    const updateObj: SimcardUpdateRequest = {};

    if ('name' in csvObject) {
      updateObj.name = ('name' in csvObject && csvObject.name !== '') ? csvObject.name : null;
    }

    if ('note1' in csvObject) {
      updateObj.note1 = ('note1' in csvObject && csvObject.note1 !== '') ? csvObject.note1 : null;
    }

    if ('note2' in csvObject) {
      updateObj.note2 = ('note2' in csvObject && csvObject.note2 !== '') ? csvObject.note2 : null;
    }

    return updateObj;
  }
}
