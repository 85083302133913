<form name="reportForm" ngForm #reportForm="ngForm">
    <div class="report-container">
        <div class="row">
            <div class="col-md-7">
                <div class="modal-alternativ report" *ngIf="reportRun" >       
                    <h3 class="modal-title">{{'report.report' | translate}}</h3>

                    <div class="text-center">
                        <p>{{"report.reportRun" | translate}}</p>

                        <div class="button-center">
                            <a class="button save" routerLink="/report/list">{{"report.report-list" | translate}}</a>
                            <button class="button" type="button" (click)="reportRun = false">{{"general.back"|translate}}</button>
                        </div>  
                    </div>
                </div>
                <div *ngIf="!reportRun">
                    <div class="modal-alternativ report" >       
                        <h3 class="modal-title">{{'report.report' | translate}}</h3>
                        
                        <div class="form-item">
                            <label for="report">{{"report.report"|translate}}</label>
                            <select id="report" class="type" name="report" required [(ngModel)]="report" #reportRef="ngModel">
                                <option value="">{{"report.choose"|translate}}</option>
                                <option value="sessions">{{"report.sessions" | translate}}</option>
                            </select>
                        </div>
                    </div>

                    <div *ngIf="report !== ''">
                        <div class="modal-alternativ report" > 
                            <div class="form-item">
                                <label for="dateRangeType">{{"report.dateRangeType" | translate}}</label>
                                <select id="dateRangeType" class="dateRangeType" name="dateRangeType" required [(ngModel)]="dateRangeType" #dateRangeTypeRef="ngModel">
                                    <option value="">{{"report.choose"|translate}}</option>
                                    <option value="today">{{"report.today" | translate}}</option>
                                    <option value="yesterday">{{"report.yesterday" | translate}}</option>
                                    <option value="this_week">{{"report.this_week" | translate}}</option>
                                    <option value="last_week">{{"report.last_week" | translate}}</option>
                                    <option value="this_month">{{"report.this_month" | translate}}</option>
                                    <option value="last_month">{{"report.last_month" | translate}}</option>
                                    <option value="last_30_days">{{"report.last_30_days" | translate}}</option>
                                    <option value="custom">{{"report.custom" | translate}}</option>
                                </select>
                            </div>
                        
                            <div class="form-item" *ngIf="dateRangeType === 'custom'">
                                <label for="dateFrom">{{"report.dateFrom" | translate}}</label>
                                <input name="dateFrom" class="dateFrom" id="dateFrom" [(ngModel)]="dateFrom" required type="date" #dateFromRef="ngModel">
                            </div>
                
                            <div class="form-item" *ngIf="dateRangeType === 'custom'">
                                <label for="dateTo">{{"report.dateTo" | translate}}</label>
                                <input name="dateTo" id="dateTo" class="dateTo" [(ngModel)]="dateTo" required type="date" #dateToRef="ngModel">
                            </div>
    
                            <div class="form-item">
                                <input name="byCostCenter" (change)="costCenter=''" type="checkbox" id="byCostCenter" [(ngModel)]="byCostCenter">
                                <label for="byCostCenter">{{"report.by_cost_center" | translate}}</label>
                                <p-dropdown [disabled]="!byCostCenter" name="costCenter" styleClass="costCenter" 
                                            [(ngModel)]="costCenter" inputId="costCenter" [resetFilterOnHide]="true" 
                                            [options]="costCenters" optionLabel="name" optionValue="@id" [filter]="true" filterBy="name" 
                                            placeholder="{{'report.choose'|translate}}" [required]="true" #costCenterRef="ngModel"></p-dropdown>
                            </div>
                
                            <div class="form-item">
                                <input name="bySimcardGroup" (change)="simcardGroup=''" type="checkbox" id="bySimcardGroup" [(ngModel)]="bySimcardGroup">
                                <label for="bySimcardGroup">{{"report.by_simcard_group" | translate}}</label>
                                <p-dropdown [disabled]="!bySimcardGroup" name="simcardGroup"  styleClass="simcardGroup"
                                            [(ngModel)]="simcardGroup" inputId="simcardGroup" [resetFilterOnHide]="true" 
                                            [options]="simGroups" optionLabel="name" optionValue="@id" [filter]="true" filterBy="name" 
                                            placeholder="{{'report.choose'|translate}}" [required]="true" #simcardGroupRef="ngModel"></p-dropdown>
                            </div>
                
                            <div class="form-item">
                                <input name="bySimcard" (change)="simcard={}" type="checkbox" id="bySimcard" [(ngModel)]="bySimcard">
                                <label for="bySimcard">{{"report.by_simcard" | translate}}</label>
                                <p-dropdown [disabled]="!bySimcard" name="simcard" styleClass="simcard"
                                            [(ngModel)]="simcard" #simcardRef="ngModel" inputId="simcard" (onFilter)="filterSimcards($event)" [resetFilterOnHide]="true" 
                                            [options]="simCards" optionLabel="label" [filter]="true" filterBy="label" 
                                            placeholder="{{'report.choose'|translate}}" [required]="true"></p-dropdown>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="report !== ''">
                        <div class="modal-alternativ report" >    
                            <div class="form-item padding-top-10">
                                <input name="schedule" type="checkbox" id="schedule" (change)="schedulerName=''; cronOptions=''; cronDay=''; cronWeekDay=[];" [(ngModel)]="schedule">
                                <label for="schedule">{{"general.schedule"|translate}}</label>
                            </div>
        
                            <div *ngIf="cronExpressionValid">
                                <div class="form-item" *ngIf="schedule">
                                    <select id="cronOptions" class="cronOptions" name="cronOptions" (change)="cronDay=''; cronWeekDay=[];" required [(ngModel)]="cronOptions" #cronOptionsRef="ngModel">
                                        <option value="">{{"report.choose"|translate}}</option>
                                        <option value="monthly">{{"report.monthly"|translate}}</option>
                                        <option value="weekly">{{"report.weekly"|translate}}</option>
                                        <option value="daily">{{"report.daily"|translate}}</option>
                                    </select>        
                                </div>
        
                                <div class="form-item" *ngIf="schedule && cronOptions==='monthly'">
                                    <label for="cronDay">{{"report.day"|translate}}</label>
                                    <select id="cronDay" class="cronDay" name="cronDay" required [(ngModel)]="cronDay" #cronDayRef="ngModel">
                                        <option value="">{{"report.choose"|translate}}</option>
                                        <option *ngFor="let item of ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']" value="{{item}}">
                                            {{item}}
                                        </option>
                                        <option value="L">{{"report.last-day"|translate}}</option>
                                    </select>
                                </div>
        
                                <div class="form-item" *ngIf="schedule && cronOptions==='weekly'">
                                    <label for="cronWeekDay">{{"report.day"|translate}}</label>
                                    <p-multiSelect  placeholder="{{'report.choose'|translate}}" [filter]="false" [options]="cronWeekDays" display="chip" id="cronWeekDay" class="cronWeekDay" name="cronWeekDay" [(ngModel)]="cronWeekDay">
                                        <ng-template let-value pTemplate="cronWeekDays">
                                            {{ 'general.'+value | translate }}
                                        </ng-template>
                                    </p-multiSelect>      
                                </div>
                            </div>
        
                            <div *ngIf="!cronExpressionValid">
                                {{"report.cron-error" | translate}}
                            </div>
        
                            <div class="form-item name" *ngIf="schedule">
                                <label for="schedulerName">{{"report.schedulerName" | translate}}</label>
                                <input name="schedulerName" class="schedulerName" id="schedulerName" [(ngModel)]="schedulerName" required type="text" #schedulerNameRef="ngModel">
                            </div>
                        </div>

                        <div class="modal-alternativ report" >
                            <div class="form-item padding-top-10">
                                <input name="notify" type="checkbox" id="notify" (change)="notifyByEmail=[];" [(ngModel)]="notify">
                                <label for="notify">{{"report.notify"|translate}}</label>
                            </div>
        
                            <div *ngIf="notify" class="notifyByEmail">
                                <div class="form-item">
                                    <label for="notifyByEmailField">{{"report.email"|translate}}</label>
                                    <input type="text" id="notifyByEmailField" class="input-with-button" name="notifyByEmailField" #notifyByEmailFieldRef="ngModel" [(ngModel)]="notifyByEmailField">
                                    <button (click)="addToEmailList();" class="input-icon-button">+</button>
                                </div>
                                <div class="form-item">
                                    <div class="email-line" *ngFor="let email of notifyByEmail; let i = index;">
                                        <div class="input-with-delete-button">{{email}}</div>
                                        <button (click)="removeSelectedEmail(i);" class="delete-input-icon-button"><img
                                                src="assets/images/row-delete.svg" alt="delete"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="button-center">
                        <button *ngIf="loadedScheduler.name === undefined" type="button" class="save button" type="button" (click)="generateReport()"
                        [disabled]="!reportForm.valid || (notify && notifyByEmail.length===0)">{{"report.generate"|translate}}</button>

                        <button *ngIf="loadedScheduler.name !== undefined" type="button" class="save button" type="button" (click)="updateReportScheduler()"
                        [disabled]="!reportForm.valid || (notify && notifyByEmail.length===0)">{{"report.update-report"|translate}}</button>
                        <button *ngIf="loadedScheduler.name !== undefined" class="button" type="button" (click)="prepareDeleteScheduler()">{{"report.delete-report"|translate}}</button>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="modal-alternativ report scheduler-templates">
                    <h3 class="modal-title">{{'report.saved-report-scheduler' | translate}}</h3>

                    <div class="form-item">
                        <label for="selectedScheduler">{{"report.report" | translate}}</label>
                        <p-dropdown *ngIf="showSchedulerList" name="selectedScheduler"
                            [(ngModel)]="selectedScheduler" inputId="selectedScheduler" [resetFilterOnHide]="true" 
                            [options]="SchedulerList" optionLabel="name" [filter]="true" filterBy="name" 
                            placeholder="{{'report.choose'|translate}}"></p-dropdown>
                    </div>

                    <div class="button-center">
                        <button type="button" [disabled]="!selectedScheduler || selectedScheduler.name === undefined" class="save button" (click)="loadThisScheduler()">{{'report.load-report'|translate}}</button>
                        <button type="button" *ngIf="loadedScheduler.name !== undefined" class="button" (click)="cancelSchaduler()">{{'report.cancel-report'|translate}}</button>
                    </div>
                </div>

                <div class="modal-alternativ report">
                    <h3 class="modal-title">{{'report.saved-report-templates' | translate}}</h3>
                
                    <div class="form-item">
                        <label for="selectedTemplate">{{"report.report-template" | translate}}</label>
                        <p-dropdown *ngIf="showTemplateList" name="selectedTemplate"
                            [(ngModel)]="selectedTemplate" inputId="selectedTemplate" [resetFilterOnHide]="true" 
                            [options]="templateList" optionLabel="name" [filter]="true" filterBy="name" 
                            placeholder="{{'report.choose'|translate}}"></p-dropdown>
                    </div>
        
                    <div class="button-center">
                        <button type="button" [disabled]="!selectedTemplate || selectedTemplate.name === undefined" class="save button" (click)="loadThisTemplate()">{{'report.load-template'|translate}}</button>
                        <button type="button" *ngIf="loadedTemplate.name !== undefined" class="button" (click)="cancelTemplate()">{{'report.cancelTemplate'|translate}}</button>
                    </div>
                
                    <div *ngIf="loadedTemplate.name === undefined">
                        <h3 class="modal-title">{{'report.isTemplate'|translate}}</h3>
            
                        <div class="form-item">
                            <label for="name">{{'report.name'|translate}}</label>
                            <input name="name" id="name" [disabled]="schedule" [(ngModel)]="name" type="text" #nameRef="ngModel">
                        </div>
                
                        <div class="button-center">
                            <button class="save button" type="button" (click)="saveReportTemplate()"
                            [disabled]="!reportForm.valid || schedule || (notify && notifyByEmail.length===0)">{{'report.saveTemplate'|translate}}</button>
                        </div>
                    </div>

                    <div *ngIf="loadedTemplate.name !== undefined">
                        <h3 class="modal-title">{{'report.update-template'|translate}}</h3>
            
                        <div class="form-item">
                            <label for="name">{{'report.name'|translate}}</label>
                            <input name="name" id="name" [(ngModel)]="name" type="text" #nameRef="ngModel">
                        </div>
                
                        <div class="button-center">
                            <button class="save button" type="button" (click)="updateReportTemplate()" [disabled]="!reportForm.valid || name === '' || (notify && notifyByEmail.length===0)">{{'report.update-template'|translate}}</button>
                            <button class="button" type="button" (click)="prepareDeleteTemplate()">{{'report.delete-template'|translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- delete modal -->
<div *ngIf="showDeleteModal" class="custom-modal delete-modal">
    <div class="custom-modal-content">
      <p class="text-align-center">{{'report.deleteQuestion' | translate}}</p>
      <div class="button-center">
        <button class="gray-button button" (click)="showDeleteModal = false">{{"general.cancel"|translate}}</button>
        <button class="save button" type="button"
          (click)="deleteSelectedTemplate();">{{"general.delete"|translate}}</button>
      </div>
    </div>
</div>

<!-- delete modal Scheduler -->
<div *ngIf="showDeleteSchedulerModal" class="custom-modal delete-modal">
    <div class="custom-modal-content">
      <p class="text-align-center">{{'report.deleteQuestionScheduler' | translate}}</p>
      <div class="button-center">
        <button class="gray-button button" (click)="showDeleteSchedulerModal = false">{{"general.cancel"|translate}}</button>
        <button class="save button" type="button"
          (click)="deleteSelectedScheduler();">{{"general.delete"|translate}}</button>
      </div>
    </div>
</div>