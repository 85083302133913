<div class="table-block">
    <div class="main-table-filters {{detailsBlockVisible}}">
        <!-- refresh -->
        <button class="refresh-page" (click)="reloadPage()">{{"general.reload" | translate}}</button>

        <!-- status -->
        <label *ngIf="simCardsUpdateInProcess" for="modal-status" class="status-button">
            <img *ngIf="simCardsProcessedInPrecent !== 100" src="assets/images/load.svg" class="status-load-icon"
                alt="loading-icon" />
            <img *ngIf="simCardsProcessedInPrecent === 100 && simCardsUpdateErrors.length === 0"
                src="assets/images/tick.svg" class="status-finished-icon" alt="finished-icon" />
            <img *ngIf="simCardsProcessedInPrecent === 100 && simCardsUpdateErrors.length > 0"
                src="assets/images/warning.svg" class="status-warining-icon" alt="loading-icon" />
            {{"general.updatestatus" | translate}}
        </label>

        <!-- SELECT SEARCH FILTER -->
        <div class="main-dropdown filters">
            <span>{{"general.filter" | translate}}</span>
            <ul>
                <li>
                    <h3>{{"general.show-filter" | translate}}</h3>
                </li>
                <ng-container *ngFor="let filter of filterList; let i = index;">
                    <li *ngIf="!filter.field?.includes('customAttributes')">
                        <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                            [(ngModel)]="filter.checked" (change)="changeFilter(filter)" />
                        <label for="filter-{{i}}">
                            {{"table-cols."+filter.field | translate}}
                        </label>
                    </li>

                    <li
                        *ngIf="filter.field === 'customAttributes.customAttribute1' && customFieldsList?.customAttribute1Title">
                        <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                            [(ngModel)]="filter.checked" />
                        <label for="filter-{{i}}">
                            {{customFieldsList?.customAttribute1Title}}
                        </label>
                    </li>
                    <li
                        *ngIf="filter.field === 'customAttributes.customAttribute2' && customFieldsList?.customAttribute2Title">
                        <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                            [(ngModel)]="filter.checked" />
                        <label for="filter-{{i}}">
                            {{customFieldsList?.customAttribute2Title}}
                        </label>
                    </li>
                    <li
                        *ngIf="filter.field === 'customAttributes.customAttribute3' && customFieldsList?.customAttribute3Title">
                        <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                            [(ngModel)]="filter.checked" />
                        <label for="filter-{{i}}">
                            {{customFieldsList?.customAttribute3Title}}
                        </label>
                    </li>
                    <li
                        *ngIf="filter.field === 'customAttributes.customAttribute4' && customFieldsList?.customAttribute4Title">
                        <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                            [(ngModel)]="filter.checked" />
                        <label for="filter-{{i}}">
                            {{customFieldsList?.customAttribute4Title}}
                        </label>
                    </li>
                    <li
                        *ngIf="filter.field === 'customAttributes.customAttribute5' && customFieldsList?.customAttribute5Title">
                        <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                            [(ngModel)]="filter.checked" />
                        <label for="filter-{{i}}">
                            {{customFieldsList?.customAttribute5Title}}
                        </label>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="selected-filters">
            <ng-container *ngFor="let filter of filterList; let i = index;">
                <div class="filter-item" *ngIf="filter.checked">
                    <div class="filter-label">
                        <button class="filter-close" (click)="filterClose(filter)"><img alt="close filter"
                                src="assets/images/filter-close.svg" /></button>

                        <ng-container
                            *ngIf="!filter.field?.includes('customAttributes') && filter.field !== 'apply_filter'">
                            {{"table-cols."+filter.field | translate}}
                        </ng-container>

                        <ng-container *ngIf="filter.field === 'customAttributes.customAttribute1'">
                            {{customFieldsList?.customAttribute1Title}}
                        </ng-container>
                        <ng-container *ngIf="filter.field === 'customAttributes.customAttribute2'">
                            {{customFieldsList?.customAttribute2Title}}
                        </ng-container>
                        <ng-container *ngIf="filter.field === 'customAttributes.customAttribute3'">
                            {{customFieldsList?.customAttribute3Title}}
                        </ng-container>
                        <ng-container *ngIf="filter.field === 'customAttributes.customAttribute4'">
                            {{customFieldsList?.customAttribute4Title}}
                        </ng-container>
                        <ng-container *ngIf="filter.field === 'customAttributes.customAttribute5'">
                            {{customFieldsList?.customAttribute5Title}}
                        </ng-container>

                        <ng-container *ngIf="filter.field === 'apply_filter'">
                            {{"sim-cards.apply-filter" | translate}}
                        </ng-container>
                    </div>
                    <div class="filter-value" *ngIf="filter.field !== 'apply_filter'">
                        <input *ngIf="filter.type==='input'" (keyup.enter)="changeFilter(filter)"
                            [style.width.px]="filter.value.length * 13" name="{{filter.field}}" value="{{filter.value}}"
                            [(ngModel)]="filter.value">
                        <select *ngIf="filter.type==='select'" (change)="changeFilter(filter)" name="{{filter.field}}"
                            [(ngModel)]="filter.value">
                            <option *ngFor="let option of filter.options; let i = index;" value="{{option.value}}">
                                {{(filter.field === 'lifecycleState')?('general.'+option.label |
                                translate):option.label}}
                            </option>
                        </select>
                    </div>

                    <div class="filter-value csv-upload" *ngIf="filter.field === 'apply_filter'">
                        <input *ngIf="filter.type==='hidden'" type="hidden" name="{{filter.field}}"
                            value="{{filter.value}}" [(ngModel)]="filter.value">

                        <ng-container *ngIf="filter.field === 'apply_filter'">
                            <div class="table-cell">
                                <select name="apply_filter_select" [(ngModel)]="apply_filter_select" (change)="(selectedCSVFile && selectedCSVFile.name !== '')?resetCsvFile():''">
                                    <option value="">{{"general.choice" | translate}}</option>
                                    <option value="ICCID">ICCID</option>
                                    <option value="MSISDN">MSISDN</option>
                                </select>
                            </div>

                            <div class="table-cell">
                                <div class="filter-upload-field {{(!apply_filter_select)?'csv-file-upload-disabled':''}}">
                                    <span *ngIf="selectedCSVFile && selectedCSVFile.name !== ''">{{selectedCSVFile.name}}</span>
                                    <span *ngIf="!(selectedCSVFile && selectedCSVFile.name !== '')">{{"sim-cards.csv-upload-button" | translate}}</span>
                                    <input [disabled]="!apply_filter_select" type="file" name="apply_filter_file" [(ngModel)]="apply_filter_file"
                                        (change)="selectFileForDownload($event);">
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="clear"></div>

    <div class="{{detailsBlockVisible}}">
        <div class="main-table-options">
            <div class="items-number">{{countItems}} {{"sim-cards.total-objects"|translate}}</div>

            <div class="download-and-col-filter">
                <!-- SELECT COL FILTER -->
                <div class="main-dropdown table-cols">
                    <span>{{"sim-cards.show-columns"|translate}}</span>
                    <ul>
                        <ng-container
                            *ngIf="userSettings && userSettings.simcardViews && userSettings.simcardViews.length > 0">
                            <li>
                                <h3>{{"sim-cards.saved-views"|translate}}</h3>
                            </li>
                            <li>
                                <span class="saved-views">
                                    <span class="save-view-block">
                                        <button type="button" class="saved-view {{(currentViewDefaultChecked)?'set-true':'set-false'}}" 
                                            (click)="showView(enableColsDefault); setCurrentView(null);">{{'sim-cards.default-layout'|translate}}</button>
                                        <button class="default-view {{(enableColsDefaultChecked)?'set-true':'set-false'}}" (click)="(enableColsDefaultChecked)?'':setDefaultView(-1)"
                                            title="{{(enableColsDefaultChecked)?'':('sim-cards.view-default'|translate)}}">Default</button>
                                    </span>
                                    <span *ngFor="let view of userSettings.simcardViews; let i = index;"
                                        class="save-view-block">
                                        <button type="button" class="saved-view {{(view.currentView)?'set-true':'set-false'}}" 
                                            (click)="showView(view.enableCols); setCurrentView(view);">{{view.name}}</button>
                                        <button class="default-view {{(view.default)?'set-true':'set-false'}}" (click)="(view.default)?'':setDefaultView(i)"
                                            title="{{(view.default)?'':('sim-cards.view-default'|translate)}}">Default</button>
                                        <button type="button" class="delete-view"
                                            title="{{'sim-cards.view-delete'|translate}}" (click)="deleteView(i)"><img
                                                src="assets/images/row-delete.svg" alt="delete"></button>
                                    </span>
                                </span>
                            </li>
                        </ng-container>
                        <li>
                            <h3>{{"sim-cards.h3-show-columns"|translate}}</h3>
                        </li>
                        <li *ngFor="let col of cols; let i = index;">
                            <input type="checkbox" value='{{"table-cols."+col.field | translate}}' data-id="{{i}}"
                                [(ngModel)]="col.checked" (change)='handleCheck(true);' />

                            <label for="{{i}}"
                                *ngIf="!col.field?.includes('customAttributes')">{{"table-cols."+col.field |
                                translate}}</label>

                            <label for="{{i}}"
                                *ngIf="col.field === 'customAttributes.customAttribute1' && customFieldsList?.customAttribute1Title">
                                {{customFieldsList?.customAttribute1Title}}
                            </label>
                            <label for="{{i}}"
                                *ngIf="col.field === 'customAttributes.customAttribute2' && customFieldsList?.customAttribute2Title">
                                {{customFieldsList?.customAttribute2Title}}
                            </label>
                            <label for="{{i}}"
                                *ngIf="col.field === 'customAttributes.customAttribute3' && customFieldsList?.customAttribute3Title">
                                {{customFieldsList?.customAttribute3Title}}
                            </label>
                            <label for="{{i}}"
                                *ngIf="col.field === 'customAttributes.customAttribute4' && customFieldsList?.customAttribute4Title">
                                {{customFieldsList?.customAttribute4Title}}
                            </label>
                            <label for="{{i}}"
                                *ngIf="col.field === 'customAttributes.customAttribute5' && customFieldsList?.customAttribute5Title">
                                {{customFieldsList?.customAttribute5Title}}
                            </label>
                        </li>
                        <li>
                            <h3>{{"sim-cards.save-view"|translate}}</h3>
                            <form name="saveViewForm" ngForm #saveViewForm="ngForm">
                                <input class="col-view-input" maxlength="25" type="text"
                                    placeholder="{{'sim-cards.view-name'|translate}}" name="viewName"
                                    [(ngModel)]="viewName" #viewNameRef="ngModel" required><br>
                                <button (click)="saveView();" [disabled]="!saveViewForm.valid"
                                    class="button save col-view-save" type="submit">{{"sim-cards.save" |
                                    translate}}</button>
                            </form>
                        </li>
                    </ul>
                </div>

                <!-- DOWNLOAD -->
                <button class="csv-download" (click)="exportAllSimCardsAction();">CSV</button>
            </div>
        </div>
    </div>

    <div class="clear"></div>

    <div class="{{detailsBlockVisible}}">
        <div class="table-outside-div">
            <p-table [columns]="selectedColumns" [value]="simCards" [reorderableColumns]="false"
                [resizableColumns]="true" [paginator]="showPaginator" (onLazyLoad)="loadDataLazy($event)" [lazy]="true"
                [loading]="loading" [rows]="maxNumberOfResults" [totalRecords]="countItems" *ngIf="tableIsVisible" [(first)]="first">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th class="th-check" *ngIf="!simcardSelected">
                            <input type="checkbox" id="allSelect" [checked]="mainCheckBoxState"
                                [(ngModel)]="mainCheckBoxState" (click)="executeMainCheckBoxEvent($event);"
                                [disabled]="simCardsUpdateInProcess && simCardsProcessedInPrecent !== 100">
                            <label for="allSelect"></label>
                        </th>
                        <th *ngFor="let col of columns" class="table-data-col-label {{col.field}}" pReorderableColumn
                            pResizableColumn
                            [pSortableColumn]="(col.field !== 'status' && col.field !== 'mcc' && col.field !== 'mnc' && col.field !== 'groups')?col.field:''">

                            <ng-container *ngIf="!col.field.includes('customAttributes')">{{"table-cols."+col.field |
                                translate}}</ng-container>

                            <ng-container
                                *ngIf="col.field === 'customAttributes.customAttribute1' && customFieldsList?.customAttribute1Title">
                                {{customFieldsList?.customAttribute1Title}}
                            </ng-container>
                            <ng-container
                                *ngIf="col.field === 'customAttributes.customAttribute2' && customFieldsList?.customAttribute2Title">
                                {{customFieldsList?.customAttribute2Title}}
                            </ng-container>
                            <ng-container
                                *ngIf="col.field === 'customAttributes.customAttribute3' && customFieldsList?.customAttribute3Title">
                                {{customFieldsList?.customAttribute3Title}}
                            </ng-container>
                            <ng-container
                                *ngIf="col.field === 'customAttributes.customAttribute4' && customFieldsList?.customAttribute4Title">
                                {{customFieldsList?.customAttribute4Title}}
                            </ng-container>
                            <ng-container
                                *ngIf="col.field === 'customAttributes.customAttribute5' && customFieldsList?.customAttribute5Title">
                                {{customFieldsList?.customAttribute5Title}}
                            </ng-container>

                            <p-sortIcon *ngIf="col.field !== 'status' && col.field !== 'mcc' && col.field !== 'mnc' && col.field !== 'groups'"
                                [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                        </th>
                        <th class="buttons" *ngIf="!simcardSelected"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                    <tr [pSelectableRow]="rowData">
                        <td class="td-check" *ngIf="!simcardSelected">
                            <!--
                            <img class="working" title="Aktuell wird ein Update verarbeitet" alt="Aktuell wird ein Update verarbeitet" src="assets/images/working.svg">
                            -->
                            <input type="checkbox" id="simcard-{{rowData['@id']}}"
                                [checked]="rowData['selected']===true" (click)="checkBoxEventOnRow($event, rowData);"
                                [disabled]="simCardsUpdateInProcess && simCardsProcessedInPrecent !== 100">
                            <label for="simcard-{{rowData['@id']}}"></label>
                        </td>
                        <td class="row-link" (click)="selectSimcardDataRow(rowData);"
                            *ngFor="let col of selectedColumns">

                            <ng-container *ngIf="col.field==='status'">
                                <div class="status-col">

                                    <span class="online"
                                        *ngIf="rowData['online'] && rowData['lifecycleState'] === 'active'">online</span>
                                    <span class="offline"
                                        *ngIf="!rowData['online'] && rowData['lifecycleState'] === 'active'">offline</span>
                                    <span class="disabled"
                                        *ngIf="rowData['lifecycleState'] === 'disabled'">{{"general.disabled" |
                                        translate}}</span>
                                    <span class="terminate"
                                        *ngIf="rowData['lifecycleState'] === 'canceled'">{{"general.terminate" |
                                        translate}}</span>

                                    <!--
                                    <span class="error">{{"general.error" | translate}}</span>
                                    -->
                                </div>
                            </ng-container>

                            <ng-container *ngIf="col.field==='lifecycleState'">
                                {{"general."+rowData['lifecycleState'] | translate}}
                            </ng-container>

                            <ng-container *ngIf="col.field==='groups'">
                                <ng-container *ngIf="rowData[col.field]">
                                    <span class="sim-group"
                                        *ngFor="let group of rowData[col.field]">{{group.name}}</span>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="col.field === 'mcc' || col.field === 'mnc'">
                                <ng-container *ngIf="rowData['activeSession'] !== undefined">
                                    <span
                                        title="{{(col.field === 'mcc')?rowData['activeSession']['countryName']:rowData['activeSession']['providerName']}}">{{rowData['activeSession'][col.field]
                                        }}</span>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="col.field === 'costCenter'">
                                {{ (rowData.costCenter)?rowData!.costCenter.name:'' }}
                            </ng-container>

                            <ng-container *ngIf="col.field === 'traffic_profile'">
                                {{ (rowData.trafficProfile)?rowData!.trafficProfile.name:'' }}
                            </ng-container>

                            <ng-container *ngIf="col.field === 'customAttributes.customAttribute1'">
                                {{ rowData.customAttributes?.customAttribute1 }}
                            </ng-container>
                            <ng-container *ngIf="col.field === 'customAttributes.customAttribute2'">
                                {{ rowData.customAttributes?.customAttribute2 }}
                            </ng-container>
                            <ng-container *ngIf="col.field === 'customAttributes.customAttribute3'">
                                {{ rowData.customAttributes?.customAttribute3 }}
                            </ng-container>
                            <ng-container *ngIf="col.field === 'customAttributes.customAttribute4'">
                                {{ rowData.customAttributes?.customAttribute4 }}
                            </ng-container>
                            <ng-container *ngIf="col.field === 'customAttributes.customAttribute5'">
                                {{ rowData.customAttributes?.customAttribute5 }}
                            </ng-container>

                            <ng-container
                                *ngIf="!col.field.includes('customAttributes') && col.field !== 'groups' && col.field !== 'status' && col.field !== 'mcc' && col.field !== 'mnc' && col.field !== 'costCenter' && col.field !== 'traffic_profile' && col.field !== 'lifecycleState'">
                                {{ rowData[col.field] }}
                            </ng-container>

                        </td>
                        <td class="buttons" *ngIf="!simcardSelected">
                            <button class="table-row-button" (click)="editSimcardDataRow(rowData);"><img
                                    src="assets/images/row-edit.svg" alt="edit" /></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="item-per-page">
            <label for="item-per-page">{{"general.objects-per-site"|translate}}</label>
            <select id="item-per-page" name="objectsNum" [(ngModel)]="maxNumberOfResults"
                #maxNumberOfResultsRef="ngModel" (change)="changeNumberOfRecords();">
                <option *ngFor="let item of ['10','20','50','100','200','500','1000']" value="{{item}}">{{item}}
                </option>
            </select>
        </div>
        <div class="mass-update" *ngIf="!simcardSelected">
            <select id="item-per-page" name="massupdate" [(ngModel)]="multiSimAction"
                [disabled]="simCardsUpdateInProcess && simCardsProcessedInPrecent !== 100">
                <option value="">{{"sim-cards.select-option"|translate}}</option>
                <option value="enable_simcards">{{"sim-cards.enable_simcards"|translate}}</option>
                <option value="disable_simcards">{{"sim-cards.disable_simcards"|translate}}</option>
                <option value="decommission_simcards">{{"sim-cards.decommission_simcards"|translate}}</option>
                <option value="set_roaming_policy">{{"sim-cards.set_roaming_policy"|translate}}</option>
                <!--
                <option value="delete">{{"sim-cards.delete"|translate}}</option>
                -->
                <option value="edit">{{"sim-cards.edit"|translate}}</option>
            </select>
            <div class="mass-update-counter">{{(selectAllCheckBoxState)?countItems:selectedSimCards}}
                {{"sim-cards.sim-cards-selected"|translate}}<br>
                {{"sim-cards.are-selected"|translate}}
            </div>

            <button *ngIf="multiSimAction !== 'edit'"
                [disabled]="multiSimAction === '' || selectedSimCards === 0 || (simCardsUpdateInProcess && simCardsProcessedInPrecent !== 100)"
                type="button" class="button"
                (click)="executeMultiSimAction(); resetMassUpdateForm();">{{"sim-cards.button-execute"|translate}}</button>

            <button *ngIf="multiSimAction === 'edit'"
                [disabled]="selectedSimCards === 0 || (simCardsUpdateInProcess && simCardsProcessedInPrecent !== 100)"
                type="button" class="button"
                (click)="executeEditMultiSimAction(); resetMassUpdateForm();">{{"sim-cards.edit"|translate}}</button>

            <a class="button csv-update" routerLink="/simcards/simcards-csv-update">{{'header.simcards-csv-update' | translate}}</a>

            <div class="selectAllCheckbox">
                <div *ngIf="mainCheckBoxState && selectedSimCards < countItems">
                    <input type="checkbox" id="selectAll" [checked]="selectAllCheckBoxState"
                        [(ngModel)]="selectAllCheckBoxState">
                    <label for="selectAll">{{"general.all" | translate}} {{countItems}} {{"general.select-cards" |
                        translate}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="details-block-right" *ngIf="simcardSelected">
        <div class="details-block">
            <h3 class="details-title"><img alt="sim-icon" src="assets/images/sim.svg" /> Details zu
                {{selectedSimcardData!.msisdn}}</h3>
            <button class="details-close" name="back-link" (click)="hideDetailsBlock();"><img alt="close-icon"
                    src="assets/images/close.svg" /></button>

            <div class="tabbed">
                <input checked="checked" id="tab1" type="radio" name="tabs" />
                <input id="tab2" type="radio" name="tabs" />
                <input id="tab3" type="radio" name="tabs" />
                <input id="tab4" type="radio" name="tabs" />
                <input id="tab5" type="radio" name="tabs" />
                <input id="tab6" type="radio" name="tabs" />

                <nav>
                    <label for="tab1">{{"sim-cards.general"|translate}}</label>
                    <label for="tab2">{{"sim-cards.contract-data"|translate}}</label>
                    <label for="tab3">{{"sim-cards.notes"|translate}}</label>
                    <label for="tab4">{{"sim-cards.traffic"|translate}}</label>
                    <label for="tab5">{{"sim-cards.data-connections"|translate}}</label>
                    <label for="tab6">{{"sim-cards.audit-log"|translate}}</label>
                </nav>

                <figure>
                    <div class="tab1">
                        <div class="item">
                            <span class="label">{{"sim-cards.msisdn"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.msisdn}}</div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.iccid"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.iccid}}</div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.designation"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.name}}</div>
                        </div>

                        <div class="item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute1Title !== '' &&
                            selectedSimcardData!.customAttributes?.customAttribute1 !== '' && selectedSimcardData!.customAttributes?.customAttribute1 !== null">
                            <span class="label">{{customFieldsList.customAttribute1Title}}</span>
                            <div class="value">{{selectedSimcardData!.customAttributes?.customAttribute1}}</div>
                        </div>

                        <div class="item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute2Title !== '' &&
                            selectedSimcardData!.customAttributes?.customAttribute2 !== '' && selectedSimcardData!.customAttributes?.customAttribute2 !== null">
                            <span class="label">{{customFieldsList.customAttribute2Title}}</span>
                            <div class="value">{{selectedSimcardData!.customAttributes?.customAttribute2}}</div>
                        </div>

                        <div class="item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute3Title !== '' &&
                            selectedSimcardData!.customAttributes?.customAttribute3 !== '' && selectedSimcardData!.customAttributes?.customAttribute3 !== null">
                            <span class="label">{{customFieldsList.customAttribute3Title}}</span>
                            <div class="value">{{selectedSimcardData!.customAttributes?.customAttribute3}}</div>
                        </div>

                        <div class="item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute4Title !== '' &&
                            selectedSimcardData!.customAttributes?.customAttribute4 !== '' && selectedSimcardData!.customAttributes?.customAttribute4 !== null">
                            <span class="label">{{customFieldsList.customAttribute4Title}}</span>
                            <div class="value">{{selectedSimcardData!.customAttributes?.customAttribute4}}</div>
                        </div>

                        <div class="item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute5Title !== '' &&
                            selectedSimcardData!.customAttributes?.customAttribute5 !== '' && selectedSimcardData!.customAttributes?.customAttribute5 !== null">
                            <span class="label">{{customFieldsList.customAttribute5Title}}</span>
                            <div class="value">{{selectedSimcardData!.customAttributes?.customAttribute5}}</div>
                        </div>

                        <div class="item">
                            <span class="label">{{"sim-cards.aktivated"|translate}}</span>
                            <div class="value">{{(selectedSimcardData!.lifecycleState === 'active')?'Ja':'Nein'}}</div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.cost-center"|translate}}</span>
                            <div class="value">
                                {{(selectedSimcardData!.costCenter!)?selectedSimcardData!.costCenter!.name!:''}}
                            </div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.sim-groups"|translate}}</span>
                            <div class="value">
                                <ng-container class="sim-group"
                                    *ngFor="let group of selectedSimcardData!.groups">{{group.name}}<br></ng-container>
                            </div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.traffic-profile"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.trafficProfile!.name}}</div>
                        </div>
                        <div class="item">
                            <span class="label label-padding-left">{{"sim-cards.limit-national"|translate}}</span>
                            <div class="value">
                                {{(selectedSimcardData!.trafficProfile!.limitNational)?humanFileSize(+(selectedSimcardData!.trafficProfile!.limitNational)):("sim-cards.unlimited"|translate)}}
                            </div>
                        </div>
                        <div class="item">
                            <span class="label label-padding-left">{{"sim-cards.limit-international"|translate}}</span>
                            <div class="value">
                                {{(selectedSimcardData!.trafficProfile!.limitInternational)?humanFileSize(+(selectedSimcardData!.trafficProfile!.limitInternational)):("sim-cards.unlimited"|translate)}}
                            </div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.data-connection"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.online?'online':'offline'}}</div>
                        </div>
                        <div class="item" *ngIf="selectedSimcardData!.activeSession">
                            <span class="label">{{ "sim-cards.provider-mcc-mnc"|translate }}</span>
                            <div class="value">{{selectedSimcardData!.activeSession!.mcc}}
                                {{selectedSimcardData!.activeSession!.mnc}}</div>
                        </div>
                        <div class="item" *ngIf="selectedSimcardData!.activeSession">
                            <span class="label">{{"table-cols.ipv4Address" | translate}}</span>
                            <div class="value">{{selectedSimcardData!.activeSession!.ipv4Address}}</div>
                        </div>
                        <div class="item" *ngIf="selectedSimcardData!.activeSession">
                            <span class="label">{{"table-cols.providerName" | translate}}</span>
                            <div class="value">{{selectedSimcardData!.activeSession!.providerName}}</div>
                        </div>
                        <div class="item" *ngIf="selectedSimcardData!.activeSession">
                            <span class="label">{{"sim-cards.apn"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.activeSession!.apn}}</div>
                        </div>
                        <div class="item" *ngIf="selectedSimcardData!.activeSession">
                            <span class="label">{{"table-cols.imei"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.activeSession!.imei}}</div>
                        </div>
                        <div class="item" *ngIf="selectedSimcardData!.activeSession">
                            <span class="label">{{"sim-cards.modem-config"|translate}}</span>
                            <div class="value"></div>
                        </div>
                        <div class="item" *ngIf="selectedSimcardData!.activeSession">
                            <span class="label label-padding-left">{{"sim-cards.apn"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.activeSession!.apn}}</div>
                        </div>
                        <div class="item" *ngIf="selectedSimcardData!.roamingPolicy">
                            <span class="label">{{"table-cols.roamingPolicy"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.roamingPolicy}}</div>
                        </div>
                    </div>
                    <div class="tab2">
                        <div class="item">
                            <span class="label">{{"sim-cards.msisdn-1"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.msisdn}}</div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.iccid-1"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.iccid}}</div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.designation-1"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.name}}</div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.ban"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.ban}}</div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.vodafone-tariff"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.tariffname}}</div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.contract-status"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.lifecycleState}}</div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.contract-start"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.contractStartDate}}</div>
                        </div>
                        <div class="item" *ngIf="selectedSimcardData!.contractEndDate">
                            <span class="label">{{"sim-cards.contract-end"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.contractEndDate}}</div>
                        </div>
                    </div>
                    <div class="tab3">
                        <div class="item">
                            <span class="label">{{"sim-cards.note-1"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.note1}}</div>
                        </div>
                        <div class="item">
                            <span class="label">{{"sim-cards.note-2"|translate}}</span>
                            <div class="value">{{selectedSimcardData!.note2}}</div>
                        </div>
                    </div>
                    <div class="tab4">
                        <div class="chart-block">
                            <!-- traffic grapph national -->
                            <div class="chart-item">
                                <div class="chart-item-icon National"></div>
                                <div class="chart-item-labels-graph">
                                    <div class="chart-item-labels">
                                        <div class="chart-item-label">{{"general.national" | translate}}</div>
                                        <div class="chart-item-usage-label"
                                            *ngIf="selectedSimcardData!.trafficProfile!.limitNational">
                                            {{humanFileSize(+(selectedSimcardData!.currentDataTraffic!.bytesNational!))}}
                                            {{"general.of" | translate}}
                                            {{humanFileSize(+(selectedSimcardData!.trafficProfile!.limitNational!))}}
                                            {{"general.used" | translate}}</div>
                                        <div class="chart-item-usage-label"
                                            *ngIf="!selectedSimcardData!.trafficProfile!.limitNational">
                                            {{humanFileSize(+(selectedSimcardData!.currentDataTraffic!.bytesNational!))}}
                                            {{"general.used" | translate}}</div>
                                    </div>
                                    <div class="chart-item-graph">
                                        <div class="chart-item-usage"
                                            *ngIf="selectedSimcardData!.trafficProfile!.limitNational"
                                            style="width: {{(100 / +(selectedSimcardData!.trafficProfile!.limitNational!)) * +(selectedSimcardData!.currentDataTraffic!.bytesNational!)}}%">
                                        </div>

                                        <div class="chart-item-usage"
                                            *ngIf="!selectedSimcardData!.trafficProfile!.limitNational"
                                            style="width: {{(100 / (Math.max(+(selectedSimcardData!.currentDataTraffic!.bytesNational!), +(selectedSimcardData!.currentDataTraffic!.bytesInternational!)) * 1.25)) * +(selectedSimcardData!.currentDataTraffic!.bytesNational!)}}%">
                                        </div>
                                    </div>
                                    <div class="chart-item-legend"><b>{{"table-cols.plan" | translate}}:</b>
                                        {{selectedSimcardData!.trafficProfile!.name}}</div>
                                </div>
                            </div>

                            <!-- traffic grapph international -->
                            <div class="chart-item">
                                <div class="chart-item-icon International"></div>
                                <div class="chart-item-labels-graph">
                                    <div class="chart-item-labels">
                                        <div class="chart-item-label">{{"general.international" | translate}}</div>
                                        <div class="chart-item-usage-label"
                                            *ngIf="selectedSimcardData!.trafficProfile!.limitInternational">
                                            {{humanFileSize(+(selectedSimcardData!.currentDataTraffic!.bytesInternational!))}}
                                            {{"general.of" | translate}}
                                            {{humanFileSize(+(selectedSimcardData!.trafficProfile!.limitInternational!))}}
                                            {{"general.used" | translate}}</div>
                                        <div class="chart-item-usage-label"
                                            *ngIf="!selectedSimcardData!.trafficProfile!.limitInternational">
                                            {{humanFileSize(+(selectedSimcardData!.currentDataTraffic!.bytesInternational!))}}
                                            {{"general.used" | translate}}</div>
                                    </div>
                                    <div class="chart-item-graph">
                                        <div class="chart-item-usage"
                                            *ngIf="selectedSimcardData!.trafficProfile!.limitInternational"
                                            style="width: {{(100 / +(selectedSimcardData!.trafficProfile!.limitInternational!)) * +(selectedSimcardData!.currentDataTraffic!.bytesInternational!)}}%">
                                        </div>

                                        <div class="chart-item-usage"
                                            *ngIf="!selectedSimcardData!.trafficProfile!.limitInternational"
                                            style="width: {{(100 / (Math.max(+(selectedSimcardData!.currentDataTraffic!.bytesNational!), +(selectedSimcardData!.currentDataTraffic!.bytesInternational!)) * 1.25)) * +(selectedSimcardData!.currentDataTraffic!.bytesInternational!)}}%">
                                        </div>
                                    </div>
                                    <div class="chart-item-legend"><b>{{"table-cols.plan" | translate}}:</b>
                                        {{selectedSimcardData!.trafficProfile!.name}}</div>
                                </div>
                            </div>

                        </div>
                        <div class="traffic-table-div">
                            <p-table [value]="simCardsDataTraffic" [tableStyle]="{ 'min-width': '50rem' }">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th>{{ "table-cols.month" | translate }}</th>
                                        <th>{{ "table-cols.bytesNational" | translate }}</th>
                                        <th>{{ "table-cols.sessionsNational" | translate }}</th>
                                        <th>{{ "table-cols.bytesInternational" | translate }}</th>
                                        <th>{{ "table-cols.sessionsInternational" | translate }}</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-dataTraffic>
                                    <tr>
                                        <td>{{ dataTraffic.startTime | date : "MMMM" }}</td>
                                        <td>{{ humanFileSize(dataTraffic.bytesNational) }}</td>
                                        <td>{{ dataTraffic.sessionsNational }}</td>
                                        <td>{{ humanFileSize(dataTraffic.bytesInternational) }}</td>
                                        <td>{{ dataTraffic.sessionsInternational }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="tab5">
                        <div class="table-block">
                            <p-table [columns]="listDataSessionsCols" [value]="simCardsDataSessions"
                                [reorderableColumns]="false" [resizableColumns]="true" [paginator]="showDSPaginator"
                                (onLazyLoad)="loadDataSessionsLazy($event)" [lazy]="true" [loading]="loadingDS"
                                [rows]="maxDataSessionsPerPage" [totalRecords]="totalDataSessions"
                                *ngIf="tableDSIsVisible">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th *ngFor="let col of listDataSessionsCols"
                                            class="table-data-col-label {{col.field}}" pReorderableColumn
                                            pResizableColumn [pSortableColumn]="col.field">
                                            {{"table-cols."+col.field | translate}}
                                            <p-sortIcon *ngIf="col.field === 'startTime' || col.field === 'endTime'"
                                                [field]="col.field" ariaLabel="Activate to sort"
                                                ariaLabelDesc="Activate to sort in descending order"
                                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr [pSelectableRow]="rowData">
                                        <td *ngFor="let col of listDataSessionsCols">
                                            <ng-container *ngIf="col.field === 'startTime' || col.field === 'endTime'">
                                                {{ rowData[col.field] | date : "dd.MM.YYYY HH:mm:ss" }}
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'numDays'">
                                                {{calculateNumDaysBetween(rowData['startTime'], rowData['endTime'])}}
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'bytesIn' || col.field === 'bytesOut'">
                                                {{ humanFileSize(rowData[col.field]) }}
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'traffic'">
                                                {{ humanFileSize(rowData['bytesIn'] + rowData['bytesOut']) }}
                                            </ng-container>
                                            <ng-container *ngIf="col.field === 'mcc' || col.field === 'mnc'">
                                                <span
                                                    title="{{(col.field === 'mcc')?rowData['countryName']:rowData['providerName']}}">{{
                                                    rowData[col.field] }}</span>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="col.field !== 'mcc' && col.field !== 'mnc' && col.field !== 'startTime' && col.field !== 'endTime' && col.field !== 'bytesIn' && col.field !== 'bytesOut' && col.field !== 'traffic' && col.field !== 'numDays'">
                                                {{ rowData[col.field] }}
                                            </ng-container>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                            <div class="item-per-page">
                                <label for="item-per-page">{{"general.objects-per-site"|translate}}</label>
                                <select id="item-per-page" name="objectsNum" [(ngModel)]="maxDataSessionsPerPage"
                                    #maxDataSessionsRef="ngModel" (change)="changeNumberOfDataSessionRecords();">
                                    <option *ngFor="let item of ['10','20','50','100','200','500','1000']"
                                        value="{{item}}">
                                        {{item}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="tab6">
                        <div class="main-table-filters">
                            <!-- refresh -->
                            <!--
                            <button class="refresh-page" (click)="getHistoryList()">{{"general.reload" | translate}}</button>
                            -->

                            <!-- SELECT SEARCH FILTER -->
                            <!--
                            <div class="main-dropdown filters">
                                <span>{{"general.filter" | translate}}</span>
                                <ul>
                                    <li>
                                        <h3>{{"general.show-filter" | translate}}</h3>
                                    </li>
                                    <li *ngFor="let filter of filterHistoryList; let i = index;">
                                        <input type="checkbox" value='{{filter.checked}}' data-id="filter-history-{{i}}"
                                            [(ngModel)]="filter.checked" />
                                        <label for="filter-history-{{i}}">{{"table-cols."+filter.field | translate}}</label>
                                    </li>
                                </ul>
                            </div>
                            <div class="selected-filters">
                                <ng-container *ngFor="let filter of filterHistoryList; let i = index;">
                                    <div class="filter-item" *ngIf="filter.checked">
                                        <div class="filter-label">
                                            <button class="filter-close" (click)="filterHistoryClose(filter)"><img alt="close filter"
                                                    src="assets/images/filter-close.svg" /></button> {{"table-cols."+filter.field | translate}}
                                        </div>
                                        <div class="filter-value">
                                            <input *ngIf="filter.type==='input'" type="{{filter.type}}" (keyup.enter)="changeHistoryFilter()"
                                                [style.width.px]="filter.value.length * 13" name="{{filter.field}}" value="{{filter.value}}"
                                                [(ngModel)]="filter.value">
                                            <input *ngIf="filter.type==='date'" type="{{filter.type}}" (change)="changeHistoryFilter()"
                                                [style.width.px]="150" name="{{filter.field}}" value="{{filter.value}}"
                                                [(ngModel)]="filter.value">
                                            <select *ngIf="filter.type==='select'" (change)="changeHistoryFilter()" name="{{filter.field}}"
                                                [(ngModel)]="filter.value">
                                                <option *ngFor="let option of filter.options; let i = index;" value="{{option.value}}">
                                                    {{"job-history."+option.label | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            -->
                        </div>

                        <div class="clear"></div>
                        <div class="table-block">
                            <p-table [columns]="colsHistory" [value]="historyData" [reorderableColumns]="false"
                                [resizableColumns]="true" [paginator]="showPaginatorHistory"
                                (onLazyLoad)="loadHistoryDataLazy($event)" [lazy]="true" [loading]="loadingHistory"
                                [rows]="maxHistoryNumberOfResults" [totalRecords]="countHistoryItems"
                                *ngIf="tableHistoryIsVisible">

                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th *ngFor="let col of columns" class="table-data-col-label {{col.field}}"
                                            pReorderableColumn pResizableColumn
                                            [pSortableColumn]="(col.field !== 'pendingCount' && col.field !== 'successfulCount' && col.field !== 'failedCount')?col.field:''">
                                            {{"table-cols."+col.field | translate}}
                                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                                *ngIf="col.field !== 'pendingCount' && col.field !== 'successfulCount' && col.field !== 'failedCount'"
                                                ariaLabelDesc="Activate to sort in descending order"
                                                ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                                    <tr [pSelectableRow]="rowData">
                                        <td *ngFor="let col of colsHistory" class="td-with-click-event">
                                            {{rowData[col.field]}}
                                        </td>
                                    </tr>
                                </ng-template>

                            </p-table>

                            <div class="item-per-page">
                                <label for="item-per-page">{{"general.objects-per-site"|translate}}</label>
                                <select id="item-per-page" name="objectsHistoryNum"
                                    [(ngModel)]="maxHistoryNumberOfResults" #maxHistoryNumberOfResultsRef="ngModel"
                                    (change)="changeNumberOfHistoryRecords();">
                                    <option *ngFor="let item of ['10','20','50','100','200','500','1000']"
                                        value="{{item}}">{{item}}
                                    </option>
                                </select>
                            </div>
                        </div>

                    </div>
                </figure>
            </div>

            <label for="modal" class="button edit" (click)="editSimcardData(selectedSimcardData!)"><img
                    src="assets/images/edit.svg" alt="edit-icon" />
                {{"sim-cards.edit"|translate}}</label>
        </div>
    </div>
</div>


<!-- Edit Modal -->
<div class="block-modal" *ngIf="selectedSimcardData">
    <input type="checkbox" class="modal-chkbox" id="modal" value="{{isModalChecked}}" [(ngModel)]="isModalChecked" (change)="reloadPage()">
    <label for="modal" class="disable-modal-label"></label>
    <label for="modal" class="modal-background"></label>

    <div class="modal">
        <label for="modal" class="close-modal" (click)="reloadPage()"><img src="assets/images/close.svg"
                alt="close-icon" /></label>

        <h3 class="modal-title">{{"sim-cards.sim"|translate}} {{selectedSimcardData!.msisdn}}
            {{"sim-cards.edit-1"|translate}}</h3>

        <form name="simcardForm" ngForm #simcardForm="ngForm">
            <div class="tabbed-modal">
                <input checked="checked" id="tab11" type="radio" name="tabs-modal" />
                <input id="tab12" type="radio" name="tabs-modal" />
                <input id="tab13" type="radio" name="tabs-modal" />
                <input id="tab18" type="radio" name="tabs-modal"
                    *ngIf="customFieldsList !== undefined && (customFieldsList.customAttribute1Title !== '' || customFieldsList.customAttribute2Title !== '' || customFieldsList.customAttribute3Title !== '' || customFieldsList.customAttribute4Title !== '' || customFieldsList.customAttribute5Title !== '')" />

                <nav>
                    <label for="tab11">{{"sim-cards.general-1"|translate}}</label>
                    <label for="tab12">{{"sim-cards.notes-1"|translate}}</label>
                    <label for="tab18"
                        *ngIf="customFieldsList !== undefined && (customFieldsList.customAttribute1Title !== '' || customFieldsList.customAttribute2Title !== '' || customFieldsList.customAttribute3Title !== '' || customFieldsList.customAttribute4Title !== '' || customFieldsList.customAttribute5Title !== '')">{{"sim-cards.custom-fields"|translate}}</label>
                </nav>

                <figure>
                    <div class="tab11">

                        <div class="form-item">
                            <label for="MSISDN" class="col-form-label">{{"sim-cards.msisdn-2"|translate}}</label>
                            <input type="text" id="MSISDN" class="form-control" aria-describedby="MSISDNHelpInline"
                                name="msisdn" [ngModel]="selectedSimcardData!.msisdn" #msisdnFormRef="ngModel"
                                disabled="true" [value]="selectedSimcardData!.msisdn">
                        </div>
                        <div class="form-item">
                            <label for="ICCID" class="col-form-label">{{"sim-cards.iccid-2"|translate}}</label>
                            <input type="text" id="ICCID" class="form-control" aria-describedby="ICCIDHelpInline"
                                name="iccid" [ngModel]="selectedSimcardData!.iccid" #iccidFormRef="ngModel"
                                disabled="true" [value]="selectedSimcardData!.iccid">
                        </div>
                        <div class="form-item">
                            <label for="Bezeichnung"
                                class="col-form-label">{{"sim-cards.designation-2"|translate}}</label>
                            <input type="text" id="Bezeichnung" class="form-control name"
                                aria-describedby="BezeichnungHelpInline" name="name"
                                [(ngModel)]="selectedSimcardData!.name" #nameFormRef="ngModel"
                                [value]="selectedSimcardData!.name">
                        </div>
                        <div class="form-item">
                            <div class="trafficProfile">
                                <label for="TrafficProfile"
                                    class="col-form-label">{{'sim-cards.traffic-profile2'|translate}}</label>
                                <p-dropdown scrollHeight="130px" placeholder="{{'sim-cards.choose'|translate}}"
                                    (onFilter)="filterTrafficProfiles($event)" name="selectedTrafficProfile"
                                    [(ngModel)]="selectedTrafficProfile" [options]="trafficProfiles" optionLabel="name"
                                    [filter]="true" filterBy="name" [required]="true"></p-dropdown>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="costCenter">
                                <label for="Kostenstelle"
                                    class="col-form-label">{{"sim-cards.cost-center-1"|translate}}</label>
                                <p-dropdown scrollHeight="130px" placeholder="{{'sim-cards.choose'|translate}}"
                                    (onFilter)="filterCostCenters($event)" (onChange)="resetFilteredSimcardGroups()"
                                    name="selectedCostCenter" [(ngModel)]="selectedCostCenter" [options]="costCenters"
                                    optionLabel="name" [filter]="true" filterBy="name" [required]="true"></p-dropdown>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="groups">
                                <label for="SIMGruppen"
                                    class="col-form-label">{{"sim-cards.sim-groups"|translate}}</label>
                                <p-multiSelect [options]="filteredSimcardGroups" display="chip" id="SIMGruppen"
                                    class="groups" name="selectedSimcardGroups" [(ngModel)]="selectedSimcardGroups"
                                    optionLabel="name"></p-multiSelect>
                            </div>
                        </div>
                    </div>
                    <div class="tab12">
                        <div class="form-item">
                            <label for="Note1" class="col-form-label">{{"sim-cards.note-1"|translate}}</label>
                            <textarea type="text" id="Note1" class="form-control note1"
                                aria-describedby="Note1HelpInline" name="note1" [(ngModel)]="selectedSimcardData!.note1"
                                #note1FormRef="ngModel" [value]="selectedSimcardData!.note1"></textarea>
                        </div>
                        <div class="form-item">
                            <label for="Note2" class="col-form-label">{{"sim-cards.note-2"|translate}}</label>
                            <textarea type="text" id="Note2" class="form-control note2"
                                aria-describedby="Note2HelpInline" name="note2" [(ngModel)]="selectedSimcardData!.note2"
                                #note2FormRef="ngModel" [value]="selectedSimcardData!.note2"></textarea>
                        </div>
                    </div>
                    <div class="tab18">
                        <div
                            *ngIf="!( (customFieldsList !== undefined && customFieldsList.customAttribute1Title !== '' && customFieldsList.customAttribute1Title !== null)
                                    || (customFieldsList !== undefined && customFieldsList.customAttribute2Title !== '' && customFieldsList.customAttribute2Title !== null)
                                    || (customFieldsList !== undefined && customFieldsList.customAttribute3Title !== '' && customFieldsList.customAttribute3Title !== null)
                                    || (customFieldsList !== undefined && customFieldsList.customAttribute4Title !== '' && customFieldsList.customAttribute4Title !== null)
                                    || (customFieldsList !== undefined && customFieldsList.customAttribute5Title !== '' && customFieldsList.customAttribute5Title !== null) )">
                            <p>{{"sim-cards.custom-field-description" | translate}}</p>
                            <div class="button-center">
                                <a class="button save"
                                    routerLink="/setup/simcards-custom-fields">{{'header.simcards-custom-fields' |
                                    translate}}</a>
                            </div>
                        </div>

                        <div class="form-item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute1Title !== '' && customFieldsList.customAttribute1Title !== null">
                            <span class="label">{{customFieldsList.customAttribute1Title}}</span>
                            <input type="text" class="form-control" aria-describedby="dataField1" name="dataField1"
                                [(ngModel)]="dataField1" #dataField1Ref="ngModel" [value]="dataField1">
                        </div>

                        <div class="form-item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute2Title !== '' && customFieldsList.customAttribute2Title !== null">
                            <span class="label">{{customFieldsList.customAttribute2Title}}</span>
                            <input type="text" class="form-control" aria-describedby="dataField2" name="dataField2"
                                [(ngModel)]="dataField2" #dataField2Ref="ngModel" [value]="dataField2">
                        </div>

                        <div class="form-item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute3Title !== '' && customFieldsList.customAttribute3Title !== null">
                            <span class="label">{{customFieldsList.customAttribute3Title}}</span>
                            <input type="text" class="form-control" aria-describedby="dataField3" name="dataField3"
                                [(ngModel)]="dataField3" #dataField3Ref="ngModel" [value]="dataField3">
                        </div>

                        <div class="form-item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute4Title !== '' && customFieldsList.customAttribute4Title !== null">
                            <span class="label">{{customFieldsList.customAttribute4Title}}</span>
                            <input type="text" class="form-control" aria-describedby="dataField4" name="dataField4"
                                [(ngModel)]="dataField4" #dataField4Ref="ngModel" [value]="dataField4">
                        </div>

                        <div class="form-item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute5Title !== '' && customFieldsList.customAttribute5Title !== null">
                            <span class="label">{{customFieldsList.customAttribute5Title}}</span>
                            <input type="text" class="form-control" aria-describedby="dataField5" name="dataField5"
                                [(ngModel)]="dataField5" #dataField5Ref="ngModel" [value]="dataField5">
                        </div>
                    </div>
                </figure>
            </div>
        </form>

        <div class="button-center">
            <button [disabled]="!simcardForm.valid" type="button" class="button save" id="submit" role="button"
                (click)="executeUpdateAction();">
                {{"sim-cards.save"|translate}}
            </button>
        </div>
    </div>
</div>
<!-- End Edit Modal -->

<!-- Mass Update Modal -->
<div class="block-modal-mass-update">
    <input type="checkbox" class="modal-chkbox" id="modal-mass-update" value="{{isMassModalChecked}}"
        [(ngModel)]="isMassModalChecked">
    <label for="modal-mass-update" class="disable-modal-label"></label>
    <label for="modal-mass-update" class="modal-background"></label>

    <div class="modal">
        <label for="modal-mass-update" class="close-modal"><img src="assets/images/close.svg"
                alt="close-icon" /></label>

        <h3 class="modal-title">{{"sim-cards.edit"|translate}} {{(selectAllCheckBoxState)?countItems:selectedSimCards}}
            {{"sim-cards.sim-cards-selected"|translate}}</h3>

        <form name="simcardBachForm" ngForm #simcardBachForm="ngForm">
            <div class="tabbed-modal">
                <input checked="checked" id="tab15" type="radio" name="tabs-mass-modal" />
                <input id="tab16" type="radio" name="tabs-mass-modal" />
                <input id="tab17" type="radio" name="tabs-mass-modal" />
                <input id="tab19" type="radio" name="tabs-mass-modal"
                    *ngIf="customFieldsList !== undefined && (customFieldsList.customAttribute1Title !== '' || customFieldsList.customAttribute2Title !== '' || customFieldsList.customAttribute3Title !== '' || customFieldsList.customAttribute4Title !== '' || customFieldsList.customAttribute5Title !== '')" />

                <nav>
                    <label for="tab15">{{"sim-cards.general-1"|translate}}</label>
                    <label for="tab16">{{"sim-cards.notes-1"|translate}}</label>
                    <label for="tab19"
                        *ngIf="customFieldsList !== undefined && (customFieldsList.customAttribute1Title !== '' || customFieldsList.customAttribute2Title !== '' || customFieldsList.customAttribute3Title !== '' || customFieldsList.customAttribute4Title !== '' || customFieldsList.customAttribute5Title !== '')">{{"sim-cards.custom-fields"|translate}}</label>
                </nav>
                <figure>
                    <div class="tab15">
                        <div class="form-item">
                            <input id="Mass-Bezeichnung" type="checkbox" name="chkBatchUpdateName"
                                value="{{chkBatchUpdateName}}" [(ngModel)]="chkBatchUpdateName">
                            <label for="Mass-Bezeichnung"
                                class="col-form-label">{{"sim-cards.designation-2"|translate}}</label>
                            <input type="text" class="form-control" aria-describedby="BezeichnungHelpInline" name="name"
                                [disabled]="!chkBatchUpdateName" [(ngModel)]="txtBatchUpdateName"
                                #txtBatchUpdateNameRef="ngModel" [value]="txtBatchUpdateName">
                        </div>
                        <div class="form-item">
                            <input id="Mass-TrafficProfile" type="checkbox" name="chkBatchUpdateTrafficProfile"
                                value="{{chkBatchUpdateTrafficProfile}}" [(ngModel)]="chkBatchUpdateTrafficProfile"
                                (change)="restartTrafficProfile($event);">
                            <label for="Mass-TrafficProfile"
                                class="col-form-label">{{'sim-cards.traffic-profile2'|translate}}</label>
                            <p-dropdown scrollHeight="130px" placeholder="{{'sim-cards.choose'|translate}}"
                                (onFilter)="filterTrafficProfiles($event)" name="selBatchUpdateTrafficProfile"
                                [(ngModel)]="selBatchUpdateTrafficProfile" [options]="trafficProfiles"
                                optionLabel="name" [filter]="true" filterBy="name"
                                [disabled]="!chkBatchUpdateTrafficProfile" [required]="true"></p-dropdown>
                        </div>
                        <div class="form-item">
                            <input id="Mass-Kostenstelle" type="checkbox" name="chkBatchUpdateCostCenter"
                                value="{{chkBatchUpdateCostCenter}}" [(ngModel)]="chkBatchUpdateCostCenter"
                                (change)="restartCostCenter($event);">
                            <label for="Mass-Kostenstelle"
                                class="col-form-label">{{"sim-cards.cost-center-1"|translate}}</label>
                            <p-dropdown scrollHeight="130px" placeholder="{{'sim-cards.choose'|translate}}"
                                (onFilter)="filterCostCenters($event)" (onChange)="resetBatchFilteredSimcardGroups()"
                                name="selBatchUpdateCostCenter" [(ngModel)]="selBatchUpdateCostCenter"
                                [options]="costCenters" optionLabel="name" [filter]="true" filterBy="name"
                                [disabled]="!chkBatchUpdateCostCenter" [required]="true"></p-dropdown>
                        </div>
                        <div class="form-item">
                            <input id="Mass-SIMGruppen" type="checkbox" name="chkBatchUpdateSimGroups"
                                value="{{chkBatchUpdateSimGroups}}" [(ngModel)]="chkBatchUpdateSimGroups"
                                (change)="restartSimGroups($event);">
                            <label for="Mass-SIMGruppen"
                                class="col-form-label">{{"sim-cards.sim-groups"|translate}}</label>
                            <select name="SIMGroupChange" required [disabled]="!chkBatchUpdateSimGroups"
                                [(ngModel)]="batchGroupOpt" #batchGroupOptRef="ngModel">
                                <option value="">{{"general.choice" | translate}}</option>
                                <option value="add">{{"general.add" | translate}}</option>
                                <option value="delete">{{"general.erase" | translate}}</option>
                                <option value="change">{{"general.change" | translate}}</option>
                            </select>
                        </div>
                        <div class="form-item" *ngIf="batchGroupOpt!==''">
                            <p-multiSelect [options]="batchFilteredSimcardGroups" display="chip" class="groups"
                                name="batchSimcardGroups" [(ngModel)]="batchSimcardGroups"
                                optionLabel="name"></p-multiSelect>
                        </div>
                    </div>
                    <div class="tab16">
                        <div class="form-item">
                            <input id="Mass-Note1" type="checkbox" name="chkBatchUpdateNote1"
                                value="{{chkBatchUpdateNote1}}" [(ngModel)]="chkBatchUpdateNote1">
                            <label for="Mass-Note1" class="col-form-label">{{"sim-cards.note-1"|translate}}</label>
                            <textarea type="text" class="form-control" aria-describedby="Note1HelpInline" name="note1"
                                [disabled]="!chkBatchUpdateNote1" [(ngModel)]="batchGroupNote1" #note1FormRef="ngModel"
                                [value]="batchGroupNote1"></textarea>
                        </div>
                        <div class="form-item">
                            <input id="Mass-Note2" type="checkbox" name="chkBatchUpdateNote2"
                                value="{{chkBatchUpdateNote2}}" [(ngModel)]="chkBatchUpdateNote2">
                            <label for="Mass-Note2" class="col-form-label">{{"sim-cards.note-2"|translate}}</label>
                            <textarea type="text" class="form-control" aria-describedby="Note2HelpInline" name="note2"
                                [disabled]="!chkBatchUpdateNote2" [(ngModel)]="batchGroupNote2" #note1FormRef="ngModel"
                                [value]="batchGroupNote2"></textarea>
                        </div>
                    </div>
                    <div class="tab19"
                        *ngIf="customFieldsList !== undefined && (customFieldsList.customAttribute1Title !== '' || customFieldsList.customAttribute2Title !== '' || customFieldsList.customAttribute3Title !== '' || customFieldsList.customAttribute4Title !== '' || customFieldsList.customAttribute5Title !== '')">

                        <div
                            *ngIf="!( (customFieldsList !== undefined && customFieldsList.customAttribute1Title !== '' && customFieldsList.customAttribute1Title !== null)
                                    || (customFieldsList !== undefined && customFieldsList.customAttribute2Title !== '' && customFieldsList.customAttribute2Title !== null)
                                    || (customFieldsList !== undefined && customFieldsList.customAttribute3Title !== '' && customFieldsList.customAttribute3Title !== null)
                                    || (customFieldsList !== undefined && customFieldsList.customAttribute4Title !== '' && customFieldsList.customAttribute4Title !== null)
                                    || (customFieldsList !== undefined && customFieldsList.customAttribute5Title !== '' && customFieldsList.customAttribute5Title !== null) )">
                            <p>{{"sim-cards.custom-field-description" | translate}}</p>
                            <div class="button-center">
                                <a class="button save"
                                    routerLink="/setup/simcards-custom-fields">{{'header.simcards-custom-fields' |
                                    translate}}</a>
                            </div>
                        </div>

                        <div class="form-item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute1Title !== '' && customFieldsList.customAttribute1Title !== null">
                            <input id="chkBatchUpdateDataField1" type="checkbox" name="chkBatchUpdateDataField1"
                                value="{{chkBatchUpdateDataField1}}" [(ngModel)]="chkBatchUpdateDataField1">
                            <label for="chkBatchUpdateDataField1"
                                class="col-form-label">{{customFieldsList.customAttribute1Title}}</label>
                            <input type="text" class="form-control" aria-describedby="dataField1" name="dataField1"
                                [(ngModel)]="dataField1" #dataField1Ref="ngModel" [value]="dataField1"
                                [disabled]="!chkBatchUpdateDataField1">
                        </div>

                        <div class="form-item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute2Title !== '' && customFieldsList.customAttribute2Title !== null">
                            <input id="chkBatchUpdateDataField2" type="checkbox" name="chkBatchUpdateDataField2"
                                value="{{chkBatchUpdateDataField2}}" [(ngModel)]="chkBatchUpdateDataField2">
                            <label for="chkBatchUpdateDataField2"
                                class="col-form-label">{{customFieldsList.customAttribute2Title}}</label>
                            <input type="text" class="form-control" aria-describedby="dataField2" name="dataField2"
                                [(ngModel)]="dataField2" #dataField2Ref="ngModel" [value]="dataField2"
                                [disabled]="!chkBatchUpdateDataField2">
                        </div>

                        <div class="form-item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute3Title !== '' && customFieldsList.customAttribute3Title !== null">
                            <input id="chkBatchUpdateDataField3" type="checkbox" name="chkBatchUpdateDataField3"
                                value="{{chkBatchUpdateDataField3}}" [(ngModel)]="chkBatchUpdateDataField3">
                            <label for="chkBatchUpdateDataField3"
                                class="col-form-label">{{customFieldsList.customAttribute3Title}}</label>
                            <input type="text" class="form-control" aria-describedby="dataField3" name="dataField3"
                                [(ngModel)]="dataField3" #dataField3Ref="ngModel" [value]="dataField3"
                                [disabled]="!chkBatchUpdateDataField3">
                        </div>

                        <div class="form-item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute4Title !== '' && customFieldsList.customAttribute4Title !== null">
                            <input id="chkBatchUpdateDataField4" type="checkbox" name="chkBatchUpdateDataField4"
                                value="{{chkBatchUpdateDataField4}}" [(ngModel)]="chkBatchUpdateDataField4">
                            <label for="chkBatchUpdateDataField4"
                                class="col-form-label">{{customFieldsList.customAttribute4Title}}</label>
                            <input type="text" class="form-control" aria-describedby="dataField4" name="dataField4"
                                [(ngModel)]="dataField4" #dataField4Ref="ngModel" [value]="dataField4"
                                [disabled]="!chkBatchUpdateDataField4">
                        </div>

                        <div class="form-item"
                            *ngIf="customFieldsList !== undefined && customFieldsList.customAttribute5Title !== '' && customFieldsList.customAttribute5Title !== null">
                            <input id="chkBatchUpdateDataField5" type="checkbox" name="chkBatchUpdateDataField5"
                                value="{{chkBatchUpdateDataField5}}" [(ngModel)]="chkBatchUpdateDataField5">
                            <label for="chkBatchUpdateDataField5"
                                class="col-form-label">{{customFieldsList.customAttribute4Title}}</label>
                            <input type="text" class="form-control" aria-describedby="dataField5" name="dataField5"
                                [(ngModel)]="dataField5" #dataField5Ref="ngModel" [value]="dataField5"
                                [disabled]="!chkBatchUpdateDataField5">
                        </div>
                    </div>
                </figure>
            </div>
        </form>

        <div class="button-center">
            <!-- <button type="button" class="button save" id="submit" role="button" (click)="executeUpdateAction();">
                {{"sim-cards.button-execute"|translate}}
            </button> -->
            <button [disabled]="!simcardBachForm.valid" *ngIf="!selectAllCheckBoxState; else elseBlock" type="submit"
                class="button save"
                (click)="executeUpdateCurrentAction();">{{"sim-cards.button-execute"|translate}}</button>
            <ng-template #elseBlock>
                <button [disabled]="!simcardBachForm.valid" type="submit" class="button save"
                    (click)="executeUpdateMultiAction();">{{"sim-cards.button-execute"|translate}}</button>
            </ng-template>
        </div>
    </div>
</div>
<!-- End Mass Update Modal -->

<!-- Status Modal -->
<div class="block-modal-status">
    <input type="checkbox" class="modal-chkbox" id="modal-status" value="{{isModalStatusChecked}}"
        [(ngModel)]="isModalStatusChecked">
    <label for="modal-status" class="disable-modal-label"></label>
    <label for="modal-status" class="modal-background"></label>

    <div class="modal">
        <label for="modal-status" class="close-modal"><img src="assets/images/close.svg" alt="close-icon" /></label>

        <h3 class="modal-title">{{"general.updatestatus" | translate}}</h3>

        <div class="chart-block">
            <div class="chart-item status">
                <div class="chart-item-labels-graph">
                    <div class="chart-item-labels">
                        <div class="chart-item-label"></div>
                        <div class="chart-item-usage-label">{{simCardsProcessedInPrecent}}% {{"general.completed" |
                            translate}}</div>
                    </div>
                    <div class="chart-item-graph">
                        <div class="chart-item-usage" style="width: {{simCardsProcessedInPrecent}}%"></div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="simCardsUpdateErrors.length > 0" class="status-error">
            <div class="status-error-title">{{"general.error-iccid" | translate}}:</div>
            <div class="error-items">
                <div *ngFor="let simError of simCardsUpdateErrors" class="error-item" title="{{simError.error}}">
                    {{simError.simcard.iccid}} <img src="assets/images/warning.svg" alt="error info icon"
                        class="error-info-icon"></div>
            </div>
        </div>
    </div>
</div>
<!-- End Status Modal -->

<!-- Batch Confirmation Modal -->
<div class="block-modal">
    <input type="checkbox" class="modal-chkbox" id="batch-modal" value="{{isBatchModalConfirmationChecked}}"
        [(ngModel)]="isBatchModalConfirmationChecked">
    <label for="batch-modal" class="disable-modal-label"></label>
    <label for="batch-modal" class="modal-background"></label>

    <div class="modal">
        <label for="batch-modal" class="close-modal"><img src="assets/images/close.svg" alt="close-icon" /></label>

        <h3 class="modal-title">{{'sim-cards.'+multiSimAction | translate}}
            {{(selectAllCheckBoxState)?countItems:selectedSimCards}}
            {{"sim-cards.sim-cards-selected"|translate}} </h3>

        <form name="simcardForm" ngForm #simcardForm="ngForm" *ngIf="multiSimAction === 'set_roaming_policy'">
            <label for="roaming-policy">{{"sim-cards.set_roaming_policy"|translate}}</label>
            <select id="roaming-policy" name="roamingPolicy" [(ngModel)]="selectedRoamingPolicy"
                #selectedRoamingPolicyRef="ngModel">
                <option *ngFor="let item of ['', 'none','eu','eu_ch','world']" value="{{item}}">{{item.toUpperCase()}}
                </option>
            </select>
        </form>

        <div class="button-center spaced">
            <button type="button" class="button save" id="submit" role="button"
                [disabled]="multiSimAction === 'set_roaming_policy' && selectedRoamingPolicy === ''"
                (click)="executeSimcardsBatchAction();">
                {{"sim-cards.confirm"|translate}}
            </button>
            <button type="cancel" class="button cancel" id="cancel" role="button" (click)="cancelBatchActions();">
                {{"sim-cards.cancel"|translate}}
            </button>

            <div class="loading-backend-layer" *ngIf="actionActive">
                <img src="assets/images/AjaxLoader.gif" alt="loading" />
                <p class="loading-text">{{"sim-cards.loading"|translate}}</p>
            </div>
        </div>
    </div>
</div>
<!-- End Edit Modal -->
