import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CardService } from 'src/app/services/card.service';
//import { SimcardTag } from 'src/app/interfaces/simcard-tag';
import { SimcardGroupData } from 'src/app/interfaces/simcard-group-data';
import { SimCardsGroups } from 'src/app/interfaces/simcardsgroups';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { CustomTableColumn } from 'src/app/interfaces/custom-table-column';
import { SimcardGroup } from 'interfaces/simcardgroup';
import { CostCenterService } from 'src/app/services/cost-center.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CostCenterList } from 'src/app/interfaces/cost-center-list';
import { FilterSubElement } from 'src/app/interfaces/filter-sub-element';
import { CostCenter } from 'interfaces/costcenter';
import { FilterElement } from 'src/app/interfaces/filter-element';

declare const successMessage: FunctionStringCallback;

interface MapKeys {
  name: string
}

@Component({
  selector: 'app-sim-tags',
  templateUrl: './sim-tags.component.html',
  styleUrls: ['./sim-tags.component.css']
})
export class SimTagsComponent implements OnInit, OnDestroy {
  private languageSubscription: Subscription;

  public simcardGroups: SimcardGroupData[] = [];
  public emailCounter = 0;
  public smsCounter = 0;
  public emailElements: boolean[] = [];
  public emailList: string[] | undefined = [];
  public smsElements: boolean[] = [];
  public smsList: string[] | undefined = [];
  public loading = true;
  public countItems = 0;
  public tableIsVisible = true;
  public simcardGroupSelected = false;
  public isModalChecked = false;
  public selectedSimcardGroupData: SimcardGroupData | null = null;
  public simcardGroupName!: string;
  public numberOfCheckedSimCards = 0;
  public isSimCardChecked = false;
  public cols!: CustomTableColumn[];
  public mainCheckBoxState = false;
  public prepereDeleteGroup!: SimcardGroup;
  public showDeleteModal = false;
  public costCentersFilter: FilterSubElement[] = [];
  public costCenters: CostCenter[] = [];
  public costCenter: string | undefined = "";
  public sortDirection: number | null | undefined;
  public searchKey = '';
  public filterList: FilterElement[] = [];
  public mapKeys: MapKeys = {
    name: 'name'
  };

  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService, private cardService: CardService, private costCenterService: CostCenterService, private errorService: ErrorHandlerService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }

    this.cols = [
      { field: 'name', checked: true },
      { field: 'costCenterName', checked: true }
    ];

    this.filterList = [
      { field: 'name', type: 'input', checked: false, value: "" },
      { field: 'costCenter', type: 'select', checked: false, value: "", options: this.costCentersFilter }
    ];

    this.listAllCostCenters();
  }

  ngOnInit() {
    console.log('initialized');
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  exportAsCSV() {
    this.cardService.getSimcardGroupsCSV(this.sessionService.getJWTToken()).subscribe({
      next: (response) => {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'simgroups_export.csv';
        hiddenElement.click();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  showSimGroupsModel() {
    this.errorService.deleteFieldError();
    this.simcardGroupName = '';
    this.costCenter = '';
    this.smsList = [];
    this.smsElements = [];
    this.emailList = [];
    this.emailElements = [];

    this.selectedSimcardGroupData = null;
    this.simcardGroupSelected = false;
  }

  listAllSimCardGroups() {
    this.cardService.getSimcardGroupsList(this.sessionService.getJWTToken(), this.mapKeys[this.searchKey as keyof MapKeys], this.sortDirection, this.filterList).subscribe({
      next: (data: SimCardsGroups) => {
        this.simcardGroups = [];
        this.simcardGroups = data['hydra:member'];
        this.countItems = (data["hydra:totalItems"])?data["hydra:totalItems"]:0;

        for (const simcardGroup of this.simcardGroups) {
          const costCenter = this.costCenters.find(costCenter => costCenter['@id'] === simcardGroup.costCenter);
          if(costCenter) {
            simcardGroup.costCenterName = costCenter.name;
          }  
        }

        this.loading = false;
      }, 
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  loadDataLazy(event: TableLazyLoadEvent): void {
    if (event && event.sortField && event.sortField && typeof event.sortField === 'string') {
      this.searchKey = event.sortField;
    }

    this.sortDirection = event.sortOrder;

    this.listAllSimCardGroups();
  }

  prepareDeleteGroup(simGroup: SimcardGroupData) {
    this.prepereDeleteGroup = simGroup;
    this.showDeleteModal = true;
  }

  deleteSelectedGroup(simGroup: SimcardGroupData) {
    //console.log(simData);
    this.cardService.deleteSimcardGroup(this.sessionService.getJWTToken(), simGroup?.['@id']).subscribe({
      next: (response) => {
        //console.log(response);
        this.listAllSimCardGroups();

        successMessage(this.translate.instant('sim-tag.tag-deleted'));
        this.showDeleteModal = false;
        return response;
      }, 
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  hideDetailsBlock() {
    this.selectedSimcardGroupData = null;
    this.simcardGroupSelected = false;
    this.simcardGroupName = '';
  }

  editSelectedGroup(simcardGroup: SimcardGroupData) {
    this.isModalChecked = true;
    this.selectedSimcardGroupData = simcardGroup;
    this.simcardGroupSelected = true;

    this.simcardGroupName = this.selectedSimcardGroupData.name as string;
    this.costCenter = this.selectedSimcardGroupData.costCenter;

    this.emailList = this.selectedSimcardGroupData.emailNotificationTargets;
    if (this.emailList != undefined && this.emailList.length > 0) {
      this.emailElements = Array(this.emailList?.length).fill(true);
    } else {
      this.emailElements = [];
      this.emailList = [];
    }

    this.smsList = this.selectedSimcardGroupData.smsNotificationTargets;
    if (this.smsList != undefined && this.smsList.length > 0) {
      this.smsElements = Array(this.smsList?.length).fill(true);
    } else {
      this.smsList = [];
      this.smsElements = [];
    }
  }

  createSimcardGroup() {
    this.cardService.createNewSimcardGroup(this.sessionService.getJWTToken(), 
    {
      name: this.simcardGroupName,
      costCenter: this.costCenter,
      emailNotificationTargets: this.emailList,
      smsNotificationTargets: this.smsList
    }).subscribe({
      next: (data: SimcardGroupData) => {
        //console.log(data);
        this.isModalChecked = false;
        this.simcardGroupName = '';
        this.smsList = [];
        this.smsElements = [];
        this.emailList = [];
        this.emailElements = [];

        this.hideDetailsBlock();

        this.listAllSimCardGroups();

        successMessage(this.translate.instant('sim-tag.add-tag'));
        return data;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  updateSimcardGroup() {
    this.cardService.updateExistingSimcardGroup(this.sessionService.getJWTToken(), this.selectedSimcardGroupData?.['@id'], 
    {
      name: this.simcardGroupName,
      // costCenter: this.costCenter, disable for edit
      emailNotificationTargets: this.emailList,
      smsNotificationTargets: this.smsList
    }).subscribe({
      next: (data: SimcardGroupData) => {
        //console.log(data);
        this.isModalChecked = false;
        this.simcardGroupName = '';
        this.smsList = [];
        this.smsElements = [];
        this.emailList = [];
        this.emailElements = [];

        this.selectedSimcardGroupData = null;
        this.simcardGroupSelected = true;
        this.hideDetailsBlock();

        this.listAllSimCardGroups();

        successMessage(this.translate.instant('sim-tag.edit-tag'));
        return data;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  executeGroupAction() {
    this.errorService.deleteFieldError();
    if (this.selectedSimcardGroupData === null) {
      this.createSimcardGroup();
    } else {
      this.updateSimcardGroup();
    }
  }

  addNewEmailBlock(): void {
    this.emailElements.push(true);
  }

  addNewSMSBlock(): void {
    this.smsElements.push(true);
  }

  insertNewEMail(event: Event, index: number): void {
    if (this.emailList != undefined && this.emailList[index] === undefined) {
      this.emailList?.push((<HTMLInputElement>event.target).value);
    } else if (this.emailList != undefined && this.emailList[index] !== undefined) {
      this.emailList[index] = (<HTMLInputElement>event.target).value;
    }
  }

  deleteFromEmailList(index: number): void {
    this.emailList?.splice(index, 1);
    this.emailElements.splice(index, 1);
  }

  insertNewSMS(event: Event, index: number): void {
    if (this.smsList != undefined && this.smsList[index] === undefined) {
      this.smsList?.push((<HTMLInputElement>event.target).value);
    } else if (this.smsList != undefined && this.smsList[index] !== undefined) {
      this.smsList[index] = (<HTMLInputElement>event.target).value;
    }
  }

  deleteFromSMSList(index: number): void {
    this.smsList?.splice(index, 1);
    this.smsElements?.splice(index, 1);
  }

  listAllCostCenters() {
    this.costCenterService.getCostCenterList(this.sessionService.getJWTToken(), null, 0, null).subscribe({
      next: (data: CostCenterList) => {
        this.costCenters = [];
        this.costCenters = data['hydra:member'];

        for (const costCenter of this.costCenters) {
          this.costCentersFilter.push({ value: costCenter['@id'], label: costCenter.name });
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      },
    });

    //console.log(this.costCenters);
  }

  changeFilter() {
    this.listAllSimCardGroups();
  }

  filterClose(filter: FilterElement) {
    filter.checked = false;
    this.changeFilter();
  }
}
