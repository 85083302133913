import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { NgForm } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { UserData } from 'src/app/interfaces/userlist';

interface DeviceData {
  uuid?: string
  name?: string | unknown
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  private languageSubscription: Subscription;

  private restartLoginProcess: Subscription;

  public username = '';
  public password = '';
  public languageForm = "de";

  public barCodeNumber = '';

  public is2FaRequired = false;
  public is2FaSetupRequired = false;

  public errorExist = false;

  public isUserLogged = false;

  public listOfTotpDevices!: DeviceData[];

  public twoFaServerData: any;
  public selectedDevice: any;

  public effectivePermissions: string[] | undefined = [];
  public qualifiedUsername = '';

  @ViewChild('f', { static: true }) formSubmit!: NgForm;

  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService, private errorService: ErrorHandlerService) {
    this.sessionService.setDefaultLanguage(this.sessionService.getDefaultLanguage());

    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    this.restartLoginProcess = this.sessionService.restartLoginProcessSubject.subscribe(data => {
      if (data.status == true) {
        this.is2FaRequired = false;
        this.is2FaSetupRequired = false;
        this.listOfTotpDevices = [];
        this.selectedDevice = null;
      }
    });

    if (this.sessionService.getJWTToken() && this.sessionService.getJWTToken() != '') {
      this.showNext();
    }
  }

  ngOnInit() {
    console.log('initialized');
  }

  onLanguageChange() {
    this.sessionService.setDefaultLanguage(this.languageForm);
  }

  ngOnDestroy() {
    console.log('destroyed');
    this.restartLoginProcess.unsubscribe();
  }

  executeLogin() {
    this.errorExist = false;
    this.userService.loginWithCredential(this.username, this.password).subscribe((data: any) => {

      this.sessionService.setDefaultLanguage(this.languageForm);

      if ('token' in data) {
        //this.sessionService.setLoginStatus(true);
        this.sessionService.setJWTToken(data.token);

        this.getActiveUser();

        this.showNext();
      } else {
        this.twoFaServerData = data;

        if ('twoFactorRequired' in data) {
          this.is2FaRequired = true;

          this.listOfTotpDevices = [];

          Object.entries(data.availableTotpDevices).forEach(([key, value], index) => {
            this.listOfTotpDevices.push({ uuid: key, name: value });
          });

          this.selectedDevice = this.listOfTotpDevices[0];

        } else if ('twoFactorSetupRequired' in data) {
          this.is2FaRequired = true;
          this.is2FaSetupRequired = true;
        }
      }
    }, err => {
      this.errorService.processError(err);
    });
  }

  selectCurrentDevice(device: DeviceData) {
    this.selectedDevice = device;
    //console.log(this.selectedDevice);
  }

  deviceIdentifier(index: number, item: DeviceData) {
    return item['uuid'];
  }

  sendAuthCode() {
    this.userService.loginWithTotpDevice(this.barCodeNumber, this.twoFaServerData.twoFactorToken, this.selectedDevice.uuid).subscribe({
      next: (response: any) => {
        console.log(response);
        //this.sessionService.setLoginStatus(true);
        this.sessionService.setJWTToken(response.token);

        this.getActiveUser();

        this.showNext();
      },
      error: (err: any) => {
        this.errorService.processError(err);
      }
    });
  }

  executeDeviceModal(): void {
    this.sessionService.pushAuthModal(true, this.twoFaServerData.setupToken);
  }

  showNext(): void {
    this.router.navigateByUrl('/dashboard').then((e) => {
      if (e) {
        //console.log("Navigation is successful!");
      } else {
        //console.log("Navigation has failed!");
      }
    });
  }

  getActiveUser() {
    this.userService.getActiveUser(this.sessionService.getJWTToken()).subscribe({
      next: (response: UserData) => {
        this.effectivePermissions = response.effectivePermissions;
        this.sessionService.setEffectivePermissions(this.effectivePermissions);

        this.qualifiedUsername = (response.qualifiedUsername)?response.qualifiedUsername:'';
        this.sessionService.setQualifiedUsername(this.qualifiedUsername);

        this.sessionService.setLoginStatus(true);
      },
      error: (err: any) => {
        this.errorService.processError(err);
      }
    });
  }
}
