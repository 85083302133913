import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { authGuard } from 'src/app/guards/auth.guard';

import { DashboardComponent } from './dashboard/dashboard.component';
import { SimCardsListComponent } from './sim-cards-list/sim-cards-list.component';

import { CostCenterComponent } from './cost-center/cost-center.component';
import { SimTagsComponent } from './sim-tags/sim-tags.component';
import { TrafficProfileComponent } from './traffic-profile/traffic-profile.component';
import { UserComponent } from './user/user.component';
import { UserGroupsComponent } from './user-groups/user-groups.component';
import { VodafoneBanComponent } from './vodafone-ban/vodafone-ban.component';
import { SimCardActivateComponent } from './sim-card-activate/sim-card-activate.component';
import { SimCardOrderComponent } from './sim-card-order/sim-card-order.component';
import { MultiSimCardCSVImportComponent } from './multi-sim-card-csvimport/multi-sim-card-csvimport.component';
import { ProfileComponent } from './profile/profile.component';
import { ReportComponent } from './report/report.component';
import { ReportListComponent } from './report-list/report-list.component';
import { JobHistoryComponent } from './job-history/job-history.component';
import { CustomDataFieldsComponent } from './custom-data-fields/custom-data-fields.component';

const routes: Routes = [
    //TODO: For preventing Error 404 Page Not Found the line bellow shoud be commented
    { path: '', component: DashboardComponent, canActivate: [authGuard] },
    { path: 'simcards', component: SimCardsListComponent, canActivate: [authGuard] },
    { path: 'simcards/simcards-csv-update', component: MultiSimCardCSVImportComponent, canActivate: [authGuard] },
    { path: 'setup/simcards-custom-fields', component: CustomDataFieldsComponent, canActivate: [authGuard] },
    { path: 'setup/cost-center', component: CostCenterComponent, canActivate: [authGuard] },
    { path: 'setup/sim-groups', component: SimTagsComponent, canActivate: [authGuard] },
    { path: 'setup/profile', component: TrafficProfileComponent, canActivate: [authGuard] },
    { path: 'setup/user', component: UserComponent, canActivate: [authGuard] },
    { path: 'setup/user-groups', component: UserGroupsComponent, canActivate: [authGuard] },
    { path: 'setup/vodafone-ban', component: VodafoneBanComponent, canActivate: [authGuard] },
    { path: 'simcard/activate', component: SimCardActivateComponent, canActivate: [authGuard] },
    { path: 'simcard/order', component: SimCardOrderComponent, canActivate: [authGuard] },
    { path: 'account', component: ProfileComponent, canActivate: [authGuard] },
    { path: 'report', component: ReportComponent, canActivate: [authGuard] },
    { path: 'report/list', component: ReportListComponent, canActivate: [authGuard] },
    { path: 'logs/job-hitory', component: JobHistoryComponent, canActivate: [authGuard] },
    //{ path: 'dashboard/mysims', component: MysimsComponent, canActivate: [AuthGuard] },

    //TODO: Required for preventing Error 404 Page Not Found
    //{ path: '', pathMatch: 'full', component: DashHomeComponent, canActivate:[AuthGuard] },
    //{ path: '**', redirectTo: '/configuration' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }
