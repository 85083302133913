import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { SimCardsListComponent } from './sim-cards-list/sim-cards-list.component';

import { TableModule } from 'primeng/table';
import { ChartModule } from 'primeng/chart';
import { QRCodeModule } from 'angularx-qrcode';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { CostCenterComponent } from './cost-center/cost-center.component';
import { SimTagsComponent } from './sim-tags/sim-tags.component';
import { TrafficProfileComponent } from './traffic-profile/traffic-profile.component';
import { UserComponent } from './user/user.component';
import { UserGroupsComponent } from './user-groups/user-groups.component';
import { VodafoneBanComponent } from './vodafone-ban/vodafone-ban.component';
import { SimCardActivateComponent } from './sim-card-activate/sim-card-activate.component';
import { SimCardOrderComponent } from './sim-card-order/sim-card-order.component';
import { MultiSimCardCSVImportComponent } from './multi-sim-card-csvimport/multi-sim-card-csvimport.component';
import { ProfileComponent } from './profile/profile.component';
import { ReportComponent } from './report/report.component';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { JobHistoryComponent } from './job-history/job-history.component';
import { ReportListComponent } from './report-list/report-list.component';
import { CustomDataFieldsComponent } from './custom-data-fields/custom-data-fields.component';

@NgModule({
  declarations: [
    DashboardComponent,
    SimCardsListComponent,
    CostCenterComponent,
    SimTagsComponent,
    TrafficProfileComponent,
    UserComponent,
    UserGroupsComponent,
    VodafoneBanComponent,
    SimCardActivateComponent,
    SimCardOrderComponent,
    MultiSimCardCSVImportComponent,
    ProfileComponent,
    ReportComponent,
    JobHistoryComponent,
    ReportListComponent,
    CustomDataFieldsComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    ChartModule,
    QRCodeModule,
    DropdownModule,
    MultiSelectModule,
    AutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ]
})
export class DashboardModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
