import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
//import { LazyLoadEvent } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CostCenterService } from './../../services/cost-center.service';
import { CostCenter } from 'src/app/interfaces/cost-center';
import { CostCenterList } from 'src/app/interfaces/cost-center-list';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { CustomTableColumn } from 'src/app/interfaces/custom-table-column';
import { BanService } from 'src/app/services/ban.service';
import { Ban, BanList } from 'src/app/interfaces/ban';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterElement } from 'src/app/interfaces/filter-element';

declare const successMessage: FunctionStringCallback;

interface MapKeys {
  name: string,
}

@Component({
  selector: 'app-cost-center',
  templateUrl: './cost-center.component.html',
  styleUrls: ['./cost-center.component.css']
})
export class CostCenterComponent implements OnInit, OnDestroy {
  private languageSubscription: Subscription;

  public costCenters: CostCenter[] = [];
  public emailCounter = 0;
  public smsCounter = 0;
  public emailElements: boolean[] = [];
  public emailList: string[] | undefined = [];
  public smsElements: boolean[] = [];
  public smsList: string[] | undefined = [];
  public costCentersList: unknown;
  public loading = true;
  public countItems = 0;
  public costCenterName = '';
  public tableIsVisible = true;
  public costCenterSelected = false;
  public isModalChecked = false;
  public selectedCostCenterData: CostCenter | null = null;
  public cols!: CustomTableColumn[];
  public prepereDeleteCostCenter!: CostCenter;
  public showDeleteModal = false;
  public banList: Ban[] = [];
  public banPageNumber = 1;
  public banItemsPerPage = 100;
  public banSortBy = '';
  public ban: string | undefined = '';
  public sortDirection: number | null | undefined;
  public searchKey = '';
  public filterList: FilterElement[] = [];
  public mapKeys: MapKeys = {
    name: 'name'
  };

  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService, private costCenterService: CostCenterService, private banService: BanService,
    private errorService: ErrorHandlerService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }

    this.costCenters = [];

    this.cols = [
      { field: 'name', checked: true },
      { field: 'banName', checked: true }
    ];

    this.filterList = [
      { field: 'name', type: 'input', checked: false, value: "" }
    ];

    this.getBanList();
  }

  ngOnInit() {
    console.log('initialized');
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  listAllCostCenters() {
    this.costCenterService.getCostCenterList(this.sessionService.getJWTToken(), this.mapKeys[this.searchKey as keyof MapKeys], this.sortDirection, this.filterList).subscribe({
      next: (data: CostCenterList) => {
        this.costCentersList = data;
        //console.log(this.costCentersList);

        this.costCenters = data['hydra:member'];
        this.countItems = (data["hydra:totalItems"])?data["hydra:totalItems"]:0;

        for (const costCenter of this.costCenters) {
          const ban = this.banList.find(ban => ban['@id'] === costCenter.ban);
          if(ban) {
            costCenter.banName = ban.ban + ' ' + ((ban.description)?ban.description:'');
          }  
        }

        this.loading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  exportAsCSV() {
    this.costCenterService.getCostCenterCSV(this.sessionService.getJWTToken()).subscribe({
      next: (response) => {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'costcenter_export.csv';
        hiddenElement.click();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  loadDataLazy(event: TableLazyLoadEvent): void {
    if (event && event.sortField && event.sortField && typeof event.sortField === 'string') {
      this.searchKey = event.sortField;
    }

    this.sortDirection = event.sortOrder;

    this.listAllCostCenters();
  }

  hideDetailsBlock() {
    this.selectedCostCenterData = null;
    this.costCenterSelected = false;
  }

  editSelectedCostCenter(costCenter: CostCenter) {
    this.isModalChecked = true;
    this.selectedCostCenterData = costCenter;
    this.costCenterSelected = true;

    this.costCenterName = this.selectedCostCenterData.name as string;
    this.ban = this.selectedCostCenterData.ban;

    this.emailList = this.selectedCostCenterData.emailNotificationTargets;
    if (this.emailList != undefined && this.emailList.length > 0) {
      this.emailElements = Array(this.emailList?.length).fill(true);
    }

    this.smsList = this.selectedCostCenterData.smsNotificationTargets;
    if (this.smsList != undefined && this.smsList.length > 0) {
      this.smsElements = Array(this.smsList?.length).fill(true);
    }
  }

  createCostCenter() {
    this.costCenterService.createNewCostCenter(this.sessionService.getJWTToken(),
    {
      name: this.costCenterName,
      ban: this.ban,
      emailNotificationTargets: this.emailList,
      smsNotificationTargets: this.smsList
    }).subscribe({
      next: (data: CostCenter) => {
        //console.log(data);
        this.isModalChecked = false;
        this.costCenterName = '';
        this.smsList = [];
        this.smsElements = [];
        this.emailList = [];
        this.emailElements = [];

        this.hideDetailsBlock();

        this.listAllCostCenters();
        successMessage(this.translate.instant("cost-center.add-cost-center"));
        return data;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  updateCostCenter() {
    const costCenterDataID = this.selectedCostCenterData?.['@id'];

    this.costCenterService.updateCostCenter(this.sessionService.getJWTToken(), costCenterDataID,
    {
      name: this.costCenterName,
      // ban: this.ban, disable ban on edit
      emailNotificationTargets: this.emailList,
      smsNotificationTargets: this.smsList
    }).subscribe({
      next: (data: CostCenter) => {
        //console.log(data);
        this.isModalChecked = false;
        this.costCenterName = '';
        this.smsList = [];
        this.smsElements = [];
        this.emailList = [];
        this.emailElements = [];

        this.hideDetailsBlock();

        this.listAllCostCenters();
        successMessage(this.translate.instant("cost-center.edit-cost-center"));
        return data;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  executeUpdateAction() {
    if (this.selectedCostCenterData == null) {
      this.createCostCenter();
    } else {
      this.updateCostCenter();
    }
  }

  prepareDeleteCostCenter(costCenter: CostCenter) {
    this.prepereDeleteCostCenter = costCenter;
    this.showDeleteModal = true;
  }

  deleteSelectedCostCenter(costCenter: CostCenter) {
    //console.log(costCenter);
    this.costCenterService.deleteSimcardGroup(this.sessionService.getJWTToken(), costCenter?.['@id']).subscribe({
      next: (response) => {
        //console.log(response);
        this.listAllCostCenters();

        successMessage(this.translate.instant("cost-center.cost-center-deleted"));
        this.showDeleteModal = false;
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  addNewEmailBlock(): void {
    this.emailElements.push(true);
  }

  addNewSMSBlock(): void {
    this.smsElements.push(true);
  }

  showCostCenterModal() {
    this.costCenterName = '';
    this.ban = "";
    this.smsList = [];
    this.smsElements = [];
    this.emailList = [];
    this.emailElements = [];
    this.selectedCostCenterData = null;
    this.costCenterSelected = false;
    this.errorService.deleteFieldError();
  }

  insertNewEMail(event: Event, index: number): void {
    if (this.emailList != undefined && this.emailList[index] === undefined) {
      this.emailList?.push((<HTMLInputElement>event.target).value);
    } else if (this.emailList != undefined && this.emailList[index] !== undefined) {
      this.emailList[index] = (<HTMLInputElement>event.target).value;
    }
    //console.log(this.emailList);
  }

  deleteFromEmailList(index: number): void {
    this.emailList?.splice(index, 1);
    this.emailElements.splice(index, 1);
  }

  insertNewSMS(event: Event, index: number): void {
    if (this.smsList != undefined && this.smsList[index] === undefined) {
      this.smsList?.push((<HTMLInputElement>event.target).value);
    } else if (this.smsList != undefined && this.smsList[index] !== undefined) {
      this.smsList[index] = (<HTMLInputElement>event.target).value;
    }
  }

  deleteFromSMSList(index: number): void {
    this.smsList?.splice(index, 1);
    this.smsElements.splice(index, 1);
  }

  getBanList() {
    this.banList = [];

    this.banPageNumber = 1;
    this.getBanAllPages();
  }

  getBanAllPages() {
    this.banService.getBanList(this.sessionService.getJWTToken(), this.banPageNumber, this.banItemsPerPage, this.banSortBy, 1, null).subscribe({
      next: (response: BanList) => {

        this.banList.push(...response['hydra:member']);

        if(response['hydra:totalItems'] && this.banList.length < response['hydra:totalItems']) {
          this.banPageNumber++;
          this.getBanAllPages();
        } else {
          console.log(this.banList);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  changeFilter() {
    this.listAllCostCenters();
  }

  filterClose(filter: FilterElement) {
    filter.checked = false;
    this.changeFilter();
  }

}
