import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, catchError, map, throwError } from 'rxjs';
import { FilterElement } from '../interfaces/filter-element';
import { Ban, BanList } from '../interfaces/ban';
import { BASE_URL } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { TariffData, TariffDataList } from '../interfaces/tariff-data';

@Injectable({
  providedIn: 'root'
})
export class BanService {

  constructor(private http: HttpClient) { }

  getBanList(token: string | null, page = 1, numberOfItems = 20, sortKey: string | null | undefined = null, sortDirection: number | undefined | null = 0, filterElements: FilterElement[] | null, defaultLanguage = 'de'): Observable<BanList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('page', '' + page)
      .set('itemsPerPage', '' + numberOfItems);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<BanList>(BASE_URL + '/api/v2/vodafone_bans', httpOptions).pipe(map((batchList: BanList) => {
      return batchList;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  updateBan(token: string | null, formData: Ban, id: string, defaultLanguage = 'de'): Observable<Ban> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.patch<Ban>(BASE_URL + id, formData, httpOptions).pipe(map((banData: Ban) => {
      return banData;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getBanDetails(token: string | null, uuid: string | undefined, defaultLanguage = 'de'): Observable<Ban> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const params = new HttpParams();

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<Ban>(BASE_URL + uuid, httpOptions).pipe(map((banData: Ban) => {
      const dataResult = banData;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  getTariffList(token: string | null, defaultLanguage = 'de'): Observable<TariffDataList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
    };

    return this.http.get<TariffDataList>(BASE_URL + '/api/v2/vodafone_tariffs', httpOptions).pipe(map((tariffList: TariffDataList) => {
      return tariffList;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getTariffDetails(token: string | null, uuid: string | undefined, defaultLanguage = 'de'): Observable<TariffData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const params = new HttpParams();

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<TariffData>(BASE_URL + '/api/v2/vodafone_tariffs/' + uuid, httpOptions).pipe(map((tariffData: TariffData) => {
      const dataResult = tariffData;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }
}
