import { TrafficProfileService } from './../../services/traffic-profile.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
//import { LazyLoadEvent } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CardService } from 'src/app/services/card.service';
import { TrafficProfile } from 'src/app/interfaces/traffic-profile';
import { TrafficProfileList } from 'src/app/interfaces/traffic-profile-list';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { CustomTableColumn } from 'src/app/interfaces/custom-table-column';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterElement } from 'src/app/interfaces/filter-element';

declare const successMessage: FunctionStringCallback;

interface MapKeys {
  name?: string,
  warningNational?: string,
  limitNational?: string,
  warningInternational?: string,
  limitInternational?: string,
}

@Component({
  selector: 'app-traffic-profile',
  templateUrl: './traffic-profile.component.html',
  styleUrls: ['./traffic-profile.component.css']
})
export class TrafficProfileComponent implements OnInit, OnDestroy {
  private languageSubscription: Subscription;

  public trafficProfiles: TrafficProfile[] = [];
  public trafficProfilesList: unknown;
  public loading = true;
  public countItems = 100;
  public tableIsVisible = true;
  public trafficProfileSelected = false;
  public isModalChecked = false;
  public selectedTrafficProfileData: TrafficProfile | null = null;
  public trafficProfileName = '';
  public trafficWarningNational = '';
  public trafficLimitNational = '';
  public trafficWarningInternational = '';
  public trafficLimitInternational = '';
  public cols!: CustomTableColumn[];
  public prepereDeleteTrafficProfile!: TrafficProfile;
  public showDeleteModal = false;
  public sortDirection: number | null | undefined;
  public searchKey = '';
  public filterList: FilterElement[] = [];
  public mapKeys: MapKeys = {
    name: 'name'
  };

  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService, private cardService: CardService,
    private trafficProfileService: TrafficProfileService, private errorService: ErrorHandlerService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }

    this.cols = [
      { field: 'name', checked: true },
      { field: 'warningNational', checked: true },
      { field: 'limitNational', checked: true },
      { field: 'warningInternational', checked: true },
      { field: 'limitInternational', checked: true },
    ];

    this.filterList = [
      { field: 'name', type: 'input', checked: false, value: "" }
    ];
  }

  ngOnInit() {
    console.log('initialized');   
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  exportAsCSV() {
    this.trafficProfileService.getTrafficProfileCSV(this.sessionService.getJWTToken()).subscribe({
      next: (response) => {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'trafficprofile_export.csv';
        hiddenElement.click();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  listAllTrafficProfiles() {
    this.trafficProfileService.getTrafficProfileList(this.sessionService.getJWTToken(), this.mapKeys[this.searchKey as keyof MapKeys], this.sortDirection, this.filterList).subscribe({
      next: (data: TrafficProfileList) => {
        this.trafficProfilesList = data;
        //console.log(this.trafficProfilesList);
        this.trafficProfiles = data['hydra:member'];
        this.countItems = (data["hydra:totalItems"])?data["hydra:totalItems"]:0;
        this.loading = false;
      }, 
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });

    //console.log(this.trafficProfiles);
  }

  loadDataLazy(event: TableLazyLoadEvent): void {
    if (event && event.sortField && event.sortField && typeof event.sortField === 'string') {
      this.searchKey = event.sortField;
    }

    this.sortDirection = event.sortOrder;

    this.listAllTrafficProfiles();
  }

  changeNumberOfRecords() {
    this.tableIsVisible = false;

    setTimeout(() => {
      this.tableIsVisible = true;
    }, 50);
  }

  prepareDeleteTrafficProfile(trafficProfile: TrafficProfile) {
    this.prepereDeleteTrafficProfile = trafficProfile;
    this.showDeleteModal = true;
  }

  deleteSelectedTrafficProfile(trafficProfile: TrafficProfile): void {
    this.trafficProfileService.deleteTrafficProfile(this.sessionService.getJWTToken(), trafficProfile.uuid).subscribe({
      next: (response) => {
        //console.log(response);
        this.listAllTrafficProfiles();

        successMessage(this.translate.instant("traffic-profile.traffic-deleted"));
        this.showDeleteModal = false;
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  editSelectedTrafficProfile(trafficProfile: TrafficProfile) {
      this.selectedTrafficProfileData = trafficProfile;

      this.trafficProfileName = (trafficProfile.name)? trafficProfile.name : '';
      this.trafficWarningNational = (trafficProfile.warningNational)? trafficProfile.warningNational.toString() : '';
      this.trafficWarningInternational = (trafficProfile.warningInternational)? trafficProfile.warningInternational.toString() : '';
      this.trafficLimitNational = (trafficProfile.limitNational)? trafficProfile.limitNational.toString() : '';
      this.trafficLimitInternational = (trafficProfile.limitInternational)? trafficProfile.limitInternational.toString() : '';
  }

  showTrafficProfileModal() {
    this.errorService.deleteFieldError();
    this.selectedTrafficProfileData = null;
    this.trafficProfileSelected = false;

    this.trafficProfileName = '';
    this.trafficWarningNational = '';
    this.trafficWarningInternational = '';
    this.trafficLimitNational = '';
    this.trafficLimitInternational = '';
  }

  hideDetailsBlock() {
    this.selectedTrafficProfileData = null;
    this.trafficProfileSelected = false;
  }

  createTrafficProfile() {
    this.trafficProfileService.createNewTrafficProfile(this.sessionService.getJWTToken(), 
    {
      "name": this.trafficProfileName,
      "warningNational": (this.trafficWarningNational)?  this.trafficWarningNational : null,
      "limitNational": (this.trafficLimitNational)? this.trafficLimitNational : null,
      "warningInternational": (this.trafficWarningInternational)? this.trafficWarningInternational : null,
      "limitInternational": (this.trafficLimitInternational)? this.trafficLimitInternational : null
    }).subscribe({
      next: (response: TrafficProfile) => {
        this.isModalChecked = false;

        this.trafficProfileName = '';
        this.trafficWarningNational = '';
        this.trafficWarningInternational = '';
        this.trafficLimitNational = '';
        this.trafficLimitInternational = '';

        this.listAllTrafficProfiles();

        successMessage(this.translate.instant("traffic-profile.add-traffic"));
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  updateTrafficProfile() {
    this.trafficProfileService.updateTrafficProfile(this.sessionService.getJWTToken(), this.selectedTrafficProfileData?.uuid, 
    {
      "name": this.trafficProfileName,
      "warningNational": (this.trafficWarningNational)?  this.trafficWarningNational : null,
      "limitNational": (this.trafficLimitNational)? this.trafficLimitNational : null,
      "warningInternational": (this.trafficWarningInternational)? this.trafficWarningInternational : null,
      "limitInternational": (this.trafficLimitInternational)? this.trafficLimitInternational : null
    }).subscribe({
      next: (response: TrafficProfile) => {
        this.isModalChecked = false;

        this.trafficProfileName = '';
        this.trafficWarningNational = '';
        this.trafficWarningInternational = '';
        this.trafficLimitNational = '';
        this.trafficLimitInternational = '';

        this.selectedTrafficProfileData = null;

        this.listAllTrafficProfiles();

        successMessage(this.translate.instant("traffic-profile.edit-traffic"));
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  executeUpdateAction() {
    this.errorService.deleteFieldError();
    if (this.selectedTrafficProfileData == null) {
      this.createTrafficProfile();
    } else {
      this.updateTrafficProfile();
    }
  }

  changeFilter() {
    this.listAllTrafficProfiles();
  }

  filterClose(filter: FilterElement) {
    filter.checked = false;
    this.changeFilter();
  }
}
