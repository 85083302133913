import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
//import { LazyLoadEvent } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CostCenterService } from './../../services/cost-center.service';
import { TrafficProfileService } from './../../services/traffic-profile.service'
import { CostCenter } from 'src/app/interfaces/cost-center';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { CustomTableColumn } from 'src/app/interfaces/custom-table-column';
import { BanService } from 'src/app/services/ban.service';
import { Ban, BanList } from 'src/app/interfaces/ban';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterElement } from 'src/app/interfaces/filter-element';
import { CostCenterList } from 'src/app/interfaces/cost-center-list';
import { TrafficProfile } from 'src/app/interfaces/traffic-profile';
import { TrafficProfileList } from 'src/app/interfaces/traffic-profile-list';

declare const successMessage: FunctionStringCallback;

@Component({
  selector: 'app-vodafone-ban',
  templateUrl: './vodafone-ban.component.html',
  styleUrls: ['./vodafone-ban.component.css']
})
export class VodafoneBanComponent implements OnInit, OnDestroy {
  private languageSubscription: Subscription;

  public loading = true;
  public countItems = 0;
  public tableIsVisible = true;
  public isModalChecked = false;
  public cols!: CustomTableColumn[];
  public pageNumber = 1;
  public itemsPerPage = 10;
  public showPaginator = true;
  public sortDirection: number | null | undefined;
  public searchKey = '';
  public filterList: FilterElement[] = [];
  public banFormData: Ban = {};
  public editExistingBanId = "";
  public banList: Ban[] = [];
  public costCenters: CostCenter[] = [];
  public allCostCenters: CostCenter[] = [];
  public trafficProfiles: TrafficProfile[] = [];
  public ban = "";
  public description = "";
  public defaultCostCenter = "";
  public defaultTrafficProfile = "";

  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService, private costCenterService: CostCenterService, private trafficProfileService: TrafficProfileService, private banService: BanService,
    private errorService: ErrorHandlerService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }

    this.cols = [
      { field: 'ban', checked: true },
      { field: 'description', checked: true },
      { field: 'defaultCostCenterName', checked: true },
      { field: 'defaultTrafficProfileName', checked: true }
    ];

    this.filterList = [
      { field: 'ban', type: 'input', checked: false, value: "" }
    ];

    this.listAllCostCenters();
    this.listAllTrafficProfiles();
  }

  ngOnInit() {
    console.log('initialized');
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  listAllBans() {
    this.loading = true;

    this.banService.getBanList(this.sessionService.getJWTToken(), this.pageNumber, this.itemsPerPage, this.searchKey, this.sortDirection, this.filterList).subscribe({
      next: (response: BanList) => {
        this.banList = response['hydra:member'];
        this.countItems = (response["hydra:totalItems"]) ? response["hydra:totalItems"] : 0;

        for (const ban of this.banList) {
          const costCenter = this.allCostCenters.find(costCenter => costCenter['@id'] === ban.defaultCostCenter);
          if (costCenter) {
            ban.defaultCostCenterName = costCenter.name;
          }

          const trafficProfile = this.trafficProfiles.find(trafficProfile => trafficProfile['@id'] === ban.defaultTrafficProfile);
          if (trafficProfile) {
            ban.defaultTrafficProfileName = trafficProfile.name;
          }
        }

        this.loading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  loadDataLazy(event: TableLazyLoadEvent): void {
    if (event && event.sortField && event.sortField && typeof event.sortField === 'string') {
      this.searchKey = event.sortField;
    }

    this.sortDirection = event.sortOrder;

    this.pageNumber = (event.first) ? (event.first / +this.itemsPerPage) + 1 : 1;

    this.listAllBans();
  }


  editBan(ban: Ban) {
    this.errorService.deleteFieldError();
    this.isModalChecked = true;
    this.getBanFormData(ban);
  }

  updateBan() {
    this.errorService.deleteFieldError();
    this.generateUserFormData();

    this.banService.updateBan(this.sessionService.getJWTToken(), this.banFormData, this.editExistingBanId).subscribe({
      next: (response: Ban) => {
        successMessage(this.translate.instant("user.updated-user"));
        this.isModalChecked = false;
        this.getBanFormData({});
        this.reloadPage();
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  generateUserFormData() {
    this.banFormData = {
      description: this.description
    }

    if (this.defaultCostCenter) {
      this.banFormData.defaultCostCenter = this.defaultCostCenter;
    }

    if (this.defaultTrafficProfile) {
      this.banFormData.defaultTrafficProfile = this.defaultTrafficProfile;
    }
  }

  getBanFormData(ban: Ban) {
    this.editExistingBanId = (ban['@id']) ? ban['@id'] : '';
    this.ban = (ban.ban) ? ban.ban : '';
    this.description = (ban.description) ? ban.description : '';
    this.defaultCostCenter = (ban.defaultCostCenter) ? ban.defaultCostCenter : '';
    this.defaultTrafficProfile = (ban.defaultTrafficProfile) ? ban.defaultTrafficProfile : '';

    this.costCenters = [];
    for (const CostCenter of this.allCostCenters) {
      if (CostCenter.ban === ban['@id']) {
        this.costCenters.push(CostCenter);
      }
    }
  }

  executeUpdateAction() {
    this.generateUserFormData();
    this.updateBan();
  }

  changeNumberOfRecords() {
    this.tableIsVisible = false;

    setTimeout(() => {
      this.tableIsVisible = true;
    }, 50);
  }

  reloadPage() {
    this.listAllBans();
  }

  changeFilter() {
    this.listAllBans();
  }

  filterClose(filter: FilterElement) {
    filter.checked = false;
    this.changeFilter();
  }

  listAllCostCenters() {
    this.costCenterService.getCostCenterList(this.sessionService.getJWTToken(), 'name', 1, null).subscribe({
      next: (data: CostCenterList) => {
        this.allCostCenters = data['hydra:member'];
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  listAllTrafficProfiles() {
    this.trafficProfileService.getTrafficProfileList(this.sessionService.getJWTToken(), 'name', 1, null).subscribe({
      next: (data: TrafficProfileList) => {
        this.trafficProfiles = data['hydra:member'];
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

}
