import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { ReportService } from 'src/app/services/report.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReportsOptions } from 'src/app/interfaces/reports-options';
import { DropdownFilterEvent } from 'primeng/dropdown';
import { CostCenterService } from './../../services/cost-center.service';
import { CostCenterList } from 'src/app/interfaces/cost-center-list';
import { CardService } from 'src/app/services/card.service';
import { SimCardsGroups } from 'src/app/interfaces/simcardsgroups';
import { SimCardsList } from 'src/app/interfaces/simcardslist';
import { SimcardData } from 'src/app/interfaces/simcard-data';
import { FilterElement } from 'src/app/interfaces/filter-element';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { ReportData } from 'src/app/interfaces/report-data';
import { ReportDataList } from 'src/app/interfaces/report-data-list';
import { HttpErrorResponse } from '@angular/common/http';
import { CostCenter } from 'interfaces/costcenter';
import { SimcardGroupData } from 'src/app/interfaces/simcard-group-data';

declare const successMessage: FunctionStringCallback;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit, OnDestroy {
  private languageSubscription: Subscription;
  public report = "";
  public dateRangeType = "";
  public dateFrom = "";
  public dateTo = "";
  public costCenter = '';
  public simcardGroup = '';
  public simcard: ReportsOptions = {};
  public name = "";
  public selectedTemplate: ReportData = {};
  public selectedScheduler: ReportData = {};
  public costCenters: CostCenter[] = [];
  public simGroups: SimcardGroupData[] = [];
  public simCards: ReportsOptions[] = [];
  public reportFormData: ReportData = {};
  public templateList: ReportData[] = [];
  public SchedulerList: ReportData[] = [];
  public loadedTemplate: ReportData = {};
  public loadedScheduler: ReportData = {};
  public showTemplateList = true;
  public showSchedulerList = true;
  public reportRun = false;
  public schedulerPageNumber = 1;
  public templatesPageNumber = 1;
  public templatesItemsPerPage = 100;
  public templatesSortBy = 'name';
  public byCostCenter = false;
  public bySimcardGroup = false;
  public bySimcard = false;
  public prepereDeleteTemplate: ReportData = {};
  public prepereDeleteScheduler: ReportData = {};
  public showDeleteModal = false;
  public showDeleteSchedulerModal = false;
  public schedule = false;
  public schedulerName = '';
  public cronExpression = '';
  public cronOptions = '';
  public cronMin = '0';
  public cronHouer = '0';
  public cronDay = '';
  public cronWeekDay: string[] = [];
  public cronWeekDays = ['MON','TUE','WED','THU','FRI','SAT','SUN'];
  public cronExpressionValid = true;
  public notify = false;
  public notifyByEmail: string[] = [];
  public notifyByEmailField = '';


  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService, private reportService: ReportService, private cardService: CardService, private errorService: ErrorHandlerService,  private costCenterService: CostCenterService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    //errorMessage('Test Error Message!');

    this.listAllCostCenters();
    this.listAllSimCardGroups();
    this.getSimcardList();

    this.getTemplates();
    this.getSchedulers();
  }

  ngOnInit() {
    console.log('initialized');
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  listAllCostCenters() {
    this.costCenterService.getCostCenterList(this.sessionService.getJWTToken()).subscribe({
      next: (data: CostCenterList) => {
        this.costCenters = data['hydra:member']
      }, 
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  listAllSimCardGroups() {
    this.cardService.getSimcardGroupsList(this.sessionService.getJWTToken()).subscribe({
      next: (data: SimCardsGroups) => {
        this.simGroups = data['hydra:member'];
      }, 
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  getSimcardList(filter: FilterElement[] | null = null) {
    this.cardService.getSimcardList(this.sessionService.getJWTToken(), 1, 50, null, null, filter, true, true).subscribe({
      next: (data: SimCardsList) => {
        const simCardsLocal = data['hydra:member'];
        for (const simCardLocal of simCardsLocal) {
          if(this.simCards.findIndex((item: ReportsOptions) => item.value === simCardLocal['@id']) === -1){
            this.simCards.push({value: simCardLocal['@id'], label: simCardLocal.msisdn + ' ' + simCardLocal.name});
          }
        }
      }, 
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  filterSimcards(event: DropdownFilterEvent) {
    //console.log(event.filter);
    const filterMsisdn = [{ field: 'msisdn', type: 'input', checked: true, value: event.filter }];
    const filterName = [{ field: 'name', type: 'input', checked: true, value: event.filter }];
    this.getSimcardList(filterMsisdn);
    this.getSimcardList(filterName);
  }

  generateReport() {
    this.generateReportFormData(false);

    this.reportService.createNewReport(this.sessionService.getJWTToken(), this.reportFormData).subscribe({
      next: (response: ReportData) => {
        successMessage(this.translate.instant("report.generate-report"));
        this.cancelTemplate();
        this.reportRun = true;

        this.getSchedulers();

        //console.log(response); 
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  loadThisTemplate() {
    this.cancelSchaduler();

    this.reportService.getReport(this.sessionService.getJWTToken(), this.selectedTemplate.uuid).subscribe({
      next: (response: ReportData) => {
        this.loadedTemplate = response;
        this.reportRun = false;
        this.loadFormData();
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  loadThisScheduler() {
    this.cancelTemplate();
    
    this.reportService.getReport(this.sessionService.getJWTToken(), this.selectedScheduler.uuid).subscribe({
      next: (response: ReportData) => {
        this.loadedScheduler = response;
        this.reportRun = false;
        this.loadFormData(this.loadedScheduler);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  loadFormData(loadedData: ReportData = this.loadedTemplate) {
    this.report = (loadedData.parameters?.type)?loadedData.parameters.type:'';
    this.dateRangeType = (loadedData.parameters?.dateRangeType)?loadedData.parameters.dateRangeType:'';
    this.dateFrom = (loadedData.parameters?.dateFrom)?loadedData.parameters.dateFrom:'';
    this.dateTo = (loadedData.parameters?.dateTo)?loadedData.parameters.dateTo:'';

    this.costCenter = (loadedData.parameters?.costCenter)?loadedData.parameters?.costCenter:'';
    this.byCostCenter = (loadedData.parameters?.costCenter)?true:false;

    this.simcardGroup = (loadedData.parameters?.simcardGroup)?loadedData.parameters?.simcardGroup:'';
    this.bySimcardGroup = (loadedData.parameters?.simcardGroup)?true:false;

    if(loadedData.schedule) {
      this.schedule = true;
      this.schedulerName = (loadedData.name)?loadedData.name:'';
      this.cronExpression = (loadedData.schedule.cronExpression)?loadedData.schedule.cronExpression:'';
    } else {
      this.name = (loadedData.name)?loadedData.name:'';
      this.schedule = false;
      this.cronExpression = '';
    }

    this.buildDataFromCronExpression();

    if(loadedData.parameters?.simcard) {
      this.cardService.getSimcardDetails(this.sessionService.getJWTToken(), loadedData.parameters?.simcard).subscribe((data: SimcardData) => {
        
        // add loaded simcard to list
        if(this.simCards.findIndex((simcard: ReportsOptions) => simcard.value === data['@id']) === -1){
          this.simCards.push({value: data['@id'], label: data.msisdn + ' ' + data.name});
        }

        this.simcard = {label: data.msisdn + ' ' + data.name, value: data['@id']};
        this.bySimcard = true;
      });
    } else {
      this.simcard = {};
      this.bySimcard = false;
    }

    if(loadedData.notifyByEmail) {
      this.notifyByEmail = loadedData.notifyByEmail;
      this.notify = true;
    } else {
      this.notifyByEmail = [];
      this.notify = false;
    }
  }

  cancelTemplate() {
    this.loadedTemplate = {};
    this.selectedTemplate = {};
    this.loadFormData();
  }

  cancelSchaduler() {
    this.loadedScheduler = {};
    this.selectedScheduler = {};
    this.loadFormData();
  }

  getTemplates() {
    this.showTemplateList = false;
    this.templateList = [];

    this.templatesPageNumber = 1;
    this.getTemplatesFromAllPages();
  }

  getTemplatesFromAllPages() {
    this.reportService.getReports(this.sessionService.getJWTToken(), this.templatesPageNumber, this.templatesItemsPerPage, this.templatesSortBy, 1, null, true, false).subscribe({
      next: (response: ReportDataList) => {

        this.templateList.push(...response['hydra:member']);

        if(response['hydra:totalItems'] && this.templateList.length < response['hydra:totalItems']) {
          this.templatesPageNumber++;
          this.getTemplatesFromAllPages();
        } else {
          if(this.loadedTemplate.name !== undefined) {
            const selectedTemplate = this.templateList.find(template  => template['@id'] === this.loadedTemplate['@id']);
            this.selectedTemplate = (selectedTemplate)?selectedTemplate:{};
          }
    
          this.showTemplateList = true;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  getSchedulers() {
    this.showSchedulerList = false;
    this.SchedulerList = [];

    this.schedulerPageNumber = 1;
    this.getSchedulersFromAllPages();
  }

  getSchedulersFromAllPages() {
    this.reportService.getReports(this.sessionService.getJWTToken(), this.schedulerPageNumber, this.templatesItemsPerPage, this.templatesSortBy, 1, null, true, true).subscribe({
      next: (response: ReportDataList) => {

        this.SchedulerList.push(...response['hydra:member']);

        if(response['hydra:totalItems'] && this.SchedulerList.length < response['hydra:totalItems']) {
          this.schedulerPageNumber++;
          this.getSchedulersFromAllPages();
        } else {
          if(this.loadedScheduler['@id'] !== undefined) {
            const selectedScheduler = this.SchedulerList.find(template  => template['@id'] === this.loadedScheduler['@id']);
            this.selectedScheduler = (selectedScheduler)?selectedScheduler:{};
          }

          this.showSchedulerList = true;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  generateReportFormData(isTemplate = false) {
    this.reportFormData = {
      isTemplate: isTemplate,
      name: this.name,
      parameters: {
        type: this.report,
        format: "csv",
        dateRangeType: this.dateRangeType,
      }
    }

    if(this.schedule) {
      this.reportFormData.isTemplate = true;
      this.reportFormData.name = this.schedulerName;
      this.reportFormData.schedule = {};
      this.reportFormData.schedule.enabled = true;
      this.buildConExpression();
      this.reportFormData.schedule.cronExpression = this.cronExpression;
    } else {
      this.reportFormData.schedule = null;
    }

    if(this.reportFormData.parameters) {
      if(this.dateRangeType === 'custom') {
        this.reportFormData.parameters.dateFrom = this.dateFrom;
        this.reportFormData.parameters.dateTo = this.dateTo;
      }

      this.reportFormData.parameters.costCenter = (this.costCenter)?this.costCenter:null;

      this.reportFormData.parameters.simcardGroup = (this.simcardGroup)?this.simcardGroup:null;

      this.reportFormData.parameters.simcard = (this.simcard.value)?this.simcard.value:null;
    }

    if(this.notify) {
      this.reportFormData.notifyByEmail = this.notifyByEmail;
    }
  }

  saveReportTemplate() {
    this.generateReportFormData(true);

    this.reportService.createNewReport(this.sessionService.getJWTToken(), this.reportFormData).subscribe({
      next: (response: ReportData) => {
        successMessage(this.translate.instant("report.add-report-template"));
        this.loadedTemplate = response;
        this.getTemplates();

        //console.log(response);
        //return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  updateReportTemplate() {
    this.generateReportFormData(true);

    this.reportService.updateReport(this.sessionService.getJWTToken(), this.reportFormData, this.selectedTemplate.uuid).subscribe({
      next: (response: ReportData) => {
        successMessage(this.translate.instant("report.update-report-template"));
        this.loadedTemplate = response;
        this.getTemplates();

        //console.log(response);
        //return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  updateReportScheduler() {
    this.generateReportFormData(true);

    this.reportService.updateReport(this.sessionService.getJWTToken(), this.reportFormData, this.selectedScheduler.uuid).subscribe({
      next: (response: ReportData) => {
        successMessage(this.translate.instant("report.update-report-scheduler"));
        this.loadedScheduler = response;
        this.getSchedulers();

        //console.log(response);
        //return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  deleteReportTemplate() {
    console.log('delete');
  }

  prepareDeleteTemplate() {
    this.showDeleteModal = true;
  }

  prepareDeleteScheduler() {
    this.showDeleteSchedulerModal = true;
  }

  deleteSelectedScheduler() {
    this.reportService.deleteReport(this.sessionService.getJWTToken(), this.loadedScheduler?.['@id']).subscribe({
      next: (response: ReportData) => {
        //console.log(response);
        this.getSchedulers();
        this.cancelSchaduler();

        successMessage(this.translate.instant("report.report-deleted-scheduler"));
        this.showDeleteSchedulerModal = false;
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  deleteSelectedTemplate() {
    this.reportService.deleteReport(this.sessionService.getJWTToken(), this.loadedTemplate?.['@id']).subscribe({
      next: (response: ReportData) => {
        //console.log(response);
        this.getTemplates();
        this.cancelTemplate();

        successMessage(this.translate.instant("report.report-deleted"));
        this.showDeleteModal = false;
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  buildConExpression() {
    let cronDay = '*';
    if(this.cronDay) {
      cronDay = this.cronDay;
    }

    let cronWeekDay = '*';
    if (this.cronWeekDay.length > 0) {
      cronWeekDay = this.cronWeekDay.toString();
    }

    const cronExpression = this.cronMin + ' ' + this.cronHouer + ' ' + cronDay + ' * ' + cronWeekDay;

    if(this.cronExpressionValid) {
      this.cronExpression = cronExpression;
    }
  }

  buildDataFromCronExpression() {
    this.cronExpressionValid = true;

    if(this.cronExpression) {
      //console.log(this.cronExpression);
      const cronExpressionData = this.cronExpression.split(" ");

      this.cronMin = cronExpressionData[0];
      this.cronHouer = cronExpressionData[1];

      if(cronExpressionData[2] === '*') {
        this.cronDay = '';
        this.cronOptions = 'daily';
      } else if(!cronExpressionData[2].includes('/')) {
        this.cronOptions = 'monthly';
        this.cronDay = cronExpressionData[2];
      } else {
        this.cronDay = '';
        this.cronExpressionValid = false;
      }

      if(cronExpressionData[4] != '*') {
        this.cronOptions = 'weekly';
        this.cronWeekDay = cronExpressionData[4].split(',');
      } else {
        this.cronWeekDay = [];
      }

    } else {
      this.cronMin = '0';
      this.cronHouer = '0';
      this.cronWeekDay = [];
      this.cronDay = '';
      this.cronOptions = '';
    }
  }

  addToEmailList() {
    const emailValidRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (this.notifyByEmailField !== '' && this.notifyByEmailField.match(emailValidRegex)) {
      this.notifyByEmail.push(this.notifyByEmailField);
      this.notifyByEmailField = '';
    }
  }

  removeSelectedEmail(simIndex: number) {
    this.notifyByEmail.splice(simIndex, 1);
  }
}
