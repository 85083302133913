<div class="container">
    <div class="login-row">
        <div class="login-logo">
            <img src="assets/images/db-logo.png" class="db-logo" alt="DB Logo" />
        </div>
        <div class="login-form" *ngIf="!is2FaRequired;else show2FADevices">
            <form name="firmSubmitForm" ngForm #f="ngForm">
                <h2>{{ 'login.login-title' | translate }}</h2>

                <div class="mb-3">
                    <label for="email" class="form-label ">{{ 'login.username' | translate }}</label>
                    <input type="email" class="form-control" id="email" name="email"
                        placeholder="{{ 'login.placeholder-user' | translate }}" value="" required minlength="3"
                        maxlength="255" [(ngModel)]="username" #usernameRef="ngModel" tabindex="1">
                </div>
                <div class="mb-3">
                    <label for="password" class="form-label ">{{ 'login.password' | translate }}</label>
                    <input type="password" class="form-control" id="password" name="password"
                        placeholder="{{ 'login.placeholder-password' | translate }}" value="" required minlength="3"
                        maxlength="255" [(ngModel)]="password" #passwordRef="ngModel" tabindex="2">
                </div>
                <div class="mb-3">
                    <label for="language" class="form-label ">{{ 'login.language' | translate }}</label>
                    <select id="language" name="language" class="form-control" [(ngModel)]="languageForm"
                        #languageFormRef="ngModel" required (change)="onLanguageChange();">
                        <option value="de">{{ 'login.label-lang-de' | translate }}</option>
                        <option value="en">{{ 'login.label-lang-en' | translate }}</option>
                    </select>
                </div>
                <!--
                <p class="small">
                    <a class="text-primary" href="#">{{ 'login.forget-password' | translate }}</a>
                </p>
                -->
                <div class="d-grid">
                    <button type="submit" (click)="executeLogin();" [disabled]="!f.valid">{{ 'login.login-button' |
                        translate }}</button>
                </div>
            </form>
        </div>
        <ng-template #show2FADevices>
            <div class="login-form" >
                <form name="firmSubmitForm" ngForm #f="ngForm">
                    <h2>{{ 'login.login-title' | translate }}</h2>
                    <div *ngIf="!is2FaSetupRequired">
                        <div *ngIf="listOfTotpDevices !== undefined && listOfTotpDevices.length > 1" class="devices-buttons">
                            <div class="devices-desc">{{'login.devices-desc' | translate}}</div>
                            <div *ngFor="let totpDevice of listOfTotpDevices; let i = index; trackBy:deviceIdentifier" class="device-button">
                                <input type="radio" id="id-{{totpDevice.uuid}}" name="device" (change)="selectCurrentDevice(totpDevice);" >
                                <label for="id-{{totpDevice.uuid}}">
                                    {{totpDevice.name}}
                                </label>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="barCodeNumber" class="form-label"> {{'profile.generated-pin' | translate}}</label>
                            <input id="barCodeNumber" class="form-control" maxlength="255" name="barCodeNumber" required type="text" value="" [(ngModel)]="barCodeNumber" #barCodeNumberRef="ngModel">
                            <div class="required_error_message" *ngIf="!barCodeNumberRef.valid && barCodeNumberRef.touched">{{'profile.2fa-app-valid-pin' | translate}}</div>
                        </div>

                        <button class="button edit" type="submit" (click)="sendAuthCode();">{{ 'login.login-button' | translate }}</button>
                    </div>

                    <div *ngIf="is2FaSetupRequired">
                        <p>
                            {{'login.totp-required' | translate}}<br>
                            {{'login.totp-required-2' | translate}}
                        </p>
                        <button class="button edit" (click)="executeDeviceModal();"><span class="add-plus">+</span>
                                {{'profile.adddevice' | translate}}</button>
                    </div>
                </form>
            </div>
        </ng-template>
    </div>
</div>
