import { CustomFieldsList } from './../../interfaces/custom-fields-config';
import { Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CardService } from 'src/app/services/card.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { CustomFieldsConfig } from 'src/app/interfaces/custom-fields-config';
import { HttpErrorResponse } from '@angular/common/http';

declare const successMessage: FunctionStringCallback;

@Component({
  selector: 'app-custom-data-fields',
  templateUrl: './custom-data-fields.component.html',
  styleUrls: ['./custom-data-fields.component.css']
})
export class CustomDataFieldsComponent implements OnInit, OnDestroy, AfterContentChecked {
  private languageSubscription: Subscription;

  public dataField1 = '';
  public dataField2 = '';
  public dataField3 = '';
  public dataField4 = '';
  public dataField5 = '';

  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService,
    private cardService: CardService,
    private errorService: ErrorHandlerService,
    private changeDetector: ChangeDetectorRef) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }

    this.getCustomFieldsData();
  }

  ngOnInit() {
    console.log('initialized');
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log(event);
  }

  getCustomFieldsData() {
    this.cardService.getCustomSimCardFields(this.sessionService.getJWTToken()).subscribe({
      next: (response: CustomFieldsConfig) => {
        this.setCustomFields(response.simcardCustomAttributes);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  executeUpdateAction() {
    this.errorService.deleteFieldError();
    this.cardService.updateCustomSimCardFields(this.sessionService.getJWTToken(), {
      simcardCustomAttributes: {
        customAttribute1Title: this.dataField1,
        customAttribute2Title: this.dataField2,
        customAttribute3Title: this.dataField3,
        customAttribute4Title: this.dataField4,
        customAttribute5Title: this.dataField5
      }
    }).subscribe({
      next: (response: CustomFieldsConfig) => {
        this.setCustomFields(response.simcardCustomAttributes);
        successMessage(this.translate.instant('custom-fields.update-successfully-message'));
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  setCustomFields(customFields: CustomFieldsList) {
    this.dataField1 = customFields.customAttribute1Title;
    this.dataField2 = customFields.customAttribute2Title;
    this.dataField3 = customFields.customAttribute3Title;
    this.dataField4 = customFields.customAttribute4Title;
    this.dataField5 = customFields.customAttribute5Title;
  }
}
