<header>
  <div class="header-menu">
    <div class="container">
      <div class="logo">
        <img alt="Logo" src="assets/images/logo.svg" />
      </div>

      <div class="main-menu" *ngIf="isUserLogged">
        <div class="navbar-button">
          <img alt="Logo" src="assets/images/menu.svg" />
        </div>
        <nav>
          <ul class="nav-ul">
            <li class="li-item" routerLinkActive="active">
              <a class="menu-link" routerLink="/dashboard">{{'header.menu-item-dashboard' | translate}}</a>
            </li>
            <li class="li-item" routerLinkActive="active">
              <a class="menu-link" routerLink="/simcards"
                (click)="reloadPageIfSame($event)">{{'header.menu-item-simcards' | translate}}</a>
            </li>
            <li class="li-item" routerLinkActive="active">
              <a class="menu-link" routerLink="/report/list">{{'header.menu-item-report' | translate}}</a>
            </li>
            <li class="li-item" routerLinkActive="active">
              <a class="menu-link no-link" href="javascript:void(0)">{{'header.menu-item-setup' | translate}}</a>

              <ul class="submenu">
                <li class="li-item" routerLinkActive="active" *ngIf="effectivePermissions && (effectivePermissions.includes('admin') || effectivePermissions.includes('manage_cost_centers'))">
                  <a class="menu-link" routerLink="/setup/cost-center">{{'header.menu-item-setup-cost-center' |
                    translate}}</a>
                </li>
                <li class="li-item" routerLinkActive="active" *ngIf="effectivePermissions && (effectivePermissions.includes('admin') || effectivePermissions.includes('manage_simcard_groups'))">
                  <a class="menu-link" routerLink="/setup/sim-groups">{{'header.menu-item-setup-sim-groups' |
                    translate}}</a>
                </li>
                <li class="li-item" routerLinkActive="active" *ngIf="effectivePermissions && (effectivePermissions.includes('admin') || effectivePermissions.includes('manage_traffic_profiles'))">
                  <a class="menu-link" routerLink="/setup/profile">{{'header.menu-item-setup-profile' |
                    translate}}</a>
                </li>
                <li class="li-item" routerLinkActive="active" *ngIf="effectivePermissions && effectivePermissions.includes('admin')">
                  <a class="menu-link" routerLink="/setup/user">{{'header.menu-item-setup-user' | translate}}</a>
                </li>
                <li class="li-item" routerLinkActive="active" *ngIf="effectivePermissions && effectivePermissions.includes('admin')">
                  <a class="menu-link" routerLink="/setup/user-groups">{{'header.menu-item-setup-user-groups' |
                    translate}}</a>
                </li>
                <li class="li-item" routerLinkActive="active" *ngIf="effectivePermissions && (effectivePermissions.includes('admin') || effectivePermissions.includes('manage_cost_centers'))">
                  <a class="menu-link" routerLink="/setup/vodafone-ban">{{'header.menu-item-setup-vodafone-ban' |
                    translate}}</a>
                </li>
                <li class="li-item" routerLinkActive="active">
                  <a class="menu-link" routerLink="/setup/simcards-custom-fields">{{'header.simcards-custom-fields'
                    | translate}}</a>
                </li>
                <li class="li-item" routerLinkActive="active">
                  <a class="menu-link" routerLink="/simcard/activate">{{'header.menu-item-setup-card-activation'
                    | translate}}</a>
                </li>
                <li class="li-item" routerLinkActive="active">
                  <a class="menu-link" routerLink="/simcard/order">{{'header.menu-item-setup-cards-order' |
                    translate}}</a>
                </li>
              </ul>
            </li>
            <li class="li-item" routerLinkActive="active">
              <a class="menu-link no-link" href="javascript:void(0)">{{'header.menu-item-logs' | translate}}</a>

              <ul class="submenu">
                <li class="li-item" routerLinkActive="active">
                  <a class="menu-link" routerLink="/logs/job-hitory">{{'header.menu-item-logs-job-history' |
                    translate}}</a>
                </li>
                <!--
                <li class="li-item" routerLinkActive="active">
                  <a class="menu-link" routerLink="#">{{'header.menu-item-logs-audit-log' | translate}}</a>
                </li>
                -->
              </ul>
            </li>
          </ul>
        </nav>
      </div>

      <div class="user-icon" *ngIf="isUserLogged">
        <div class="user-profile-modal">
          <div class="username">{{qualifiedUsername}}</div>
          <a href="account/" class="account item">{{'header.profile-account' | translate}}</a>
          <a href="#" class="logout item" (click)="logout($event)">{{'header.profile-logout' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- form add Device Modal -->
<div *ngIf="addDevices" class="modal2fa account">
  <div class="modal2fa-content">
    <button class="close-modal" (click)="addDevices= false;"><img src="assets/images/close.svg"
        alt="close-icon" /></button>

    <h3 class="modal-title">{{'profile.adddevice' | translate}}</h3>

    <div class="block-with-background">
      <form name="userDataForm2fa" ngForm #userDataForm2fa="ngForm">
        <div class="div-2fa">
          <div class="item">
            <p>{{'profile.2fa-info' | translate}}</p>
            <p class="bold">
              Google Authenticator<br>
              Microsoft Authenticator<br>
              FreeOTP
            </p>
          </div>

          <div class="item" *ngIf="!isCodeDisplayed; else showCodeData">
            <p>{{'profile.2fa-app-qr' | translate}}</p>

            <div class="barcode text-align-center" *ngIf="totpSetupDevice !== undefined">
              <qrcode [qrdata]="totpSetupDevice.provisioningUri!" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
              <button type="button" class="qr-scan-link"
                (click)="isCodeDisplayed=true;">{{'profile.2fa-app-cant-scan'|translate}}</button>
            </div>
          </div>

          <ng-template #showCodeData>
            <div class="qr-code-alternative">
              <p>{{'profile.2fa-app-qr-code'|translate}}</p>
              <p class="secreet-qr-code">
                {{totpSetupDevice.totpSecret}}
              </p>
              <p>{{'profile.totpSecret-option' | translate}}</p>
              <p>
                {{'profile.2fa-app-type-based' | translate}}<br />
                {{'profile.2fa-app-algorithm' | translate}}<br />
                {{'profile.2fa-app-numbers' | translate}}<br />
                {{'profile.2fa-app-interval' | translate}}
              </p>
            </div>

            <p class="text-align-center">
              <button type="button" class="qr-scan-link" *ngIf="totpSetupDevice !== undefined"
                (click)="isCodeDisplayed=false;">{{'profile.2fa-app-qr-scan'|translate}}</button>
            </p>
          </ng-template>

          <div class="item">
            <label for="twoFAActivate" class="full-width">
              <p>{{'profile.2fa-app-password-i' | translate}}</p>
            </label>
          </div>

          <div class="item password form-item">
            <label for="barCodeNumber" class="field-title">
              {{'profile.2fa-app-generated-pin' | translate}}
            </label>
            <input id="barCodeNumber" maxlength="255" name="barCodeNumber" required type="text" value=""
              [(ngModel)]="barCodeNumber" #barCodeNumberRef="ngModel" class="authCode">
            <div class="required_error_message" *ngIf="!barCodeNumberRef.valid && barCodeNumberRef.touched">
              {{'profile.2fa-app-valid-pin' |
              translate}}</div>
          </div>

          <div class="item password form-item">
            <label for="device" class="field-title">
              {{'profile.devicename' | translate}}
            </label>
            <input id="device" maxlength="255" name="device" placeholder="" type="text" value="" [(ngModel)]="device"
              required #deviceRef="ngModel" class="name">
            <div class="required_error_message" *ngIf="!deviceRef.valid && deviceRef.touched">
              {{'profile.devicenameerror' | translate}}</div>
          </div>
        </div>
      </form>
    </div>
    <div class="button-center">
      <button type="button" class="gray-button button"
        (click)="addDevices = false; isCodeDisplayed= false;">{{"profile.cancel"|translate}}</button>
      <button type="submit" class="save button" (click)="saveToTpData()"
        [disabled]="!userDataForm2fa.valid || !userDataForm2fa.dirty">{{"profile.save"|translate}}</button>
    </div>
  </div>
</div>