import { SimcardGroupData } from 'src/app/interfaces/simcard-group-data';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';

import { BASE_URL } from 'src/environments/environment';
import { SimCardsList } from '../interfaces/simcardslist';
import { SimcardData } from '../interfaces/simcard-data';
import { SimcardUpdateRequest } from '../interfaces/simcard-update-request';
import { SimCardsGroups } from '../interfaces/simcardsgroups';
import { FilterElement } from '../interfaces/filter-element';
import { DataSessionsList } from '../interfaces/data-sessions-list';
import { DataTrafficList } from '../interfaces/data-traffic-list';
import { BatchList } from '../interfaces/batchlist';
import { BatchData } from '../interfaces/batch-data';
import { HistoryList } from '../interfaces/historylist';
import { CustomFieldsConfig, CustomFieldsUpdate } from '../interfaces/custom-fields-config';
import { SimcardsFilterData } from '../interfaces/simcards-filter-data';

const SERVICE_URL = "/simcards";
const SERVICE_GROUPS_URL = "/simcard_groups";

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(private http: HttpClient) {
  }

  public getSimcardList(token: string | null, page = 1, numberOfItems = 20, sortKey: string | null = null, sortDirection: number | undefined | null = 0, filterElements: FilterElement[] | null, isStatusIncluded = false, isTrafficStatus = false, defaultLanguage = 'de'): Observable<SimCardsList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('page', '' + page)
      .set('itemsPerPage', '' + numberOfItems);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }

    if (isStatusIncluded) {
      params = params.set('include[]', 'connectionStatus');
    }

    if (isTrafficStatus) {
      params = params.append('include[]', 'trafficStatus');
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    //return this.http.get<SimCardList>(this.serviceBaseUrl + '/simcard/', httpOptions).pipe(map((simCardList:SimCardList) => {
    return this.http.get<SimCardsList>(BASE_URL + '/api/v2' + SERVICE_URL, httpOptions).pipe(map((simCardList: SimCardsList) => {
        const dataResult = simCardList;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getBatchSimcardList(token: string | null, page = 1, numberOfItems = 20, nextPage: string | null | undefined = null, filterElements: FilterElement[] | null = null, defaultLanguage = 'de'): Observable<SimCardsList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('page', '' + page)
      .set('itemsPerPage', '' + numberOfItems);

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions: {
      headers?: HttpHeaders,
      params?: HttpParams
    } = {
      headers: httpHeaders,
    };

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }
  // && filterElements === null
    if (nextPage === null || nextPage === undefined) {
      httpOptions.params = params;
      //return this.http.get<SimCardList>(this.serviceBaseUrl + '/simcard/', httpOptions).pipe(map((simCardList:SimCardList) => {
      return this.http.get<SimCardsList>(BASE_URL + '/api/v2' + SERVICE_URL, httpOptions).pipe(map((simCardList: SimCardsList) => {
          const dataResult = simCardList;

          return dataResult;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        }));
    } else {
      return this.http.get<SimCardsList>(BASE_URL + nextPage, httpOptions).pipe(map((simCardList: SimCardsList) => {
          const dataResult = simCardList;

          return dataResult;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        }));
    }

  }

  public getSimcardsCSV(token: string | null, sortKey: string | null = null, sortDirection: number | undefined | null = 0, filterElements: FilterElement[] | null, isStatusIncluded = false, isTrafficStatus = false, defaultLanguage = 'de'): Observable<string> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams();

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }

    if (isStatusIncluded) {
      params = params.set('include[]', 'connectionStatus');
    }

    if (isTrafficStatus) {
      params = params.append('include[]', 'trafficStatus');
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'text/csv')
      .set('Accept', 'text/csv')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions: NonNullable<unknown> = {
      headers: httpHeaders,
      params: params,
      responseType: 'text' as const,
    };

    //return this.http.get<SimCardList>(this.serviceBaseUrl + '/simcard/', httpOptions).pipe(map((simCardList:SimCardList) => {
    return this.http.get<string>(BASE_URL + '/api/v2' + SERVICE_URL + '/csv', httpOptions).pipe(map((simCardList: string) => {
        const dataResult = simCardList;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getSimcardDetails(token: string | null, uuid: string, defaultLanguage = 'de'): Observable<SimcardData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const params = new HttpParams();

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<SimcardData>(BASE_URL + uuid, httpOptions).pipe(map((simCard: SimcardData) => {
        const dataResult = simCard;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  /*
  replaceExistingSimCard(token: string, uuid: string, simData: SimcardUpdateRequest, defaultLanguage = 'de'): Observable<SimcardData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.put<SimcardData>(BASE_URL + uuid, simData, httpOptions).pipe(map((simCardData: SimcardData) => {
      const dataResult = simCardData;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }
  */

  updateExistingSimCard(token: string | null, uuid: string | undefined, simData: SimcardUpdateRequest, defaultLanguage = 'de'): Observable<SimcardData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.patch<SimcardData>(BASE_URL + uuid, simData, httpOptions).pipe(map((simCardData: SimcardData) => {
        const dataResult = simCardData;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  createNewSimcardGroup(token: string | null, simData: SimcardGroupData, defaultLanguage = 'de'): Observable<SimcardGroupData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<SimcardGroupData>(BASE_URL + '/api/v2' + SERVICE_GROUPS_URL, simData, httpOptions).pipe(map((simCardData: SimcardGroupData) => {
        const dataResult = simCardData;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getSimcardGroupsList(token: string | null, sortKey: string | null = null, sortDirection: number | undefined | null = 0, filterElements: FilterElement[] | null = null, defaultLanguage = 'de'): Observable<SimCardsGroups> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('pagination', false);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<SimCardsGroups>(BASE_URL + '/api/v2' + SERVICE_GROUPS_URL, httpOptions).pipe(map((simcardGroupsList: SimCardsGroups) => {
        const dataResult = simcardGroupsList;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getSimcardGroupsCSV(token: string | null, defaultLanguage = 'de'): Observable<string> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const params = new HttpParams()
      .set('pagination', false);

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'text/csv')
      .set('Accept', 'text/csv')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions: NonNullable<unknown> = {
      headers: httpHeaders,
      params: params,
      responseType: 'text' as const
    };

    return this.http.get<string>(BASE_URL + '/api/v2' + SERVICE_GROUPS_URL, httpOptions).pipe(map((simcardGroupsList: string) => {
        const dataResult = simcardGroupsList;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getSimcardGroupDetails(token: string | null, uuid: string, defaultLanguage = 'de'): Observable<SimcardGroupData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const params = new HttpParams()
      .set('uuid', '' + uuid);

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<SimcardGroupData>(BASE_URL + uuid, httpOptions).pipe(map((simCardGroup: SimcardGroupData) => {
        const dataResult = simCardGroup;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  /*
  replaceExistingSimcardGroup(token: string, uuid: string, simData: SimcardUpdateRequest, defaultLanguage = 'de'): Observable<SimcardGroupData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.put<SimcardGroupData>(BASE_URL + uuid, simData, httpOptions).pipe(map((simCardGroupData: SimcardGroupData) => {
      const dataResult = simCardGroupData;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }
  */

  updateExistingSimcardGroup(token: string | null, uuid: string | undefined, simData: SimcardGroupData, defaultLanguage = 'de'): Observable<SimcardGroupData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.patch<SimcardGroupData>(BASE_URL + uuid, simData, httpOptions).pipe(map((simcardGroupData: SimcardGroupData) => {
        const dataResult = simcardGroupData;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  deleteSimcardGroup(token: string | null, uuid: string | undefined, defaultLanguage = 'de'): Observable<any> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/ld+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.delete<any>(BASE_URL + uuid, httpOptions).pipe(map((response: any) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getDataSessionsForASimCard(token: string | null, uuid: string | undefined, page = 1, numberOfItems = 20, sortKey: string | null = null, sortDirection: number | undefined | null = 0, defaultLanguage = 'de'): Observable<DataSessionsList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('page', '' + page)
      .set('itemsPerPage', '' + numberOfItems);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<any>(BASE_URL + '/api/v2/simcards/' + uuid + '/data_sessions', httpOptions).pipe(map((dataSessionsList: DataSessionsList) => {
        const dataResult = dataSessionsList;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getDataTrafficForASimCard(token: string | null, uuid: string | undefined, page = 1, numberOfItems = 20, sortKey: string | null = null, sortDirection: number | undefined | null = 0, defaultLanguage = 'de'): Observable<DataTrafficList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('page', '' + page)
      .set('itemsPerPage', '' + numberOfItems);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<any>(BASE_URL + '/api/v2/simcards/' + uuid + '/data_traffic', httpOptions).pipe(map((dataTrafficList: DataTrafficList) => {
        const dataResult = dataTrafficList;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  createBatchSimcardsActionSession(token: string | null, batchAction: string, roamingPolicy: string | null, defaultLanguage = 'de'): Observable<any> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    const batchOptions: any = {
      action: batchAction
    };

    if (roamingPolicy !== null && roamingPolicy !== "") {
      batchOptions["roamingPolicy"] = roamingPolicy;
    }

    return this.http.post<any>(BASE_URL + '/api/v2/batch_actions', batchOptions, httpOptions).pipe(map((response: any) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  createBatchSimcardsActivationActionSession(token: string | null, batchAction: string, roamingPolicy: string | null, costCenter: string | null | undefined, tariff: string | null | undefined, trafficProfile: string | null | undefined, defaultLanguage = 'de'): Observable<any> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    const batchOptions: any = {
      action: batchAction
    };

    if (roamingPolicy !== null && roamingPolicy !== "") {
      batchOptions["roamingPolicy"] = roamingPolicy;
    }

    batchOptions["costCenter"] = costCenter;
    batchOptions["tariff"] = tariff;
    batchOptions["trafficProfile"] = trafficProfile;

    return this.http.post<any>(BASE_URL + '/api/v2/batch_actions', batchOptions, httpOptions).pipe(map((response: any) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  addSimcardsToBatchSession(token: string | null, batchUUID: string | undefined, simcardsArray: string[], defaultLanguage = 'de'): Observable<any> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    const batchData: any = {
      simcards: simcardsArray
    };

    return this.http.post<any>(BASE_URL + '/api/v2/batch_actions/' + batchUUID + '/add_simcards', batchData, httpOptions).pipe(map((response: any) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  addSimcardsICCIDsToBatchSession(token: string | null, batchUUID: string | undefined, iccidList: any, defaultLanguage = 'de'): Observable<any> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    const batchData: any = {
      iccids: iccidList
    };

    return this.http.post<any>(BASE_URL + '/api/v2/batch_actions/' + batchUUID + '/add_iccids', batchData, httpOptions).pipe(map((response: any) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  submitSimcardsBatchSession(token: string | null, batchUUID: string | undefined, defaultLanguage = 'de'): Observable<any> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<any>(BASE_URL + '/api/v2/batch_actions/' + batchUUID + '/submit', {}, httpOptions).pipe(map((response: any) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  getBatchList(token: string | null, page = 1, numberOfItems = 20, sortKey: string | null | undefined = null, sortDirection: number | undefined | null = 0, filterElements: FilterElement[] | null, defaultLanguage = 'de'): Observable<BatchList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('page', '' + page)
      .set('itemsPerPage', '' + numberOfItems);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<BatchList>(BASE_URL + '/api/v2/batch_actions', httpOptions).pipe(map((batchList: BatchList) => {
        return batchList;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  getBatchDetail(token: string | null, batchUUID: string | undefined, defaultLanguage = 'de'): Observable<BatchData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.get<BatchData>(BASE_URL + batchUUID, httpOptions).pipe(map((batchData: BatchData) => {
        return batchData;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  getHistoryList(token: string | null, uuid: string, page = 1, numberOfItems = 20, sortKey: string | null | undefined = null, sortDirection: number | undefined | null = 0, filterElements: FilterElement[] | null, defaultLanguage = 'de'): Observable<BatchList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('page', '' + page)
      .set('itemsPerPage', '' + numberOfItems);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<HistoryList>(BASE_URL + '/api/v2/simcards/' + uuid + '/edit_history', httpOptions).pipe(map((historyList: HistoryList) => {
        return historyList;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  getCustomSimCardFields(token: string | null, defaultLanguage = 'de'): Observable<CustomFieldsConfig> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.get<CustomFieldsConfig>(BASE_URL + "/api/v2/active_customer", httpOptions).pipe(map((fieldsData: CustomFieldsConfig) => {
        return fieldsData;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  updateCustomSimCardFields(token: string | null, fieldsData: CustomFieldsUpdate | undefined, defaultLanguage = 'de'): Observable<CustomFieldsConfig> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.patch<CustomFieldsConfig>(BASE_URL + "/api/v2/active_customer", fieldsData, httpOptions).pipe(map((fieldsData: CustomFieldsConfig) => {
        return fieldsData;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  createSimcardsICCIDFilter(token: string | null, csvFile: File | null, defaultLanguage = 'de'): Observable<SimcardsFilterData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const formData = new FormData();
    if (csvFile) {
      formData.append("csv", csvFile, csvFile.name);
    }

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<SimcardsFilterData>(BASE_URL + '/api/v2/simcard_filters/create_from_iccids', formData, httpOptions).pipe(map((simCardFilter: SimcardsFilterData) => {
        const dataResult = simCardFilter;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  createSimcardsMSISDNFilter(token: string | null, csvFile: File | null, defaultLanguage = 'de'): Observable<SimcardsFilterData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const formData = new FormData();
    if (csvFile) {
      formData.append("csv", csvFile, csvFile.name);
    }

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<SimcardsFilterData>(BASE_URL + '/api/v2/simcard_filters/create_from_msisdns', formData, httpOptions).pipe(map((simCardFilter: SimcardsFilterData) => {
        const dataResult = simCardFilter;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }
}
