import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CardService } from 'src/app/services/card.service';
import { SimCardsList } from 'src/app/interfaces/simcardslist';
import { FilterElement } from 'src/app/interfaces/filter-element';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private languageSubscription: Subscription;

  public testDefaultLanguage = '';
  public filterList: FilterElement[] = [];
  public onlineCards = 0;
  public allCards = 0;


  constructor(private router: Router, private userService: UserService, private cardService: CardService,
    private translate: TranslateService, private sessionService: SessionService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.testDefaultLanguage = this.sessionService.getDefaultLanguage();
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    } else {
      this.getSimcardsOnline();
      this.getSimcardsAll();
    }
  }

  ngOnInit() {
    console.log('initialized');
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log('Refreshed');
  }

  getSimcardsOnline() {
    this.filterList = [
      { field: 'online', type: 'input', checked: true, value: 'true' },
    ];

    this.cardService.getSimcardList(this.sessionService.getJWTToken(), 1, 1, null, 0, this.filterList, true, true).subscribe((data: SimCardsList) => {
      this.onlineCards = (data["hydra:totalItems"]) ? data["hydra:totalItems"] : 0;
      //console.log('SIM Cards online: ', data);
    });
  }

  getSimcardsAll() {
    this.filterList = [];

    this.cardService.getSimcardList(this.sessionService.getJWTToken(), 1, 1, null, 0, this.filterList, true, true).subscribe((data: SimCardsList) => {
      this.allCards = (data["hydra:totalItems"]) ? data["hydra:totalItems"] : 0;
      //console.log('SIM Cards all: ', data);
    });
  }
}

