<div class="main-table-options">
    <div class="download-and-col-filter">
        <!-- DOWNLOAD -->
        <button class="csv-download" (click)="exportAsCSV();">CSV</button>
    </div>

    <!-- SELECT SEARCH FILTER -->
    <div class="main-dropdown filters">
        <span>{{"general.filter" | translate}}</span>
        <ul>
            <li>
                <h3>{{"general.show-filter" | translate}}</h3>
            </li>
            <li *ngFor="let filter of filterList; let i = index;">
                <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                    [(ngModel)]="filter.checked" />
                <label for="filter-{{i}}">{{"table-cols."+filter.field | translate}}</label>
            </li>
        </ul>
    </div>
    <div class="selected-filters">
        <ng-container *ngFor="let filter of filterList; let i = index;">
            <div class="filter-item" *ngIf="filter.checked">
                <div class="filter-label">
                    <button class="filter-close" (click)="filterClose(filter)"><img alt="close filter"
                            src="assets/images/filter-close.svg" /></button> {{"table-cols."+filter.field | translate}}
                </div>
                <div class="filter-value">
                    <input *ngIf="filter.type==='input'" type="{{filter.type}}" (keyup.enter)="changeFilter()"
                        [style.width.px]="filter.value.length * 13" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <input *ngIf="filter.type==='date'" type="{{filter.type}}" (change)="changeFilter()"
                        [style.width.px]="150" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <select *ngIf="filter.type==='select'" (change)="changeFilter()" name="{{filter.field}}"
                        [(ngModel)]="filter.value">
                        <option *ngFor="let option of filter.options; let i = index;" value="{{option.value}}">
                            {{option.label}}</option>
                    </select>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="clear"></div>
<div class="table-block">
    <div class="table-outside-div">
        <p-table [columns]="cols" [value]="simcardGroups" [reorderableColumns]="false"
            [resizableColumns]="true" (onLazyLoad)="loadDataLazy($event)" [lazy]="true"
            [loading]="loading" [totalRecords]="countItems" *ngIf="tableIsVisible">

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" class="table-data-col-label {{col.field}}" pReorderableColumn
                        pResizableColumn [pSortableColumn]="(col.field === 'name')?col.field:''">
                        {{"table-cols."+col.field | translate}}
                        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" *ngIf="col.field === 'name'"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of cols">
                        {{ rowData[col.field] }}
                    </td>
                    <td class="buttons">
                        <button class="table-row-button" (click)="editSelectedGroup(rowData);"><img
                                src="assets/images/row-edit.svg" alt="edit" /></button>
                        <button class="table-row-button" (click)="prepareDeleteGroup(rowData);"><img
                                src="assets/images/row-delete.svg" alt="delete" /></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<div>
    <input type="checkbox" class="modal-chkbox" id="modal" [(ngModel)]="isModalChecked"
        (click)="showSimGroupsModel();">
    <label for="modal" class="button-under-table">{{"sim-groups.new-sim-group"|translate}}</label>
    <label for="modal" class="modal-background"></label>
    <div class="modal">
        <label for="modal" class="close-modal"><img src="assets/images/close.svg" alt="close-icon"></label>
        <h3 class="modal-title" *ngIf="selectedSimcardGroupData===null">{{"sim-groups.h-new-sim-group"|translate}}
        </h3>
        <h3 class="modal-title" *ngIf="selectedSimcardGroupData!==null">{{"sim-groups.h-edit-sim-group"|translate}}
        </h3>

        <div class="tabbed-modal">
            <div>
                <form name="tagsForm" ngForm #tagsForm="ngForm">
                    <div class="form-item">
                        <label for="Bezeichnung"
                            class="col-form-label">{{"sim-groups.designation-1"|translate}}</label>
                        <input type="text" id="Bezeichnung" class="form-control name"
                            aria-describedby="BezeichnungHelpInline" name="name" [(ngModel)]="simcardGroupName"
                            #simcardGroupNameFormRef="ngModel" required>
                    </div>
                    <div class="form-item">
                        <label for="costCenter" class="col-form-label">{{"sim-groups.costCenter"|translate}}</label>
                        <p-dropdown scrollHeight="130px" placeholder="{{'general.choice'|translate}}" class="costCenter"
                                    name="costCenter" [(ngModel)]="costCenter" [options]="costCenters" optionLabel="name" optionValue="@id"
                                    [filter]="true" filterBy="name" [disabled]="selectedSimcardGroupData !== null" [required]="true"></p-dropdown>
                    </div>

                    <div class="form-item">
                        <label for="EmailBenachrichtigungen">{{"sim-groups.email-notif"|translate}}</label>
                        <div *ngFor="let emailData of emailElements; let i = index">
                            <input type="email" id="EmailBenachrichtigungen"
                                aria-describedby="EmailBenachrichtigungenHelpInline"
                                class="input-with-delete-button emailNotificationTargets fieldid-{{i}}" (keyup)="insertNewEMail($event, i)"
                                value="{{emailList![i]}}" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="email{{i}}" [(ngModel)]="emailList![i]" required>
                            <button type="button" (click)="deleteFromEmailList(i)"
                                class="delete-input-icon-button"><img src="assets/images/row-delete.svg"
                                    alt="delete"></button>
                        </div>
                        <button (click)="addNewEmailBlock();" class="add-new-field-button">+ {{"general.add-field" | translate}}</button>
                    </div>

                    <div class="form-item">
                        <label for="SMSBenachrichtigungen">SMS-Benachrichtigungen</label>
                        <div *ngFor="let smsData of smsElements; let i = index">
                            <input type="tel" id="SMSBenachrichtigungen" aria-describedby="SMSBenachrichtigungen"
                                class="input-with-delete-button" (keyup)="insertNewSMS($event, i)"
                                value="{{smsList![i]}}" pattern="[0-9,+-.& ]+" minlength="6" maxlength="25" name="smsList{{i}}" [(ngModel)]="smsList![i]" required>
                            <button type="button" (click)="deleteFromSMSList(i)"
                                class="delete-input-icon-button smsNotificationTargets fieldid-{{i}}"><img src="assets/images/row-delete.svg"
                                    alt="delete"></button>
                        </div>
                        <button (click)="addNewSMSBlock();" class="add-new-field-button">+ {{"general.add-field" | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="button-center">
            <button type="submit" [disabled]="!tagsForm.valid" class="button save" id="submit" role="button" (click)="executeGroupAction();">
                {{"sim-groups.save"|translate}}
            </button>
        </div>
    </div>
</div>

<!-- delete modal -->
<div *ngIf="showDeleteModal" class="custom-modal delete-modal">
    <div class="custom-modal-content">
      <p class="text-align-center">{{'sim-tag.deleteQuestion' | translate}}</p>
      <div class="button-center">
        <button class="gray-button button" (click)="showDeleteModal = false">{{"general.cancel"|translate}}</button>
        <button class="save button" type="button"
          (click)="deleteSelectedGroup(prepereDeleteGroup);">{{"general.delete"|translate}}</button>
      </div>
    </div>
</div>