import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable, BehaviorSubject, of, Subject, throwError } from 'rxjs';

import { catchError, finalize, map, tap } from 'rxjs/operators';

import { DEFAULT_LANGUAGE } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public defaultLanguage: string | null = DEFAULT_LANGUAGE!;
  public observerLanguage = new Subject<string>();
  public autoLogoutSessionObserver = new Subject<{ status: boolean }>();
  public autoLogoutFinishedObserver = new Subject<{ status: boolean }>();
  public showLoginModalSubject = new Subject<{ status: boolean }>();

  public showLoginStatusSubject = new Subject<{ status: boolean }>();

  public showAuthModalSubject = new Subject<{ status: boolean, setupToken: string | null }>();

  public reloadNewDevicesSubject = new Subject<{ status: boolean }>();

  public restartLoginProcessSubject = new Subject<{ status: boolean }>();

  public JWTToken: string | null = '';
  public status = false;
  public effectivePermissions: string[] | undefined = [];
  public qualifiedUsername = '';

  setJWTToken(token: string): void {
    this.JWTToken = token;
    sessionStorage.setItem('token', token);
  }

  getJWTToken(): string | null {
    if (undefined == this.JWTToken || null == this.JWTToken || '' == this.JWTToken) {
      this.JWTToken = sessionStorage.getItem('token');
    }
    return this.JWTToken;
  }

  pushLogoutModal(): void {
    this.autoLogoutSessionObserver.next({ status: true });
  }

  setLoginStatus(status: boolean): void {
    this.status = status;
    this.showLoginStatusSubject.next({ status: status });
  }

  setEffectivePermissions(effectivePermissions: string[] | undefined): void {
    this.effectivePermissions = effectivePermissions;
  }

  setQualifiedUsername(qualifiedUsername = ''): void {
    this.qualifiedUsername = qualifiedUsername;
  }

  getLoginStatus(): boolean {
    return this.status;
  }

  setDefaultLanguage(langCode: string): void {
    this.defaultLanguage = langCode;
    sessionStorage.setItem("language", langCode);

    this.observerLanguage.next(langCode);
  }

  getDefaultLanguage(): string {
    this.defaultLanguage = sessionStorage.getItem("language");

    if (this.defaultLanguage == null) this.defaultLanguage = DEFAULT_LANGUAGE;

    return (this.defaultLanguage);
  }

  setLogout(): void {
    this.JWTToken = null;
    sessionStorage.removeItem('token');
  }

  pushAuthModal(status: boolean, setupToken: string | null): void {
    this.showAuthModalSubject.next({ status: status, setupToken: setupToken });
  }

  reloadDevicesList(status: boolean): void {
    this.reloadNewDevicesSubject.next({ status: status });
  }

  restartLoginProcess(status: boolean): void {
    this.restartLoginProcessSubject.next({ status: status });
  }
}
