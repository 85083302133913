import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SessionService } from './session.service';
import { HttpErrorResponse } from '@angular/common/http';

declare let $: any;

//declare const successMessage: FunctionStringCallback;
//declare const infoMessage: FunctionStringCallback;
//declare const warningMessage: FunctionStringCallback;
declare const errorMessage: FunctionStringCallback;

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private languageSubscription: Subscription;

  constructor(private sessionService: SessionService, private translate: TranslateService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });
    //console.log("Error Service");
  }

  processError(errorObj: HttpErrorResponse) {
    $(".error-field").remove();
    if (errorObj.error.violations && errorObj.error.violations.length > 0) {
      errorMessage(this.translate.instant('error.field-error'));

      for (const errorField of errorObj.error.violations) {

        if(errorField.propertyPath === '') {
          errorMessage("Status: " + errorObj.status + " - " + errorObj.error['hydra:description'].replace('\n', '<br>'));
        }

        let field = errorField.propertyPath;

        if (field.indexOf("[") >= 0) {
          const splitField = field.split('[', 2);
          const splitFieldName = splitField[0];
          const splitFieldId = splitField[1].split(']', 1)[0];
          field = splitFieldName + '.fieldid-' + splitFieldId;
        }
        $('form .' + field).after('<div class="error-field">' + errorField.message + '</div>');
      }
    } else {
      let errorMessageText = '';
      if (errorObj.error['hydra:description']) {
        errorMessageText = errorObj.error['hydra:description'].replace('\n', '<br>');
      } else {
        errorMessageText = errorObj.error.message;
      }

      if (errorObj.status === 401) {
        if(errorMessageText.includes('JWT')) {
          errorMessageText = this.translate.instant("error.wrong-authentication-jwt");
        } else {
          errorMessageText = this.translate.instant("error.wrong-authentication");
        }
        
      }

      errorMessage("Status: " + errorObj.status + " - " + errorMessageText);
    }
  }

  deleteFieldError() {
    $(".error-field").remove();
  }
}
