<div class="main-table-filters">
    <!-- refresh -->
    <button class="refresh-page" (click)="reloadPage()">{{"general.reload" | translate}}</button>

    <!-- SELECT SEARCH FILTER -->
    <div class="main-dropdown filters">
        <span>{{"general.filter" | translate}}</span>
        <ul>
            <li>
                <h3>{{"general.show-filter" | translate}}</h3>
            </li>
            <li *ngFor="let filter of filterList; let i = index;">
                <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                    [(ngModel)]="filter.checked" />
                <label for="filter-{{i}}">{{"table-cols."+filter.field | translate}}</label>
            </li>
        </ul>
    </div>
    <div class="selected-filters">
        <ng-container *ngFor="let filter of filterList; let i = index;">
            <div class="filter-item" *ngIf="filter.checked">
                <div class="filter-label">
                    <button class="filter-close" (click)="filterClose(filter)"><img alt="close filter"
                            src="assets/images/filter-close.svg" /></button> {{"table-cols."+filter.field | translate}}
                </div>
                <div class="filter-value">
                    <input *ngIf="filter.type==='input'" type="{{filter.type}}" (keyup.enter)="changeFilter()"
                        [style.width.px]="filter.value.length * 13" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <input *ngIf="filter.type==='date'" type="{{filter.type}}" (change)="changeFilter()"
                        [style.width.px]="150" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <select *ngIf="filter.type==='select'" (change)="changeFilter()" name="{{filter.field}}"
                        [(ngModel)]="filter.value">
                        <option *ngFor="let option of filter.options; let i = index;" value="{{option.value}}">
                            {{"job-history."+option.label | translate}}</option>
                    </select>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="clear"></div>

<div class="table-block">
    <div class="table-outside-div">
        <p-table [columns]="cols" [value]="userList" [paginator]="showPaginator" (onLazyLoad)="loadDataLazy($event)" [lazy]="true"
            [loading]="loading" [rows]="maxNumberOfResults" [totalRecords]="countItems" *ngIf="tableIsVisible" [(first)]="first">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" class="table-data-col-label {{col.field}}" pReorderableColumn
                        pResizableColumn [pSortableColumn]="col.field">
                        {{"table-cols."+col.field | translate}}
                        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
                <tr>
                    <td>{{ user.username }}@{{customerData.code}}</td>
                    <td>{{ user.fullname }}</td>
                    <td>{{ user.email }}</td>
                    <td class="buttons">
                        <button class="table-row-button" (click)="editUser(user);"><img src="assets/images/row-edit.svg" alt="edit" /></button>
                        <button class="table-row-button" (click)="prepareDeleteUser(user);"><img src="assets/images/row-delete.svg" alt="delete" /></button>
                      <button class="table-row-button" (click)="prepareResetTotpUser(user);" title="{{'user.resetTotp'|translate}}"><img
                        src="assets/images/two-reset-red.png" alt="reset-2fa"/></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div class="item-per-page">
            <label for="item-per-page">{{'general.objects-per-site'|translate}}</label>
            <select id="item-per-page" name="objectsNum" [(ngModel)]="maxNumberOfResults"
                #maxNumberOfResultsRef="ngModel" (change)="changeNumberOfRecords();">
                <option *ngFor="let item of ['10','20','50','100','200','500','1000']" value="{{item}}">{{item}}
                </option>
            </select>
        </div>
    </div>

    <!-- User Form -->
    <div>
        <input type="checkbox" class="modal-chkbox" id="modal" (change)="createNewUserButton()" [(ngModel)]="isModalChecked">
        <label for="modal" class="button-under-table">{{'user.new-user'|translate}}</label>
        <label for="modal" class="modal-background"></label>
        <div class="modal">
            <label for="modal" class="close-modal"><img src="assets/images/close.svg" alt="close-icon"></label>
            <h3 *ngIf="editExistingUserUuid === ''" class="modal-title">{{'user.h-new-user'|translate}}</h3>
            <h3 *ngIf="editExistingUserUuid !== ''" class="modal-title">{{'user.h-edit-user'|translate}}</h3>
            <form name="userForm" ngForm #userForm="ngForm">
                <div class="tabbed-modal">
                    <input checked="checked" id="tab11" type="radio" name="tabs-modal" />
                    <input id="tab12" type="radio" name="tabs-modal" />

                    <nav>
                        <label for="tab11">{{'user.general'|translate}}</label>
                        <label for="tab12">{{'user.permissions'|translate}}</label>
                    </nav>
                    <figure>
                        <div class="tab11">
                            <div class="form-item">
                                <label for="benutzername">{{'user.username'|translate}}</label>
                                <input type="text" id="benutzername" class="input-with-substring username"
                                        [(ngModel)]="userName" name="userName" #userNameRef="ngModel"
                                        [value]="userName" required [disabled]="(editExistingUserUuid)?true:false">
                                <span class="username"> @{{customerData.code}}</span>
                            </div>
                            <div class="form-item">
                                <label for="VollerName">{{'user.fullname'|translate}}</label>
                                <input type="text" class="fullname" id="VollerName"
                                        [(ngModel)]="fullName" name="fullName" #fullNameRef="ngModel"
                                        [value]="fullName" required>
                            </div>
                            <div class="form-item">
                                <label for="Email">{{'user.email'|translate}}</label>
                                <input type="email" class="email" id="Email"
                                        [(ngModel)]="userEmail" name="userEmail" #userEmailRef="ngModel"
                                        [value]="userEmail" required>
                            </div>
                            <div class="form-item">
                                <label for="Passwort">
                                    {{'user.password'|translate}}
                                    <span class="info" id="info">
                                        <span class="info-text">
                                        {{'profile.password-i1' | translate}} ~!@$%^*_-+=`|\()&#123;&#125;[]:;”’<>,.?/#&.
                                            {{'profile.password-i2' | translate}}
                                        </span>
                                    </span>
                                </label>
                                <input id="password" class="plainPassword" name="password" placeholder="" type="password" value=""
                                    [(ngModel)]="password" [required]="(editExistingUserUuid)?false:true" minlength="13" maxlength="255" #passwordRef="ngModel"
                                    pattern="((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[+\-<>~_\(\)\{\}\[\]:;&apos;&quot;”’`|.,?/=!@#$%^&*])).{13,}">
                                <span class="required_error_message"
                                    *ngIf="!passwordRef.valid && passwordRef.touched">{{'profile.password-not-secure' |
                                    translate}}</span>
                            </div>
                            <div class="form-item">
                                <label for="passwordConfirm">{{'user.password-confirm'|translate}}</label>
                                <input id="passwordConfirm" name="passwordConfirm" placeholder="" type="password" value=""
                                    [(ngModel)]="passwordConfirm" [required]="(editExistingUserUuid)?false:true" minlength="13" maxlength="255" #passwordConfirmRef="ngModel"
                                    pattern="((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[+\-<>~_\(\)\{\}\[\]:;&apos;&quot;”’`|.,?/=!@#$%^&*])).{13,}">
                                <span class="required_error_message"
                                    *ngIf="(!passwordConfirmRef.valid || passwordRef.value !== passwordConfirmRef.value) && passwordConfirmRef.touched">{{'profile.password-different' | translate}}</span>
                            </div>
                            <div class="form-item">
                                <label for="benutzergruppen">{{'user.user-groups'|translate}}</label>
                                <p-multiSelect [options]="userGroupList" display="chip" id="benutzergruppen" class="groups" name="groups" [(ngModel)]="groups" optionLabel="name" optionValue="@id"></p-multiSelect>
                            </div>
                        </div>
                        <div class="tab12">
                            <div *ngFor="let permisson of globalPermissions" class="form-item">
                                <input (change)='changePermissions()' type="checkbox" id="{{permisson.name}}" [(ngModel)]="permisson.checked" value="{{permisson.checked}}" name="{{permisson.name}}" [checked]="permisson.checked">
                                <label for="{{permisson.name}}" class="col-form-label">{{permisson.title}}</label>
                            </div>
                        </div>
                    </figure>
                </div>
                <div class="button-center">
                    <button type="button" [disabled]="!userForm.valid || passwordRef.value !== passwordConfirmRef.value" class="button save" id="submit" role="button"
                        (click)="saveUser();">
                        {{'user.save'|translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- delete modal -->
<div *ngIf="showDeleteModal" class="custom-modal delete-modal">
    <div class="custom-modal-content">
      <p class="text-align-center">{{'user.deleteQuestion' | translate}}</p>
      <div class="button-center">
        <button class="gray-button button" (click)="showDeleteModal = false">{{"general.cancel"|translate}}</button>
        <button class="save button" type="button"
          (click)="deleteSelectedUser(prepereDeleteUser);">{{"general.delete"|translate}}</button>
      </div>
    </div>
</div>


<!-- resetTotp modal -->
<div *ngIf="showResetTotpModal" class="custom-modal reset-totp-modal">
  <div class="custom-modal-content">
    <p class="text-align-center">{{ 'user.resetTotpQuestion' | translate }}</p>
    <div class="button-center">
      <button class="gray-button button" (click)="showResetTotpModal = false">{{ "general.cancel"|translate }}</button>
      <button class="save button" type="button"
              (click)="deleteSelectedUser(prepereResetTotpUser);">{{ "user.resetTotp"|translate }}
      </button>
    </div>
  </div>
</div>
