import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { TotpSetupDevice } from 'src/app/interfaces/totp-setup-device';
import { TotpSetupConfirmation } from 'src/app/interfaces/totp-setup-confirmation';
import { TotpDevice } from 'src/app/interfaces/totp-device';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { UserData } from 'src/app/interfaces/userlist';

declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private languageSubscription: Subscription;

  public autoLogoutSubscription: Subscription;

  public closeAutoLogoutModalSubscription: Subscription;

  public showLoginModalSubscription: Subscription;

  public showLoginStatusSubscription: Subscription;

  public showAuthModalSubscription: Subscription;

  public isUserLogged = false;

  public addDevices = false;
  public isCodeDisplayed = false;
  public totpSetupDevice!: TotpSetupDevice;
  public totpDevice!: TotpDevice;
  public totpDevices!: TotpSetupConfirmation[];
  public barCodeNumber = '';
  public device = '';
  public isAlreadyActivated = false;
  public isBarCodeGenerated = false;
  public qrCodeBase?: string;
  public qrCodeSecret?: string;
  public showBarCodeGenerated = false;
  public twoFAActivate = false;
  public effectivePermissions: string[] | undefined = [];
  public qualifiedUsername = '';

  public setupToken?: string;

  constructor(private userService: UserService, private router: Router,
    private translate: TranslateService, private sessionService: SessionService, private errorService: ErrorHandlerService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    this.autoLogoutSubscription = this.sessionService.autoLogoutSessionObserver.subscribe(response => {
      this.logoutWithModal();
    });

    this.closeAutoLogoutModalSubscription = this.sessionService.autoLogoutFinishedObserver.subscribe(response => {
      if (response.status == true) {
        this.logoutModalClose();
      }
    });

    this.showLoginModalSubscription = this.sessionService.showLoginModalSubject.subscribe(data => {
      this.logoutModalVisible(data.status);
    });

    this.showLoginStatusSubscription = this.sessionService.showLoginStatusSubject.subscribe(data => {
      if (data.status == true) {
        this.isUserLogged = true;
        
        this.effectivePermissions = this.sessionService.effectivePermissions;
        sessionStorage.setItem('effectivePermissions', JSON.stringify(this.effectivePermissions));

        this.qualifiedUsername = this.sessionService.qualifiedUsername;
        sessionStorage.setItem('qualifiedUsername', JSON.stringify(this.qualifiedUsername));
      } else {
        this.isUserLogged = false;
      }
    });

    this.showAuthModalSubscription = this.sessionService.showAuthModalSubject.subscribe(data => {
      if (data.status == true) {
        if (data.setupToken !== null) {
          this.setupToken = data.setupToken;

          this.isBarCodeGenerated = true;
          this.addDevices = true;
          this.isCodeDisplayed = false;
          this.barCodeNumber = "";
          this.device = "";

          this.totpDevice = {
            "@id": '',
            "@context": '',
            "@type": '',
            "setupToken": this.setupToken
          };

          this.prepareFor2FAAuth(this.totpDevice);
        } else {
          this.execute2FALogin();
        }
      }
    });

    const effectivePermissions = sessionStorage.getItem('effectivePermissions');
    if(effectivePermissions) {
      this.effectivePermissions = JSON.parse(effectivePermissions);
      this.sessionService.effectivePermissions = this.effectivePermissions;
    }

    const qualifiedUsername = sessionStorage.getItem('qualifiedUsername');
    if(qualifiedUsername) {
      this.qualifiedUsername = JSON.parse(qualifiedUsername);
      this.sessionService.qualifiedUsername = this.qualifiedUsername;
    }

    if (this.sessionService.getJWTToken() && this.sessionService.getJWTToken() != '') {
      this.isUserLogged = true;
    }
  }

  ngOnInit() {
    //console.log('initialized');

    $(document).on('click', (event: Event) => {

      // Menu Animation
      if ($(event.target).closest(".navbar-button").not(".close").length !== 0) {
        $('.main-menu').addClass('show');
        $(".navbar-button").addClass('close');
      } else if ($(event.target).closest(".navbar-button.close").length !== 0) {
        $('.main-menu').removeClass('show');
        $(".navbar-button").removeClass('close');
      }

    });
  }

  ngOnDestroy() {
    console.log('destroyed');
    this.showAuthModalSubscription.unsubscribe();
  }

  reloadPageIfSame(event: Event) {
    if (event.target instanceof HTMLAnchorElement && event.target.getAttribute('href') == this.router.url) {
      event.preventDefault();
      window.location.reload();
    }
  }

  logout(event: Event) {
    event.preventDefault();

    this.sessionService.setJWTToken('');
    this.sessionService.setLoginStatus(false);
    this.isUserLogged = false;
    this.sessionService.effectivePermissions = [];
    sessionStorage.removeItem('effectivePermissions');
    this.sessionService.qualifiedUsername = '';
    sessionStorage.removeItem('qualifiedUsername');

    this.router.navigateByUrl('/login').then((e) => {
      if (e) {
        //console.log("Navigation is successful!");
      } else {
        //console.log("Navigation has failed!");
      }
    });
  }

  logoutWithModal() {
    console.log("logout");
    this.router.navigateByUrl('/dashboard');
    this.sessionService.setLogout();
  }

  logoutModalClose() {
    console.log("logout modal close");
  }

  logoutModalVisible(isVisible: boolean): void {
    console.log("is visible: ", isVisible);
  }

  saveToTpData() {
    //console.log("save");

    this.userService.confirmTotpDevice(this.totpDevice.setupToken!, this.barCodeNumber, this.device).subscribe({
      next: (response: TotpSetupConfirmation) => {
        //console.log(response);
        this.isAlreadyActivated = true;
        this.device = "";
        this.addDevices = false;
        this.isCodeDisplayed = false;

        this.sessionService.reloadDevicesList(true);
        this.sessionService.restartLoginProcess(true);
      },
      error: (err: any) => {
        this.errorService.processError(err);
      }
    });
  }

  execute2FALogin() {
    this.isBarCodeGenerated = true;
    this.addDevices = true;
    this.isCodeDisplayed = false;
    this.barCodeNumber = "";
    this.device = "";

    this.update2FAStatus();
  }

  update2FAStatus() {
    const accessToken = sessionStorage.getItem('access_token');

    this.barCodeNumber = '';

    if (this.isBarCodeGenerated === true) {

      this.createTotpDeviceFor2FAAuth();
    } else {
      this.barCodeNumber = '';

      this.qrCodeBase = undefined;
      this.qrCodeSecret = '';

      this.showBarCodeGenerated = false;
    }
  }

  createTotpDeviceFor2FAAuth() {
    this.userService.createTotpDevice(this.sessionService.getJWTToken()).subscribe({
      next: (response: TotpDevice) => {
        //console.log(response);
        this.totpDevice = response;

        this.prepareFor2FAAuth(this.totpDevice);
      },
      error: (err: any) => {
        this.errorService.processError(err);
      }
    });
  }

  prepareFor2FAAuth(totpDevice: TotpDevice) {
    this.userService.prepareTotpDevice(totpDevice.setupToken).subscribe({
      next: (data: TotpSetupDevice) => {
        //console.log(data);
        this.totpSetupDevice = data;

        this.showBarCodeGenerated = true;
      },
      error: (err: any) => {
        this.errorService.processError(err);
      }
    });
  }

}
