import { ChangeDetectorRef, Component, OnInit, OnDestroy, HostListener, AfterContentChecked } from '@angular/core';
import { CustomTableColumn } from 'src/app/interfaces/custom-table-column';
import { FilterElement } from 'src/app/interfaces/filter-element';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'src/app/services/session.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { Subscription } from 'rxjs';
import { ReportService } from 'src/app/services/report.service';
import { ReportDataList } from 'src/app/interfaces/report-data-list';
import { ReportData } from 'src/app/interfaces/report-data';
import { TableLazyLoadEvent } from 'primeng/table';
import { HttpErrorResponse } from '@angular/common/http';

interface MapKeys {
  name?: string,
  createdAt?: string
}

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent implements OnInit, OnDestroy, AfterContentChecked {
  private languageSubscription: Subscription;
  
  public filterList: FilterElement[] = [];
  public selectedColumns: CustomTableColumn[] = [];
  public showPaginator = false;
  public loading = true;
  public reportsSortBy = 'name';
  public pageNumber = 1;
  public maxNumberOfResults = 10;
  public countItems = 0;
  public reportList: ReportData[] = [];
  public sortDirection: number | null | undefined;
  public searchKey = 'createdAt';
  public tableIsVisible = true;
  public mapKeys: MapKeys = {
    name: 'name',
    createdAt: 'createdAt'
  };
  public first = 0;

  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService, private reportService: ReportService, private errorService: ErrorHandlerService,
    private changeDetector: ChangeDetectorRef) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    this.selectedColumns = [
      { field: 'name', checked: true },
      { field: 'createdAt', checked: true },
      { field: 'type', checked: true },
      { field: 'status', checked: true }
    ];

    this.filterList = [
      { field: 'name', type: 'input', checked: false, value: "" },
      { field: 'parameters.type', type: 'select', checked: false, value: "", options: [
          { label: 'sessions', value: 'sessions' },
          { label: 'sessions_by_cost_center', value: 'sessions_by_cost_center' },
          { label: 'sessions_by_simcard_group', value: 'sessions_by_simcard_group' },
          { label: 'sessions_by_simcard', value: 'sessions_by_simcard' }
        ]
      }
    ];
  
  }

  ngOnInit() {
    console.log('initialized');
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log(event);
  }

  reloadPage() {
    this.getReports();
  }

  loadDataLazy(event: TableLazyLoadEvent): void {
    this.sortDirection = -1;

    if (event && event.sortField && event.sortField && typeof event.sortField === 'string') {
      this.searchKey = event.sortField;
      this.sortDirection = event.sortOrder;
    }

    this.pageNumber = (event.first)?(event?.first / +this.maxNumberOfResults) + 1:1;

    this.getReports();
  }

  getReports() {
    this.loading = true;

    this.reportService.getReports(this.sessionService.getJWTToken(), this.pageNumber, this.maxNumberOfResults, this.mapKeys[this.searchKey as keyof MapKeys], this.sortDirection, this.filterList, false).subscribe({
      next: (response: ReportDataList) => {
        this.reportList = response['hydra:member'];

        //console.log(this.reportList);

        this.countItems = (response["hydra:totalItems"])?response["hydra:totalItems"]:0;
        this.showPaginator = true;
        this.loading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  changeNumberOfRecords() {
    this.tableIsVisible = false;

    setTimeout(() => {
      this.tableIsVisible = true;
    }, 50);
  }

  changeFilter() {
    this.first = 0;
    this.pageNumber = 1;
    this.getReports();
  }

  filterClose(filter: FilterElement) {
    filter.checked = false;
    this.changeFilter();
  }
}
