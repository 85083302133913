<div class="modal-alternativ">
    <h3 class="modal-title">{{'header.simcards-csv-update-title' | translate}}</h3>

    <div class="upload-field-with-text">
        <div class="upload-field">
            <button _ngcontent-vrc-c55="" class="file-upload-button">Datei auswählen</button>
            <input [disabled]="simCardsUpdateInProcess" type="file" id="csv" name="csv" accept=".csv"
                [(ngModel)]="selectedCSVFile" (change)="uploadSelectedFile($event);" />
        </div>

        <div class="upload-file">
            <span *ngIf="selectedCSVFile !== undefined">{{selectedCSVFile.name}}</span>
        </div>
    </div>

    <div class="button-center">
        <button [disabled]="simCardsUpdateInProcess" type="button" id="submit" role="button" class="button save"
            (click)="executeUpdateAction();" [disabled]="selectedCSVFile === undefined"> {{"sim-cards.button-execute"|translate}} </button>
    </div>
</div>


<!-- Status Modal -->
<div class="block-modal-status">
    <input type="checkbox" class="modal-chkbox" id="modal-status" value="{{isModalStatusChecked}}"
        [(ngModel)]="isModalStatusChecked">
    <label for="modal-status" class="disable-modal-label"></label>
    <label for="modal-status-daslbe" class="modal-background"></label>

    <div class="modal">
        <label for="modal-status" class="close-modal" *ngIf="simCardsProcessedInPrecent === 100"><img
                src="assets/images/close.svg" alt="close-icon" /></label>

        <h3 class="modal-title">Updatestatus</h3>

        <div class="chart-block">
            <div class="chart-item status">
                <div class="chart-item-labels-graph">
                    <div class="chart-item-labels">
                        <div class="chart-item-label"></div>
                        <div class="chart-item-usage-label">{{simCardsProcessedInPrecent}}% abgeschloßen</div>
                    </div>
                    <div class="chart-item-graph">
                        <div class="chart-item-usage" style="width: {{simCardsProcessedInPrecent}}%"></div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="simCardsUpdateErrors.length > 0" class="status-error">
            <div class="status-error-title">Fehler bei folgender ICCID-s:</div>
            <div class="error-items">
                <div *ngFor="let simError of simCardsUpdateErrors" class="error-item" title="{{simError.error}}">
                    {{simError.simcard.iccid}} <img src="assets/images/warning.svg" alt="error info icon"
                        class="error-info-icon"></div>
            </div>
        </div>
    </div>
</div>
<!-- End Status Modal -->
