import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable, BehaviorSubject, of, Subject, throwError } from 'rxjs';

import { catchError, finalize, map, tap } from 'rxjs/operators';

import { BASE_URL } from 'src/environments/environment';

import { FilterElement } from '../interfaces/filter-element';
import { TrafficProfileList } from '../interfaces/traffic-profile-list';
import { TrafficProfile } from '../interfaces/traffic-profile';

const SERVICE_URL = "/traffic_profiles";

@Injectable({
  providedIn: 'root'
})
export class TrafficProfileService {

  constructor(private http: HttpClient) { }

  public getTrafficProfileList(token: string | null, sortKey: string | null = null, sortDirection: number | undefined | null = 0, filterElements: FilterElement[] | null = null, defaultLanguage = 'de'): Observable<TrafficProfileList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('pagination', false);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<any>(BASE_URL + '/api/v2' + SERVICE_URL, httpOptions).pipe(map((trafficProfileList: TrafficProfileList) => {
      const dataResult = trafficProfileList;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getTrafficProfileCSV(token: string | null, defaultLanguage = 'de'): Observable<string> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const params = new HttpParams()
      .set('pagination', false);

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'text/csv')
      .set('Accept', 'text/csv')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions: NonNullable<unknown> = {
      headers: httpHeaders,
      params: params,
      responseType: 'text' as const
    };

    return this.http.get<string>(BASE_URL + '/api/v2' + SERVICE_URL, httpOptions).pipe(map((trafficProfileList: string) => {
        const dataResult = trafficProfileList;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getTrafficProfile(token: string | null, uuid: string, defaultLanguage = 'de'): Observable<TrafficProfile> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const params = new HttpParams()
      .set('uuid', '' + uuid);

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<any>(BASE_URL + uuid, httpOptions).pipe(map((trafficProfile: TrafficProfile) => {
      const dataResult = trafficProfile;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  createNewTrafficProfile(token: string | null, trafficProfileData: any, defaultLanguage = 'de'): Observable<TrafficProfile> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/ld+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<any>(BASE_URL + '/api/v2' + SERVICE_URL, trafficProfileData, httpOptions).pipe(map((trafficProfileData: TrafficProfile) => {
      const dataResult = trafficProfileData;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  updateTrafficProfile(token: string | null, uuid: string | undefined, trafficProfileData: any, defaultLanguage = 'de'): Observable<TrafficProfile> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.patch<any>(BASE_URL + '/api/v2' + SERVICE_URL + '/' + uuid, trafficProfileData, httpOptions).pipe(map((trafficProfile: TrafficProfile) => {
      const dataResult = trafficProfile;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  deleteTrafficProfile(token: string | null, uuid: string | undefined, defaultLanguage = 'de'): Observable<any> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.delete(BASE_URL + '/api/v2' + SERVICE_URL + '/' + uuid, httpOptions).pipe(map((response: any) => {
      const dataResult = response;

      return dataResult;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }
}
