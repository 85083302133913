import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TotpDevice } from 'src/app/interfaces/totp-device';
import { TotpSetupDevice } from 'src/app/interfaces/totp-setup-device';
import { TotpSetupConfirmation } from 'src/app/interfaces/totp-setup-confirmation';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserData } from 'src/app/interfaces/userlist';

declare const successMessage: FunctionStringCallback;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

  private languageSubscription: Subscription;

  private reloadTotpDevices: Subscription;

  public editLoginData = false;
  public changePassword = false;
  public isAlreadyActivated = false;
  public uPassword = '';
  public uNewPassword = '';
  public uPasswordConfirm = '';
  public barCodeNumber = '';
  public barCodeSecret = '';
  public device = '';
  public twoFAActivateStatus = false;
  public twoFAActivate = false;
  public addDevices = false;
  public isBarCodeGenerated = false;
  public showBarCodeGenerated = false;
  public showDeleteModal = false;
  public isCodeDisplayed = false;
  public twoFAChanged = false;
  public qrCodeBase?: string;
  public qrCodeSecret?: string;
  public totpDevice!: TotpDevice;
  public totpDevices!: TotpSetupConfirmation[];
  public totpSetupDevice!: TotpSetupDevice;
  public prepereDeleteDevice!: TotpDevice;
  public activeUser!: UserData;

  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService, private errorService: ErrorHandlerService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    this.reloadTotpDevices = this.sessionService.reloadNewDevicesSubject.subscribe(data => {
      if (data.status == true) {
        this.findAllTotpDevices();
      }
    });

    this.findAllTotpDevices();
    this.getActiveUser();
  }

  ngOnInit() {
    console.log('initialized');
  }

  ngOnDestroy() {
    console.log('destroyed');
    this.reloadTotpDevices.unsubscribe();
  }

  saveToTpData() {
    //console.log("save");

    const setupToken = (this.totpDevice.setupToken)?this.totpDevice.setupToken:'';

    this.userService.confirmTotpDevice(setupToken, this.barCodeNumber, this.device).subscribe({
      next: (response: TotpSetupConfirmation) => {
        //console.log(response);
        this.isAlreadyActivated = true;
        this.device = "";
        this.addDevices = false;
        this.isCodeDisplayed = false;

        this.findAllTotpDevices();
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  executeDeviceModal(): void {
    this.sessionService.pushAuthModal(true, null);
  }

  findAllTotpDevices() {
    this.userService.listAllTotpDevices(this.sessionService.getJWTToken(), 1, 100).subscribe({
      next: (devices: TotpDevice[]) => {
        //console.log(devices);
        this.totpDevices = devices;

        if (this.totpDevices && this.totpDevices.length > 0) {
          this.twoFAActivate = true;
          this.isAlreadyActivated = true;
        } else {
          this.twoFAActivate = false;
          this.isAlreadyActivated = false;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  deviceIdentifier(index: number, item: TotpSetupConfirmation) {
    return item['@id'];
  }

  prepareDeleteDevice(device: TotpSetupConfirmation) {
    this.prepereDeleteDevice = device;
    this.showDeleteModal = true;
  }

  deleteCurrentDevice(device: TotpSetupConfirmation) {
    //console.log(device);

    this.userService.deleteTotpDevice(this.sessionService.getJWTToken(), device.uuid).subscribe({
      next: (response) => {
        //console.log(response);
        this.showDeleteModal = false;
        this.findAllTotpDevices();
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  changeCurrentPassword() {
    this.userService.changeUserPassword(this.sessionService.getJWTToken(), this.uPassword, this.uNewPassword).subscribe({
      next: (response) => {
        this.editLoginData = false;
        successMessage(this.translate.instant('profile.success-password-update'));
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  getActiveUser() {
    this.userService.getActiveUser(this.sessionService.getJWTToken()).subscribe({
      next: (response: UserData) => {
        this.activeUser = response;
      },
      error: (err: any) => {
        this.errorService.processError(err);
      }
    });
  }

}
