import { Component, OnInit } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'db-m2m';

  ngOnInit() {
    this.checkTableWidth();
    
    $(document).on('click', () => {
      this.checkTableWidth();
    });

    // Main Select Dropdown
    $(document).on('click', (event: Event) => {
      if($(event.target).closest(".delete-view").length === 0) {
        if ($(event.target).closest(".main-dropdown").length === 0) {
          $('.main-dropdown ul').fadeOut();
          $('.main-dropdown').removeClass('open');
        } else {
            if($(event.target).closest('ul').length === 0 || $(event.target).closest(".main-dropdown.result-number").length != 0) {
              if (!$(event.target).closest('.main-dropdown').find('ul').is(':visible')){
                  $(event.target).closest('.main-dropdown:not(.highlighted-false, .highlighted-)').addClass('open');
                  $(event.target).closest('.main-dropdown:not(.highlighted-false, .highlighted-)').find('ul').fadeIn();
                  $('.main-dropdown ul').not($(event.target).closest('.main-dropdown').find('ul')).fadeOut();
                  $('.main-dropdown').not($(event.target).closest('.main-dropdown')).removeClass('open');
              } else {
                  $(event.target).closest('.main-dropdown').removeClass('open');
                  $(event.target).closest('.main-dropdown').find('ul').fadeOut();
              }
            }
        }
      }
    });

    // Info Box Animation
    $(document).on('click', (event: Event) => {
      if ($(event.target).closest(".info").length === 0) {
          $('.info-text').fadeOut();
      } else {
          if ($(event.target).closest(".info-text").length === 0)
            $('.info-text').not($(event.target).find('.info-text')).fadeOut();
          if ($(event.target).find('.info-text').is(':visible')) {
            $(event.target).find('.info-text').fadeOut();
          } else {
            $(event.target).find('.info-text').fadeIn();
          }
      }
    });
  }

  checkTableWidth() {
    setTimeout(() => {
      if($('.table-outside-div table').width() + 40 > $('.table-outside-div').width()) {
        $('.table-outside-div').addClass('over');
      } else {
        $('.table-outside-div').removeClass('over');
      }
    }, 500);
  }
}
