import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { TableLazyLoadEvent } from 'primeng/table';
//import { LazyLoadEvent } from 'primeng/api'; 

import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CardService } from 'src/app/services/card.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { BatchList } from 'src/app/interfaces/batchlist';
import { BatchData } from 'src/app/interfaces/batch-data';
import { CustomTableColumn } from 'src/app/interfaces/custom-table-column';
import { FilterElement } from 'src/app/interfaces/filter-element';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-job-history',
  templateUrl: './job-history.component.html',
  styleUrls: ['./job-history.component.css']
})
export class JobHistoryComponent implements OnInit, OnDestroy {
  private languageSubscription: Subscription;

  public showPaginator = false;
  public loading = true;
  public countItems = 0;
  public batchList: unknown;
  public batchData: BatchData[] = [];
  public pageNumber = 1;
  public pageConnectionsNumber = 1;
  public searchKey: string | null | undefined;
  public maxNumberOfResults = 10;
  public sortDirection: number | null | undefined;
  public cols!: CustomTableColumn[];
  public tableIsVisible = true;
  public isDetailsModalChecked = false;
  public batchDetails: BatchData = {};
  public filterList: FilterElement[] = [];

  constructor(private router: Router, private userService: UserService,
    private translate: TranslateService, private sessionService: SessionService, private cardService: CardService, private errorService: ErrorHandlerService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }

    this.showPaginator = true;
    this.cols = [
      { field: 'submittedAt', checked: true },
      { field: 'action', checked: true },
      { field: 'pendingCount', checked: true },
      { field: 'successfulCount', checked: true },
      { field: 'failedCount', checked: true }
    ];

    this.filterList = [
      {
        field: 'action', type: 'select', checked: false, value: "", options: [
          { label: 'activate_simcards', value: 'activate_simcards' },
          { label: 'decommission_simcards', value: 'decommission_simcards' },
          { label: 'enable_simcards', value: 'enable_simcards' },
          { label: 'disable_simcards', value: 'disable_simcards' },
          { label: 'set_roaming_policy', value: 'set_roaming_policy' }
        ]
      },
      {
        field: 'state', type: 'select', checked: false, value: "", options: [
          { label: 'ready', value: 'ready' },
          { label: 'processing', value: 'processing' },
          { label: 'finished', value: 'finished' },
          { label: 'incomplete', value: 'incomplete' }
        ]
      },
      {field: 'submittedAt[strictly_after]', type: 'date', checked: false, value: ""},
      {field: 'submittedAt[strictly_before]', type: 'date', checked: false, value: ""}
    ];

    //this.getBatchList();
  }

  ngOnInit() {
    console.log('initialized');
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  reloadPage() {
    this.getBatchList();
  }

  loadDataLazy(event: TableLazyLoadEvent): void {
    if (event && event.sortField && event.sortField && typeof event.sortField === 'string') {
      this.searchKey = event.sortField;
    }

    this.sortDirection = event.sortOrder;

    this.pageNumber = (event.first)?(event.first / +this.maxNumberOfResults) + 1:1;

    this.getBatchList();
  }

  changeNumberOfRecords() {
    this.tableIsVisible = false;

    setTimeout(() => {
      this.tableIsVisible = true;
    }, 50);
  }

  changeFilter() {
    //console.log(filter.field);
    //console.log(this.filterList);
    this.getBatchList();
  }

  filterClose(filter: FilterElement) {
    filter.checked = false;
    this.changeFilter();
  }

  getBatchList() {
    this.loading = true;

    this.cardService.getBatchList(this.sessionService.getJWTToken(), this.pageNumber, this.maxNumberOfResults, this.searchKey, this.sortDirection, this.filterList).subscribe({
      next: (data: BatchList) => {
        this.batchList = data;
        this.batchData = data['hydra:member'];

        this.countItems = (data["hydra:totalItems"])?data["hydra:totalItems"]:0;
        this.showPaginator = true;
        this.loading = false;

        //console.log('batchList', this.batchData);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }


  showDetails(batch: BatchData) {
    this.batchDetails = batch;

    this.cardService.getBatchDetail(this.sessionService.getJWTToken(), batch?.['@id']).subscribe({
      next: (data: BatchData) => {
        this.batchDetails = data;
        this.isDetailsModalChecked = true;

        //console.log(this.batchDetails);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });    
  }

}
