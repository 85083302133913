<div class="main-table-filters">
    <!-- refresh -->
    <button class="refresh-page" (click)="reloadPage()">{{"general.reload" | translate}}</button>

    <!-- SELECT SEARCH FILTER -->
    <div class="main-dropdown filters">
        <span>{{"general.filter" | translate}}</span>
        <ul>
            <li>
                <h3>{{"general.show-filter" | translate}}</h3>
            </li>
            <li *ngFor="let filter of filterList; let i = index;">
                <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                    [(ngModel)]="filter.checked" />
                <label for="filter-{{i}}">{{"table-cols."+filter.field | translate}}</label>
            </li>
        </ul>
    </div>
    <div class="selected-filters">
        <ng-container *ngFor="let filter of filterList; let i = index;">
            <div class="filter-item" *ngIf="filter.checked">
                <div class="filter-label">
                    <button class="filter-close" (click)="filterClose(filter)"><img alt="close filter"
                            src="assets/images/filter-close.svg" /></button> {{"table-cols."+filter.field | translate}}
                </div>
                <div class="filter-value">
                    <input *ngIf="filter.type==='input'" type="{{filter.type}}" (keyup.enter)="changeFilter()"
                        [style.width.px]="filter.value.length * 13" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <input *ngIf="filter.type==='date'" type="{{filter.type}}" (change)="changeFilter()"
                        [style.width.px]="150" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <select *ngIf="filter.type==='select'" (change)="changeFilter()" name="{{filter.field}}"
                        [(ngModel)]="filter.value">
                        <option *ngFor="let option of filter.options; let i = index;" value="{{option.value}}">
                            {{"job-history."+option.label | translate}}</option>
                    </select>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="clear"></div>

<div class="table-block">
    <div class="table-outside-div">
        <p-table [columns]="cols" [value]="userGroupList" [paginator]="showPaginator" (onLazyLoad)="loadDataLazy($event)" [lazy]="true"
            [loading]="loading" [rows]="maxNumberOfResults" [totalRecords]="countItems" *ngIf="tableIsVisible" [(first)]="first">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" class="table-data-col-label {{col.field}}" pReorderableColumn
                        pResizableColumn [pSortableColumn]="col.field">
                        {{"table-cols."+col.field | translate}}
                        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-usergroup>
                <tr>
                    <td>{{ usergroup.name }}</td>
                    <td class="buttons">
                        <button class="table-row-button" (click)="editUserGroup(usergroup);"><img src="assets/images/row-edit.svg" alt="edit" /></button>
                        <button class="table-row-button" (click)="prepareDeleteUserGroup(usergroup);"><img src="assets/images/row-delete.svg" alt="delete" /></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div class="item-per-page">
            <label for="item-per-page">{{'general.objects-per-site'|translate}}</label>
            <select id="item-per-page" name="objectsNum" [(ngModel)]="maxNumberOfResults"
                #maxNumberOfResultsRef="ngModel" (change)="changeNumberOfRecords();">
                <option *ngFor="let item of ['10','20','50','100','200','500','1000']" value="{{item}}">{{item}}
                </option>
            </select>
        </div>
    </div>

    <!-- User group form -->
    <div>
        <input type="checkbox" class="modal-chkbox" id="modal" (change)="createNewUserGroupButton()" [(ngModel)]="isModalChecked">
        <label for="modal" class="button-under-table">{{'user-groups.new-user-group' | translate}}</label>
        <label for="modal" class="modal-background"></label>
        <div class="modal">
            <label for="modal" class="close-modal"><img src="assets/images/close.svg" alt="close-icon"></label>
            <h3 class="modal-title" *ngIf="editExistingUserGroupUuid === ''">{{'user-groups.h-new-user-group' | translate}}</h3>
            <h3 class="modal-title" *ngIf="editExistingUserGroupUuid !== ''">{{'user-groups.h-edit-user-group' | translate}}</h3>
            <form name="userForm" ngForm #userForm="ngForm">
                <div class="tabbed-modal">
                    <input checked="checked" id="tab11" type="radio" name="tabs-modal" />
                    <input id="tab12" type="radio" name="tabs-modal" />
                    <input id="tab13" type="radio" name="tabs-modal" />

                    <nav>
                        <label for="tab11">{{'user-groups.general' | translate}}</label>
                        <label for="tab12">{{'user-groups.permissions' | translate}}</label>
                        <label for="tab13">{{'user-groups.cost-center-acl' | translate}}</label>
                    </nav>
                    <figure>
                        <div class="tab11">
                            <div class="form-item">
                                <label for="name" class="col-form-label">{{'user-groups.designation-1' | translate}}</label>
                                <input type="text" id="name" class="form-control name" [(ngModel)]="name" name="name" #nameRef="ngModel" [value]="name" required>
                            </div>                           
                            <div class="form-item">
                                <input type="checkbox" id="requireTwoFactor" class="form-control" [(ngModel)]="requireTwoFactor" name="requireTwoFactor" #requireTwoFactorRef="ngModel" [value]="requireTwoFactor">
                                <label for="requireTwoFactor" class="col-form-label requireTwoFactor">{{'user-groups.requireTwoFactor' | translate}}</label>
                            </div>
                        </div>
                        <div class="tab12">
                            <div *ngFor="let permisson of globalPermissions" class="form-item">
                                <input (change)='changePermissions()' type="checkbox" id="{{permisson.name}}" [(ngModel)]="permisson.checked" value="{{permisson.checked}}" name="{{permisson.name}}" [checked]="permisson.checked">
                                <label for="{{permisson.name}}" class="col-form-label">{{permisson.title}}</label>
                            </div>
                        </div>
                        <div class="tab13">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-item">
                                        <label for="ban-name">{{'user-groups.cost-center' | translate}}</label>
                                    </div>
                                </div>
                                <div class="col-sm-8">
                                    <div class="form-item">
                                        <label for="Benutzer">{{'user-groups.roles' | translate}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngFor="let _ of costCenterRoles; let i = index;" class="simcardAcl fieldid-{{i}}">
                                <div class="col-sm-4">
                                    <div class="form-item">
                                        <select id="costCenter" name="costCenter" (change)="regenerateUsedCostCenters()" name="costCenterRoles{{i}}.costCenter" [(ngModel)]="costCenterRoles![i].costCenter" required>
                                            <option value="">{{'general.choice' | translate}}</option>
                                            <option *ngFor="let costCenter of costCenters" value="{{costCenter['@id']}}" [disabled]="costCenter['@id'] && usedCostCenters.includes(costCenter['@id'])">
                                                {{costCenter.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-8">
                                    <div class="form-item">
                                        <p-multiSelect [options]="simcardPermissions" scrollHeight="140px" display="chip" class="input-with-delete-button" name="roles-{{i}}" [(ngModel)]="costCenterRoles[i].permissions" optionLabel="title" optionValue="name" [required]="true"></p-multiSelect>
                                        <button (click)="deleteFromRoleList(i);" class="delete-input-icon-button"><img src="assets/images/row-delete.svg" alt="delete"></button>
                                    </div>
                                </div>
                            </div>
                            <button (click)="addNewCostCenterRole();" class="add-new-field-button">+ ACL hinzufügen</button>
                        </div>
                    </figure>
                </div>
            </form>
            <div class="button-center">
                <button type="button" [disabled]="!userForm.valid" class="button save" id="submit" role="button" (click)="saveUserGroup()">
                    {{'user-groups.save'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- delete modal -->
<div *ngIf="showDeleteModal" class="custom-modal delete-modal">
    <div class="custom-modal-content">
      <p class="text-align-center">{{'user-groups.deleteQuestion' | translate}}</p>
      <div class="button-center">
        <button class="gray-button button" (click)="showDeleteModal = false">{{"general.cancel"|translate}}</button>
        <button class="save button" type="button"
          (click)="deleteSelectedUserGroup(prepereDeleteUserGroup);">{{"general.delete"|translate}}</button>
      </div>
    </div>
</div>