import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { CardService } from 'src/app/services/card.service';
import { TrafficProfile } from 'src/app/interfaces/traffic-profile';
import { PermissionsData, PermissionsList } from 'src/app/interfaces/permissionslist';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { UserData, UserList } from 'src/app/interfaces/userlist';
import { FilterElement } from 'src/app/interfaces/filter-element';
import { CustomTableColumn } from 'src/app/interfaces/custom-table-column';
import { UserGroup, UserGroupList } from 'src/app/interfaces/user-group';
import { CustomFieldsConfig } from 'src/app/interfaces/custom-fields-config';

declare const successMessage: FunctionStringCallback;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {
  private languageSubscription: Subscription;

  public trafficProfiles: TrafficProfile[] = [];
  public trafficProfilesList: unknown;
  public showPaginator = false;
  public loading = true;
  public maxNumberOfResults = 10;
  public countItems = 0;
  public pageNumber = 1;
  public tableIsVisible = true;
  public trafficProfileSelected = false;
  public isModalChecked = false;
  public selectedTrafficProfileData: TrafficProfile | null = null;
  public globalPermissions: PermissionsData[] = [];
  public editUserPermissions: string[] = [];
  public userName = "";
  public userEmail = "";
  public fullName = "";
  public password = "";
  public passwordConfirm = "";
  public userFormData: UserData = {};
  public userList: UserData[] = [];
  public searchKey: string | null | undefined;
  public sortDirection: number | null | undefined;
  public filterList: FilterElement[] = [];
  public cols!: CustomTableColumn[];
  public editExistingUserUuid = "";
  public prepereDeleteUser: UserData = {};
  public showDeleteModal = false;
  public prepereResetTotpUser: UserData = {};
  public showResetTotpModal = false;
  public userGroupList: UserGroup[] = [];
  public groupPageNumber = 1;
  public groupMaxNumberOfResults = 100;
  public groupSearchKey = 'name';
  public groups: string[] = [];
  public customerData!: CustomFieldsConfig;
  public first = 0;

  constructor(private router: Router, private userService: UserService,
              private translate: TranslateService, private sessionService: SessionService, private cardService: CardService, private errorService: ErrorHandlerService) {
    this.translate.setDefaultLang(this.sessionService.getDefaultLanguage());
    this.languageSubscription = this.sessionService.observerLanguage.subscribe(data => {
      this.translate.setDefaultLang(data);
    });

    if (!this.sessionService.getJWTToken() || this.sessionService.getJWTToken() == '') {
      this.router.navigateByUrl('/login').then((e) => {
        if (e) {
          //console.log("Navigation is successful!");
        } else {
          //console.log("Navigation has failed!");
        }
      });
    }

    //TODO: initial cost center request
    this.showPaginator = true;

    this.cols = [
      {field: 'username', checked: true},
      {field: 'fullname', checked: true},
      {field: 'email', checked: true}
    ];

    this.filterList = [
      {field: 'username', type: 'input', checked: false, value: ""},
      {field: 'fullname', type: 'input', checked: false, value: ""},
      {field: 'email', type: 'input', checked: false, value: ""}
    ];

    this.getGlobalPermissions();
    this.getUserGroupList();
    this.getCustomerData();
  }

  ngOnInit() {
    console.log('initialized');

    this.trafficProfiles = [];
  }

  ngOnDestroy() {
    console.log('destroyed');
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log('Refreshed');
  }

  loadDataLazy(event: TableLazyLoadEvent): void {
    if (event && event.sortField && event.sortField && typeof event.sortField === 'string') {
      this.searchKey = event.sortField;
    }

    this.sortDirection = event.sortOrder;

    this.pageNumber = (event.first) ? (event.first / +this.maxNumberOfResults) + 1 : 1;

    this.getUserList();
  }

  changeNumberOfRecords() {
    this.tableIsVisible = false;

    setTimeout(() => {
      this.tableIsVisible = true;
    }, 50);
  }

  selectSimcardDataRow(tariffProfile: TrafficProfile) {
    //console.log(tariffProfile);

    this.selectedTrafficProfileData = tariffProfile;
    this.trafficProfileSelected = true;
  }

  hideDetailsBlock() {
    this.selectedTrafficProfileData = null;
    this.trafficProfileSelected = false;
  }

  createNewUserButton() {
    this.getUserFormData({});
  }

  editUser(user: UserData) {
    this.errorService.deleteFieldError();
    this.getUserFormData(user);
    this.isModalChecked = true;
  }

  saveUser() {
    if (this.editExistingUserUuid != '') {
      this.updateUser();
    } else {
      this.createUser();
    }
  }

  createUser() {
    this.errorService.deleteFieldError();
    this.generateUserFormData();

    this.userService.createUser(this.sessionService.getJWTToken(), this.userFormData).subscribe({
      next: (response: UserData) => {
        successMessage(this.translate.instant("user.created-user"));
        this.isModalChecked = false;
        this.getUserFormData({});
        this.reloadPage();
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  updateUser() {
    this.errorService.deleteFieldError();
    this.generateUserFormData();

    this.userService.updateUser(this.sessionService.getJWTToken(), this.userFormData, this.editExistingUserUuid).subscribe({
      next: (response: UserData) => {
        successMessage(this.translate.instant("user.updated-user"));
        this.isModalChecked = false;
        this.getUserFormData({});
        this.reloadPage();
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  generateUserFormData() {
    this.userFormData = {
      username: this.userName,
      fullname: this.fullName,
      email: this.userEmail,
      permissions: this.editUserPermissions,
      plainPassword: this.password,
      groups: this.groups
    }
  }

  getUserFormData(user: UserData) {
    this.userName = (user.username) ? user.username : '';
    this.fullName = (user.fullname) ? user.fullname : '';
    this.userEmail = (user.email) ? user.email : '';
    this.editUserPermissions = (user.permissions) ? user.permissions : [];
    this.password = '';
    this.passwordConfirm = '';
    this.editExistingUserUuid = (user.uuid) ? user.uuid : '';
    this.groups = (user.groups) ? user.groups : [];

    if (user.permissions) {
      for (const permission of this.globalPermissions) {
        if (permission.name && this.editUserPermissions.includes(permission.name)) {
          permission.checked = true;
        }
      }
    } else {
      this.globalPermissions.map(permission => permission.checked = false); // Disable all Ckeckbox
    }

  }

  getGlobalPermissions() { // persmions für User und User Gruppen mit scope = global
    this.userService.getPermissions(this.sessionService.getJWTToken(), 'global').subscribe({
      next: (response: PermissionsList) => {
        this.globalPermissions = response['hydra:member'];
        //console.log(this.globalPermissions);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  changePermissions() {
    this.editUserPermissions = [];

    for (const permission of this.globalPermissions) {
      if (permission.checked && permission.name) {
        this.editUserPermissions.push(permission.name);
      }
    }
    //console.log(this.editUserPermissions);
  }

  getUserList() {
    this.loading = true;

    this.userService.getUserList(this.sessionService.getJWTToken(), this.pageNumber, this.maxNumberOfResults, this.searchKey, this.sortDirection, this.filterList).subscribe({
      next: (data: UserList) => {
        this.userList = data['hydra:member'];

        this.countItems = (data["hydra:totalItems"]) ? data["hydra:totalItems"] : 0;
        this.showPaginator = true;
        this.loading = false;

        //console.log('batchList', this.batchData);
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  prepareDeleteUser(user: UserData) {
    this.errorService.deleteFieldError();
    this.prepereDeleteUser = user;
    this.showDeleteModal = true;
  }

  deleteSelectedUser(user: UserData) {
    this.userService.deleteUser(this.sessionService.getJWTToken(), user?.['@id']).subscribe({
      next: (response: UserData) => {
        //console.log(response);
        this.reloadPage();

        successMessage(this.translate.instant("user.user-deleted"));
        this.showDeleteModal = false;
        return response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  prepareResetTotpUser(user: UserData) {
    this.errorService.deleteFieldError();
    this.prepereResetTotpUser = user;
    this.showResetTotpModal = true;
  }

  resetTotp(user: UserData) {
    if (user.uuid != null) {
      this.userService.resetTotp(this.sessionService.getJWTToken(), user.uuid).subscribe({
        next: () => {
          successMessage(this.translate.instant("user.reset-totp-success"));
          this.showResetTotpModal = false;
        },
        error: (err: HttpErrorResponse) => {
          this.errorService.processError(err);
        }
      })
    }
  }

  reloadPage() {
    this.getUserList();
  }

  changeFilter() {
    //console.log(filter.field);
    //console.log(this.filterList);
    this.first = 0;
    this.pageNumber = 1;
    this.getUserList();
  }

  filterClose(filter: FilterElement) {
    filter.checked = false;
    this.changeFilter();
  }

  getUserGroupAllPages() {
    this.userService.getUserGroupList(this.sessionService.getJWTToken(), this.groupPageNumber, this.groupMaxNumberOfResults, this.groupSearchKey, 1, null).subscribe({
      next: (data: UserGroupList) => {
        this.userGroupList.push(...data['hydra:member']);

        if (data['hydra:totalItems'] && this.userGroupList.length < data['hydra:totalItems']) {
          this.groupPageNumber++;
          this.getUserGroupAllPages();
        } else {
          console.log(this.userGroupList);
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }

  getUserGroupList() {
    this.userGroupList = [];

    this.groupPageNumber = 1;
    this.getUserGroupAllPages();
  }

  getCustomerData() {
    this.cardService.getCustomSimCardFields(this.sessionService.getJWTToken()).subscribe({
      next: (response: CustomFieldsConfig) => {
        this.customerData = response;
      },
      error: (err: HttpErrorResponse) => {
        this.errorService.processError(err);
      }
    });
  }
}
