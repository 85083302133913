<div class="modal-alternativ">
    <h3 class="modal-title">{{"custom-fields.sim-cards-custom-fields"|translate}}</h3>
    <div class="form-item">
        <label for="Konzerngesellschaft" class="col-form-label">{{"custom-fields.custom-field-1"|translate}}</label>
        <input type="text" class="form-control" aria-describedby="dataField1" name="name" [(ngModel)]="dataField1"
            #dataField1Ref="ngModel" [value]="dataField1">
    </div>
    <div class="form-item">
        <label for="Konzerngesellschaft" class="col-form-label">{{"custom-fields.custom-field-2"|translate}}</label>
        <input type="text" class="form-control" aria-describedby="dataField2" name="name" [(ngModel)]="dataField2"
            #dataField2Ref="ngModel" [value]="dataField2">
    </div>
    <div class="form-item">
        <label for="Konzerngesellschaft" class="col-form-label">{{"custom-fields.custom-field-3"|translate}}</label>
        <input type="text" class="form-control" aria-describedby="dataField3" name="name" [(ngModel)]="dataField3"
            #dataField3Ref="ngModel" [value]="dataField3">
    </div>
    <div class="form-item">
        <label for="Konzerngesellschaft" class="col-form-label">{{"custom-fields.custom-field-4"|translate}}</label>
        <input type="text" class="form-control" aria-describedby="dataField4" name="name" [(ngModel)]="dataField4"
            #dataField4Ref="ngModel" [value]="dataField4">
    </div>
    <div class="form-item">
        <label for="Konzerngesellschaft" class="col-form-label">{{"custom-fields.custom-field-5"|translate}}</label>
        <input type="text" class="form-control" aria-describedby="dataField5" name="name" [(ngModel)]="dataField5"
            #dataField5Ref="ngModel" [value]="dataField5">
    </div>

    <div class="button-center">
        <button type="button" class="button save" id="submit" role="button" (click)="executeUpdateAction();">
            {{"general.save"|translate}}
        </button>
    </div>
</div>