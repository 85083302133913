import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';

import { BASE_URL } from 'src/environments/environment';

import { Credentials } from '../models/credentials';
import { TotpDevice } from '../interfaces/totp-device';
import { TotpSetupDevice } from '../interfaces/totp-setup-device';
import { TotpSetupConfirmation } from '../interfaces/totp-setup-confirmation';
import { TotpDevicesList } from '../interfaces/totp-devices-list';
import { UserSettings } from '../interfaces/user-settings';
import { PermissionsList } from '../interfaces/permissionslist';
import { UserData, UserList } from '../interfaces/userlist';
import { FilterElement } from '../interfaces/filter-element';
import { UserGroup, UserGroupList } from '../interfaces/user-group';

const SERVICE_URL = "/";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userSettings: UserSettings = {};

  constructor(private http: HttpClient) {
  }

  /**
   * Login to the API
   *
   * @param userEmail
   * @param password
   */
  public loginWithCredential(userEmail: string, password: string, defaultLanguage = 'de'): Observable<any> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Accept-Language', defaultLanguage)
      .set('Content-Type', 'application/json');

    const userData: Credentials = {
      username: userEmail,
      password: password
    };

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<any>(BASE_URL + '/api/v2' + SERVICE_URL + "auth", userData, httpOptions).pipe(map((response: any) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public loginWithTotpDevice(authCode: string, twoFactorToken: string, deviceUUID: string, defaultLanguage = 'de'): Observable<any> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Accept-Language', defaultLanguage)
      .set('Content-Type', 'application/json');

    const userData: any = {
      twoFactorToken: twoFactorToken,
      totpDeviceUuid: deviceUUID,
      authCode: authCode
    };

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<any>(BASE_URL + '/api/v2/auth/twofactor', userData, httpOptions).pipe(map((response: any) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public createTotpDevice(token: string | null, defaultLanguage = 'de'): Observable<TotpDevice> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token);

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<any>(BASE_URL + '/api/v2/active_user/totp_devices', {}, httpOptions).pipe(map((response: TotpDevice) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public listAllTotpDevices(token: string | null, page = 1, numberOfItems = 20, defaultLanguage = 'de'): Observable<TotpDevice[]> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const params = new HttpParams()
      .set('page', '' + page)
      .set('itemsPerPage', '' + numberOfItems);

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<any>(BASE_URL + '/api/v2/active_user/totp_devices', httpOptions).pipe(map((totpDevicesList: TotpDevicesList) => {
        const dataResult = totpDevicesList;

        return dataResult['hydra:member'];
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public deleteTotpDevice(token: string | null, uuid: string | undefined, defaultLanguage = 'de'): Observable<any> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token);

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.delete<any>(BASE_URL + '/api/v2/active_user/totp_devices/' + uuid, httpOptions).pipe(map((response: any) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public prepareTotpDevice(setupToken: string | undefined, defaultLanguage = 'de'): Observable<TotpSetupDevice> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Accept-Language', defaultLanguage)
      .set('Content-Type', 'application/json');

    const tokenData = {
      setupToken: setupToken
    };

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<any>(BASE_URL + '/api/v2/auth/totp_setup/prepare', tokenData, httpOptions).pipe(map((response: TotpSetupDevice) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public confirmTotpDevice(setupToken: string, authCode: string, name: string, defaultLanguage = 'de'): Observable<TotpSetupConfirmation> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Accept-Language', defaultLanguage)
      .set('Content-Type', 'application/json');

    const tokenData = {
      setupToken: setupToken,
      authCode: authCode,
      name: name
    };

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<any>(BASE_URL + '/api/v2/auth/totp_setup/confirm', tokenData, httpOptions).pipe(map((response: TotpSetupConfirmation) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public changeUserPassword(token: string | null, oldPassword: string, newPassword: string, defaultLanguage = 'de'): Observable<TotpDevice> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token);

    const httpOptions = {
      headers: httpHeaders
    };

    const passwordObj: {
      oldPassword: string,
      newPassword: string,
    } = {
      oldPassword: oldPassword,
      newPassword: newPassword
    };

    return this.http.post<any>(BASE_URL + '/api/v2/active_user/change_password', passwordObj, httpOptions).pipe(map((response: TotpDevice) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public saveUserSettings(token: string | null, settingsObject = {}, defaultLanguage = 'de') {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token);

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.put<UserSettings>(BASE_URL + '/api/v2/active_user/portal_settings', settingsObject, httpOptions).pipe(map((response: UserSettings) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getUserSettings(token: string | null, defaultLanguage = 'de'): Observable<UserSettings> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token);

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.get<UserSettings>(BASE_URL + '/api/v2/active_user/portal_settings', httpOptions).pipe(map((response: UserSettings) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  public getPermissions(token: string | null, scope = "", defaultLanguage = 'de'): Observable<PermissionsList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token);

    const params = new HttpParams()
      .set('scope', '' + scope);

    const httpOptions = {
      headers: httpHeaders,
      params: params
    };

    return this.http.get<PermissionsList>(BASE_URL + '/api/v2/.refdata/permissions', httpOptions).pipe(map((response: PermissionsList) => {
        const dataResult = response;

        return dataResult;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  createUser(token: string | null, reportFormData: UserData, defaultLanguage = 'de'): Observable<UserData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<UserData>(BASE_URL + '/api/v2/portal_users', reportFormData, httpOptions).pipe(map((userData: UserData) => {
        return userData;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  updateUser(token: string | null, reportFormData: UserData, uuid: string, defaultLanguage = 'de'): Observable<UserData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.patch<UserData>(BASE_URL + '/api/v2/portal_users/' + uuid, reportFormData, httpOptions).pipe(map((userData: UserData) => {
        return userData;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  resetTotp(token: string | null, uuid: string, defaultLanguage = 'de'): Observable<any> {
    const httpHeaders = new HttpHeaders()
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)
    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<any>(BASE_URL + '/api/v2/portal_users/' + uuid + '/reset_totp', {}, httpOptions).pipe(map((response: any) => {
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  getUserList(token: string | null, page = 1, numberOfItems = 20, sortKey: string | null | undefined = null, sortDirection: number | undefined | null = 0, filterElements: FilterElement[] | null, defaultLanguage = 'de'): Observable<UserList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('page', '' + page)
      .set('itemsPerPage', '' + numberOfItems);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<UserList>(BASE_URL + '/api/v2/portal_users', httpOptions).pipe(map((batchList: UserList) => {
        return batchList;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  deleteUser(token: string | null, id: string | undefined, defaultLanguage = 'de'): Observable<UserData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.delete<UserData>(BASE_URL + id, httpOptions).pipe(map((userData: UserData) => {
        return userData;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  createUserGroup(token: string | null, reportFormData: UserGroup, defaultLanguage = 'de'): Observable<UserGroup> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.post<UserGroup>(BASE_URL + '/api/v2/portal_user_groups', reportFormData, httpOptions).pipe(map((groupData: UserGroup) => {
        return groupData;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  updateUserGroup(token: string | null, reportFormData: UserGroup, uuid: string, defaultLanguage = 'de'): Observable<UserGroup> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.patch<UserGroup>(BASE_URL + '/api/v2/portal_user_groups/' + uuid, reportFormData, httpOptions).pipe(map((userData: UserGroup) => {
        return userData;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  deleteUserGroup(token: string | null, id: string | undefined, defaultLanguage = 'de'): Observable<UserGroup> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/merge-patch+json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.delete<UserGroup>(BASE_URL + id, httpOptions).pipe(map((userGroup: UserGroup) => {
        return userGroup;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  getUserGroupList(token: string | null, page = 1, numberOfItems = 20, sortKey: string | null | undefined = null, sortDirection: number | undefined | null = 0, filterElements: FilterElement[] | null, defaultLanguage = 'de'): Observable<UserGroupList> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    let params = new HttpParams()
      .set('page', '' + page)
      .set('itemsPerPage', '' + numberOfItems);

    let sortASC = false;
    if (sortDirection != 0) {
      if (sortDirection == -1) {
        sortASC = false;
      } else if (sortDirection == 1) {
        sortASC = true;
      }

      params = params.set('order[' + sortKey + ']', (sortASC ? 'asc' : 'desc'));
    }

    if (filterElements !== null) {
      for (const filterObj of filterElements) {
        if (filterObj.field && filterObj.checked == true && filterObj.value !== '') {
          params = params.set(filterObj.field, (filterObj.value ?? ''));
        }
      }
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders,
      params: params,
    };

    return this.http.get<UserGroupList>(BASE_URL + '/api/v2/portal_user_groups', httpOptions).pipe(map((batchList: UserGroupList) => {
        return batchList;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }

  getActiveUser(token: string | null, defaultLanguage = 'de'): Observable<UserData> {
    defaultLanguage = sessionStorage.getItem("language") ?? 'de';

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Language', defaultLanguage)
      .set('Authorization', 'Bearer ' + token)

    const httpOptions = {
      headers: httpHeaders
    };

    return this.http.get<UserData>(BASE_URL + '/api/v2/active_user', httpOptions).pipe(map((activeUser: UserData) => {
        return activeUser;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }));
  }
}
