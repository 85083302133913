<div class="main-table-options">
    <div class="download-and-col-filter">
        <!-- DOWNLOAD -->
        <button class="csv-download" (click)="exportAsCSV();">CSV</button>
    </div>

    <!-- SELECT SEARCH FILTER -->
    <div class="main-dropdown filters">
        <span>{{"general.filter" | translate}}</span>
        <ul>
            <li>
                <h3>{{"general.show-filter" | translate}}</h3>
            </li>
            <li *ngFor="let filter of filterList; let i = index;">
                <input type="checkbox" value='{{filter.checked}}' data-id="filter-{{i}}"
                    [(ngModel)]="filter.checked" />
                <label for="filter-{{i}}">{{"table-cols."+filter.field | translate}}</label>
            </li>
        </ul>
    </div>
    <div class="selected-filters">
        <ng-container *ngFor="let filter of filterList; let i = index;">
            <div class="filter-item" *ngIf="filter.checked">
                <div class="filter-label">
                    <button class="filter-close" (click)="filterClose(filter)"><img alt="close filter"
                            src="assets/images/filter-close.svg" /></button> {{"table-cols."+filter.field | translate}}
                </div>
                <div class="filter-value">
                    <input *ngIf="filter.type==='input'" type="{{filter.type}}" (keyup.enter)="changeFilter()"
                        [style.width.px]="filter.value.length * 13" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <input *ngIf="filter.type==='date'" type="{{filter.type}}" (change)="changeFilter()"
                        [style.width.px]="150" name="{{filter.field}}" value="{{filter.value}}"
                        [(ngModel)]="filter.value">
                    <select *ngIf="filter.type==='select'" (change)="changeFilter()" name="{{filter.field}}"
                        [(ngModel)]="filter.value">
                        <option *ngFor="let option of filter.options; let i = index;" value="{{option.value}}">
                            {{option.label}}</option>
                    </select>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="clear"></div>
<div class="table-block">
    <div class="table-outside-div">
        <p-table [columns]="cols" [value]="trafficProfiles"
            (onLazyLoad)="loadDataLazy($event)" [lazy]="true" [loading]="loading"
            [totalRecords]="countItems" *ngIf="tableIsVisible">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" class="table-data-col-label {{col.field}}" pReorderableColumn
                        pResizableColumn [pSortableColumn]="col.field">
                        {{"table-cols."+col.field | translate}}
                        <p-sortIcon *ngIf="col.field === 'name'" [field]="col.field" ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-trafficProfile>
                <tr>
                    <td>{{ trafficProfile.name }}</td>
                    <td>{{ trafficProfile.warningNational }}</td>
                    <td>{{ trafficProfile.limitNational }}</td>
                    <td>{{ trafficProfile.warningInternational }}</td>
                    <td>{{ trafficProfile.limitInternational }}</td>
                    <td class="buttons">
                        <button class="table-row-button" (click)="isModalChecked=true"
                            (click)="editSelectedTrafficProfile(trafficProfile);"><img src="assets/images/row-edit.svg"
                                alt="edit" /></button>
                        <button class="table-row-button" (click)="prepareDeleteTrafficProfile(trafficProfile);"><img
                                src="assets/images/row-delete.svg" alt="delete" /></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div>
        <input type="checkbox" class="modal-chkbox" id="modal" [(ngModel)]="isModalChecked"
            (click)="showTrafficProfileModal();">
        <label for="modal" class="button-under-table">{{'traffic-profile.new-traffic'|translate}}</label>
        <label for="modal" class="modal-background"></label>
        <div class="modal">
            <label for="modal" class="close-modal"><img src="assets/images/close.svg" alt="close-icon"></label>
            <h3 class="modal-title" *ngIf="selectedTrafficProfileData === null">{{'traffic-profile.h-new-traffic'|translate}}</h3>
            <h3 class="modal-title" *ngIf="selectedTrafficProfileData !== null">{{'traffic-profile.h-edit-traffic'|translate}}</h3>

            <div class="tabbed-modal">
                <div>
                    <form name="trafficProfilForm" ngForm #trafficProfilForm="ngForm">
                        <div class="form-item">
                            <label for="Bezeichnung"
                                class="col-form-label">{{'traffic-profile.designation-1'|translate}}</label>
                            <input type="text" id="Bezeichnung" class="form-control name"
                                aria-describedby="BezeichnungHelpInline" name="trafficProfileName"
                                [(ngModel)]="trafficProfileName" #trafficProfileNameFormRef="ngModel" required>
                        </div>
                        <div class="form-item">
                            <label for="WarnungNat"
                                class="col-form-label">{{'traffic-profile.warnung-nat-1'|translate}}</label>
                            <input type="text" id="WarnungNat" class="input-with-unit" aria-describedby="WarnungNat"
                                name="trafficWarningNational" [(ngModel)]="trafficWarningNational"
                                #trafficWarningNationalFormRef="ngModel" pattern = "[0-9]+">
                            <span class="unit-for-input">
                                kB
                            </span>
                        </div>
                        <div class="form-item">
                            <label for="LimitNat"
                                class="col-form-label">{{'traffic-profile.limit-nat-1'|translate}}</label>
                            <input type="text" id="LimitNat" class="input-with-unit"
                                aria-describedby="LimitNatHelpInline" name="trafficLimitNational"
                                [(ngModel)]="trafficLimitNational" #trafficLimitNationalFormRef="ngModel" pattern = "[0-9]+">
                            <span class="unit-for-input">
                                kB
                            </span>
                        </div>
                        <div class="form-item">
                            <label for="WarnungInt"
                                class="col-form-label">{{'traffic-profile.warnung-int-1'|translate}}</label>
                            <input type="text" id="WarnungInt" class="input-with-unit"
                                aria-describedby="WarnungIntHelpInline" name="trafficWarningInternational"
                                [(ngModel)]="trafficWarningInternational" #trafficWarningInternationalFormRef="ngModel" pattern = "[0-9]+">
                            <span class="unit-for-input">
                                kB
                            </span>
                        </div>
                        <div class="form-item">
                            <label for="LimitInt"
                                class="col-form-label">{{'traffic-profile.limit-int-1'|translate}}</label>
                            <input type="text" id="LimitInt" class="input-with-unit"
                                aria-describedby="LimitIntHelpInline" name="trafficLimitInternational"
                                [(ngModel)]="trafficLimitInternational" #trafficLimitInternationalFormRef="ngModel" pattern = "[0-9]+">
                            <span class="unit-for-input">
                                kB
                            </span>
                        </div>
                    </form>
                </div>
            </div>

            <div class="button-center">
                <button [disabled]="!trafficProfilForm.valid" type="submit" class="button save" id="submit" role="button" (click)="executeUpdateAction();">
                    {{"traffic-profile.save"|translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- delete modal -->
<div *ngIf="showDeleteModal" class="custom-modal delete-modal">
    <div class="custom-modal-content">
      <p class="text-align-center">{{'traffic-profile.deleteQuestion' | translate}}</p>
      <div class="button-center">
        <button class="gray-button button" (click)="showDeleteModal = false">{{"general.cancel"|translate}}</button>
        <button class="save button" type="button"
          (click)="deleteSelectedTrafficProfile(prepereDeleteTrafficProfile);">{{"general.delete"|translate}}</button>
      </div>
    </div>
</div>